// import _ from 'lodash';

const initialState = {
  packageObj: {},
  package_cmp:{}
};

export const PACKAGE_ITEM = 'PACKAGE_ITEM';
export const PACKAGE_CMP = 'PACKAGE_CMP';


 const packageItem = (state = initialState, action) => {
   console.log('login reducer',action)
  switch (action.type) {
    case PACKAGE_ITEM:
      return Object.assign({}, state, {
        packageObj:action.payload
      })
      case PACKAGE_CMP:
        return Object.assign({}, state, {
          package_cmp:action.payload
        })
    default:
      return state;
  }
};

export default packageItem;