import React, { Component } from 'react'
import styled from 'styled-components'

const TextareaWrapper=styled.div`
margin:8px 20px;

`
const Textarea=styled.textarea`
background:#fff;
color:#A2A2A2;
border: 1px solid #F1F1F1;
width:100%;
padding: 12px;
min-height: 123px;
box-shadow: 1px 1px 9px 0px #d3d3d347;
`
export default class index extends Component {
    render() {
        return (<TextareaWrapper className={this.props.classname} >
        <Textarea
        placeholder={this.props.label}
        value={this.props.val}
        name={this.props.name}
        className={this.props.classname}
        onChange={this.props.inputChange}
        />
        </TextareaWrapper>)
    }
}
