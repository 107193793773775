import React, { Component } from 'react'
import {withRouter} from 'react-router-dom'

import { connect } from 'react-redux'
import styled from 'styled-components'
import PendingImg from '../../assets/images/PENDING.png'
import Status from '../Status'
import {apiPost} from '../../library/index'
import {chooseLang} from '../Header/action'

const H4=styled.h4`
color: #223E92;
font-size: 26px;`

const H6=styled.h6`
font-size:21px;
margin:1rem 0px;
font-weight:normal;
color:#363636;
text-align: center;
`
const PaymentMessage = styled.div`
font-size:16px;
margin:2rem;
`
export class index extends Component {
    state={
        id:'',
        refid:''
    }
    componentDidMount() {
        if (this.props.match.params && this.props.match.params.id) {
            // localStorage.getItem('refid')
            this.changeLang(this.props.match.params.lang)
            this.setState({
                id:this.props.match.params.id,
                refid:localStorage.getItem('refid') 
            },()=>{
                this.sendEmail()
            })
        }
    }
    
    changeLang =async (lang) => {
        console.log('language changing.....')
        const obj={
            url:'translation/',
            body:{
                text_language:lang.toUpperCase()
            }
        }
        const newLng=await apiPost(obj)
        const newObj={obj:newLng[0],lang:lang}
        this.props.chooseLang(newObj)
        // this.props.history.push('/')
        console.log('neeLang Data',newLng)
    } 

    sendEmail=async()=>{
        const data = {
            url: "confirmationmail/",
            body: {
                ref_id: this.state.refid
            }
        }
        const newData = await apiPost(data)
        console.log('email status', newData)
    }
        render() {
            const{langObj}=this.props.lang
            return (<Status img={PendingImg} buttonName='Back to connections.be'>
                    <H4>{langObj.p_booking}</H4>
        <H6>{langObj.ref} <b>{this.state.refid}</b> </H6>
        <PaymentMessage className='margin-y'>{langObj.p_details}</PaymentMessage> 
                    </Status>)
        }
}

const mapStateToProps = (state) => ({
lang:state.language
})

const mapDispatchToProps = {

}

export default withRouter(connect(mapStateToProps, {chooseLang})(index))







