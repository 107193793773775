import React, { Component } from 'react'
import styled from 'styled-components'

const Button=styled.button`
outline: none;
padding: 6px 12px;
font-size:14px;
cursor:pointer;
`
const RightIcon=styled.i`
margin-left:15px
`
const LeftIcon=styled.i`
margin-right:15px
`
export default class index extends Component {
    render() {
        return (<Button onClick={this.props.submit}
        className={this.props.classname}
        disabled={this.props.disable}
        >
        {this.props.lefticon?<LeftIcon className={this.props.lefticon}></LeftIcon>:''}
        {this.props.name}
        {this.props.righticon?<RightIcon className={this.props.righticon}></RightIcon>:''}
        </Button>)
    }
}
