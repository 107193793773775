import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import $ from "jquery";
import { Link, withRouter } from "react-router-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import _ from "lodash";
import Alert from "react-s-alert";
import moment from "moment/min/moment-with-locales";
// import 'moment/locale/fr';
// import Moment from "react-moment";
import Linkify from "react-linkify";
// import moment as Moment from "moment";

import { chooseLang, chooseEmailSub } from "../Header/action";
//
import PackageImage from "../../assets/images/package-detailimg.png";
import PackageLabel from "../PackageLabel";
import PlaneIcon from "../../assets/images/PLANE.png";
import HotelIcon from "../../assets/images/HOTEL-ICON.png";
import TravelIcon from "../../assets/images/ICON-TRAVELLERS.png";
import Textarea from "../TextArea";
import TourIcon from "../../assets/images/TOUR-ICON.png";
import ExtraIcon from "../../assets/images/EXTRA-ICON.png";
import ExcursionIcon from "../../assets/images/EXCURSION-ICON.png";
import LaterIcon from "../../assets/images/later-icon.png";
import LeftIcon from "../../assets/images/left.png";
import RightIcon from "../../assets/images/right.png";

import EarlierIcon from "../../assets/images/earlier-icon.png";
import { openModal, closeModal } from "../HelpUsSection/actions";
import {
  selectPackage,
  packageCmp,
  priceCal,
  selectDays,
  choosenPackage,
  selectTravelers,
  showTableData,
  selectMonth,
  selectPassengers,
  getExtraComps,
  changePageNumber,
  choosenPrice,
  changeInvoice,
  changeStepOne,
  changeStepTwoAdult,
  changeStepTwoChild,
  resetInputs,
  mon_Options,
} from "./actions";
import HelpSection from "../HelpUsSection";
import Table from "../Table";

//
import { err_Val } from "../PaymentOptions/actions";
import { err, selectType } from "../PaymentMethods/actions";
import { showErr } from "../Insurance/actions";
import FormPackageCard from "../FormPackageCard";
import PaymentMethods from "../PaymentMethods";
import UserDetails from "../UserDetails";
import Insurance from "../Insurance";
import Dropdown from "../Dropdown";
import { Colors } from "../../library/colors";
import {
  days,
  months,
  years,
  validateInputs,
  getMonthName,
  monthObj,
  validEmailRegex,
  apiGet,
  copyObj,
  apiPost,
} from "../../library";
import Input from "../Input";
import Button from "../Button";
import TextArea from "../TextArea";
import PriceDetails from "../PricingDetails";
import PaymentDetails from "../PaymentDetails";
import PaymentOptions from "../PaymentOptions";
import Checkbox from "../Checkbox";
import UpgradeCard from "../UpgradesCard";
import HotelImage from "../../assets/images/hotel.png";
import ValueImage from "../../assets/images/addedval.png";
import Airplane from "../AirlineUpgrades";
import ImportantIcon from "../../assets/images/important.png";
import Tick from "../../assets/images/tick.png";
import TourExcursionCard from "../TourExcursionCard";
import ExcursionCard from "../ExcursionCard";
import Loader from "../Loader";
import { resetInsurance } from "../Insurance/actions";
import { useEffect, useState } from "react";
import { frontEndUrl } from "../../library/constant";

// import {} from '../'

const H4 = styled.h4`
  font-weight: 100;
`;
const H3 = styled.h3`
  color: ${Colors.primaryColor};
  font-size: 26px;
`;
const InformationSection = styled.div`
  padding: 20px;
  background: #fff;
  margin: 15px 0px;
  box-shadow: ${(props) =>
    props.bg ? "2px 2px 11px 0px #d3d3d3ab" : "0px 0px 0px 0px transparent"};
`;
const CheckboxWrapper = styled.div`
  display: flex;
`;
const DetailSection = styled.div`
  margin-left: 20px;
`;
const P = styled.p`
  font-size: 16px;
  margin: 8px 0px;
  // text-transform:capitalize;s
`;
const FormWrapper = styled.div`
  width: 85%;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;
const Label = styled.label`
  font-size: 14px;
`;
const InputFieldsSection = styled.div`
  margin: 30px 0px;
`;
const FlexWrapper = styled.div`
  display: flex;
  // align-items:ce;
  margin: 30px 0px;
  justify-content: space-between;
  // flex-wrap:wrap;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  // margin-right: 25px;
  width: 47.5%;
`;
const ButtonGroups = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3rem 0px;
`;
const FloatingHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const H5 = styled.h6`
  color: ${Colors.primaryColor};
  font-size: 20px;
`;
const HR = styled.hr`
  width: calc(100% - 130px);
  border: 1px solid #ebebeb;
`;
const JustifyFlex = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0px;
`;
const H3Text = styled.h5`
  font-size: 18px;
  font-weight: bold;
`;
// step 4 styles starts
const FourthStepSection = styled.div`
  margin: 1.7rem 0px;
`;
const PackageDetails = styled.div``;
const FourthStepHeader = styled.h3`
  font-size: 20px;
  color: #fff;
  background-image: linear-gradient(#1a2e6a, #1f3688);
  margin: 15px 0px;
  padding: 8px 15px;
  font-weight: 200;
`;
const PricingContainer = styled.div`
  margin: 10px 20px;
`;
const FourthStepSubHeader = styled.h4`
  font-size: 24px;
  color: ${Colors.primaryColor};
  font-weight: normal;
  text-transform: capitalize;
`;
const SingleSection = styled.div`
  margin: 5px 0px;
`;
const NormalText = styled.h5`
  font-size: 14px;
  color: #383838;
  font-weight: 100;
  margin: 0.4rem 0;
`;
const HighlightText = styled.h5`
  font-size: 16px;
  margin: 0.4rem 0;
`;
const FloatingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const StepsContainer = styled.div`
  display: flex;
  align-items: center;
`;
const Bar = styled.hr`
  width: 25%;
  margin: 15px 0px;
  border: 1.5px solid lightgray;
  background-color: lightgray;
`;
const Separator = styled.hr`
  width: 100%;
  border: 1px solid #d3d3d352;
`;
const TotalPriceContainer = styled.div`
  text-align: right;
  background: #d3d3d363;
  border-top: 1.5px solid lightgray;
  padding: 10px;
  @media only screen and (max-width: 600px) {
    text-align: left;
  }
`;
const TermsConditions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
// step 4 style ends

// step 0 starts
const PageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const ImageContainer = styled.div`
  width: 32%;
  height: 602px;
  background-image: url(${(props) => (props.img ? props.img : "")});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 330px;
  }
`;
const StepZeroContainer = styled.div`
  width: 63%;
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: 20px 0px;
    // height:330px;
  }
`;
const PackageName = styled.div``;
const PackageIcon = styled.img`
  margin-right: 0.5em;
  margin-bottom: 0.5rem;
`;
const FlexPackageIcon = styled.h3`
  display: -webkit-box;
`;

const StepZeroH3 = styled.h3`
  font-size: 20px;
  margin: 8px 0px;
  color: ${Colors.primaryColor};
`;
const StepZeroH4 = styled.h4`
  font-size: 20px;
  color: ${Colors.primaryColor};
`;
const StepZeroH6 = styled.h6`
  font-size: 20px;
  font-weight: normal;
  color: ${Colors.primaryText};
`;
const StepZeroHighlightText = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: ${Colors.primaryText};
  margin: 0.1rem 0px;
  text-transform: capitalize;
`;
const StepZeroStepZeroHighlightText = styled.span`
  font-size: 16px;
  // text-transform:capitalize;

  font-weight: 100;
  color: ${Colors.primaryText};
`;
const IncludedPackage = styled.div`
  margin: 10px 0px;
`;
// const FlexWrapper = styled.div`
// display:flex;
// justify-content:space-between;
// `
const PackageSections = styled.div`
  width: 40%;
`;
const LinkText = styled.p`
  font-size: 12px;
  font-weight: 100;
  color: ${Colors.primaryText};
`;
const StepZeroStepZeroSeparator = styled.br``;
const SummaryContainer = styled.div`
  margin: 1.5rem 0px;
`;
const StepZeroStepZeroHR = styled.hr`
  width: 100%;
  margin: 30px 0px;
  border: 1px solid #ebebeb;
`;
const SummaryPackageDetail = styled.div`
  display: flex;
  align-items: center;
  margin: 10px;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: baseline;
  }
  // justify-content:space-between;
`;
const TableWrapper = styled.div`
  margin: 20px 0px;
`;
const DateSection = styled.div`
  display: flex;
  align-items: center;
  width: 40%;
  justify-content: space-between;
`;
const Icon = styled.img`
  height: 100%;
`;
const StepZeroStepZeroFloatingContainer = styled.div`
  display: flex;
  align-items: center;
`;
const EnquiryContainer = styled.div`
  margin: 20px 0px;
  background: transparent;
`;
const EnquirySuccsess = styled.div`
  margin: 20px 0px;
  background: transparent;
`;
const HelpUsFrom = styled.div``;
const Text = styled.div`
  font-size: 18px;
  color: grey;
`;
// const FlexWrapper = styled.div`
// display:flex;
// // align-items:center;
// justify-content:space-between;
// `
const InputFiled = styled.div`
  margin: 15px 0px;
  width: 47%;
  flex-wrap: wrap;
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const DropdownGroup = styled.div`
  display: flex;
  align-items: center;
`;
const Note = styled.p`
  font-size: 12px;
  color: gray;
  text-align: right;
`;
const Name = styled.h4`
  background: ${Colors.secondaryText};
  color: #ffffff;
  font-size: 22px;
  padding: 8px 1rem;
  position: absolute;
  bottom: 140px;
  left: 0;
  // margin-top:-3px;
`;
const PriceTag = styled.h5`
  padding: 8px 1rem;
  color: #ffffff;
  position: absolute;
  bottom: 100px;
  left: 0;
  background: ${Colors.primaryColor};
`;
// step 4 ends

const FormSectionWrapper = styled.div`
  display: flex;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const FormSection = styled.div`
  width: 63%;
  margin-right: 20px;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;
const PackageDetailSection = styled.div`
  width: 29.5%;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;
const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
`;
// const values
// const options = [
//     { value: 'belgium', label: 'Belgium' },
//     { value: 'netherland', label: 'Netherland' },
//     { value: 'sweden', label: 'Sweden' },
// ];

// step 0
const options = [{ value: "2 Adults, 1 Child", label: "2 Adults, 1 Child" }];

const numbers = [
  { label: "0", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
];
// ends
var temp = false

const componentDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank">
    {text}
  </a>
);

export class index extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      stepOneInputs: {
        first_name: "",
        last_name: "",
        email: "",
        confirm_email: "",
        telephone_number: "",
        contact_status: false,
        street: "",
        house_number: "",
        postal_code: "",
        city: "",
        country: "",
        company_name: "",
        vat_number: "",
      },
      child: [],
      adult: [],
      errOne: {},
      errTwo: "",
      childArrVal: [],
      adultArrVal: [],
      countryOptions: [],
      fieldErrOne: {},
      invoice: false,
      showCountry: false,
      pageNum: 0,
      prevPage: 0,
      nextPage: "",
      showMonth: "",
      showDays: "",
      showYear: "",
      showGender: "",
      country: "",
      step2Arr: [
        { gender: "", fname: "", lname: "", date: "", month: "", year: "" },
        { gender: "", fname: "", lname: "", date: "", month: "", year: "" },
        { gender: "", fname: "", lname: "", date: "", month: "", year: "" },
      ],
      // stepzero
      showTable: false,
      showSummary: false,
      index: "",
      show: false,
      data: "",
      rowDatas: {},
      rows: [],
      col: [],
      travelers: "",
      depatureMonth: "",
      price: "",
      date: "",
      duration: "",
      morePassengers: {
        adult_qty: "",
        child_qty: "",
        infant_qty: "",
        day: "",
        month: "",
        year: "",
        length_of_stay: "",
        first_name: "",
        last_name: "",
        email: "",
        telephone: "",
        remarks: "",
        package_code: "",
      },
      child_qty: "",
      adult_qty: "",
      infant_qty: "",
      length_of_stay: "",
      year: "",
      day: "",
      month: "",
      selectMonth: false,
      keys: {
        des_name: [],
        order_name: "",
        order_price: "",
        text_language: "EN",
        package_code: "",
        detail: "",
      },
      loading: false,
      package_detail: [],
      package_cmp: {},
      errors: {},
      field_errors: {},
      yearOptions: [],
      dateOptions: [],
      monthOptions: [],
      travelOptions: [],
      monOptions: [],
      packageSummary: {},
      extrasPackage: {},
      priceCalcObj: {},
      paymentType: "",
      terms: false,
      offset: 0,
      sampleArr: [],
      perPage: 5,
      currentPage: 0,
      pageCount: null,
      paymentLoading: false,
      openPsgInf: false,
      openTerms: false,
      anotherdate: false,
    };
  }

  componentDidMount = async () => {
    // step zero
    const year = new Date().getFullYear();
    const years = Array.from(new Array(20), (val, index) => {
      return { label: index + year, value: index + year };
    });
    const months = Array.from(new Array(12), (val, index) => {
      return { label: index + 1, value: index + 1 };
    });
    const dates = Array.from(new Array(31), (val, index) => {
      return { label: index + 1, value: index + 1 };
    });
    this.setState({
      yearOptions: years,
      monthOptions: months,
      dateOptions: dates,
    });
    console.log("packegedetails", this.props, years);
    if (this.props.match.params && this.props.match.params.id) {
      this.changeLang(this.props.match.params.lang);
      this.setState({
        // loading: true
      });
      const newKeys = copyObj(this.state.keys);
      newKeys["package_code"] = this.props.match.params.id;
      newKeys["text_language"] = this.props.lang.selectedLang.toUpperCase();
      const data = {
        url: "user_pack_view/",
        body: newKeys,
      };
      const newData = await apiPost(data);
      this.props.packageCmp(newData);
      const newTravel = this.getTravelers(newData.qty_list);
      console.log("newTravel", newTravel);
      // const newTravel = newData.qty.map((x, i) => { return { label: x, value: x } })
      // var selLang=this.props.lang.selectedLang.toLowerCase()
      // console.log('selLang',selLang)
      // moment.locale(selLang);
      // const newMonths = newData.period.map((x, i) => {
      //     return {
      //         // label: moment(x).lang(this.props.lang.selectedLang.toLowerCase()).format('MMMM YYYY'),
      //         value: x
      //     }
      // })
      const newObj = {
        package_cmp: newData,
        travelOptions: newTravel,
        monOptions: [],
        csv_file: newData.package[0].csv_file,
      };
      this.props.choosenPackage(newObj);
      console.log("selected package newData", newData);
      this.setState({
        // package_cmp: newData,
        travelOptions: newTravel,
        monOptions: [],
        csv_file: newData.package[0].csv_file,
        morePassengers: {
          ...this.state.morePassengers,
          package_code: newData.package[0].package_code,
        },
        // package_detail: newData.package
      });
      var packageVars = newData.package[0];
      console.log("validate fpit cdm", packageVars);
      if (packageVars.partial_pay === false) {
        this.props.selectType("full payment");
      }
      console.log("selected packegedetails", newData);
    }
    // stepzero ends
    console.log("props step cmp", this.props.cmp);
    this.getCountry();
    //    this.stepTwoFields()
    //    this.getExtrasPackage()
  };
  // componentDidUpdate = async(prevState) => {
  //   if (temp===true && this.props.cmp.stepOneInputs.first_name==="")
  //   {
  //     window.location.reload()
  //   }
  //   if (this.props.cmp.stepOneInputs.first_name){
  //     temp = true
  //   }
  // }
  // changeLanguage
  changeLang = async (lang) => {
    console.log("language changing.....");
    const obj = {
      url: "translation/",
      body: {
        text_language: lang.toUpperCase(),
      },
    };
    const newLng = await apiPost(obj);
    const newObj = { obj: newLng[0], lang: lang };
    this.props.chooseLang(newObj);
    // this.props.history.push('/')
    console.log("neeLang Data", newLng);
  };
  // GETTRAVELERS DATA
  getTravelers = (arr) => {
    const eng = Object.keys(arr[0]).map((x) => {
      return { label: x, value: x };
    });
    // console.log('eng',eng)
    const dt = Object.values(arr[0]).map((x, i) => {
      return { label: x, value: eng[i].label };
    });
    // console.log('fr',fr)
    const fr = Object.values(arr[1]).map((x, i) => {
      return { label: x, value: eng[i].label };
    });
    const obj = { EN: eng, NL: dt, FR: fr };
    console.log(
      "selectedLanhTravel",
      obj[this.props.lang.selectedLang.toUpperCase()],
      this.props.lang.selectedLang
    );
    return obj[this.props.lang.selectedLang.toUpperCase()];
    // console.log('dt',dt)
  };
  // getExtrasPackage
  getExtrasPackage = async (date, qty, duration, month) => {
    // var dates=date.split(" ")[0]
    // var mon=monthObj[[date.split(" ")[1]]]
    // var year=month.split(" ")[1]
    // var finalDate=mon+"/"+dates+"/"+year
    // console.log('date,qty,price,month',dates,mon,year,finalDate,monthObj)

    const keys = {
      des_name: [],
      order_name: "",
      order_price: "",
      text_language: this.props.lang.selectedLang.toUpperCase(),
      package_code: this.props.match.params.id,
      detail: "qwedqw",
      duration: duration,
      qty: qty,
      start_date: date,
    };
    const data = {
      url: "user_pack_view/",
      body: keys,
    };
    const newData = await apiPost(data);
    this.props.getExtraComps(newData);
    // this.setState({
    //     extrasPackage: newData
    // })
    console.log("extras package", newData);
  };

  // stepTwo feilds
  stepTwoFields = (val) => {
    var obj = {
      gender: "",
      first_name: "",
      last_name: "",
      day: "",
      month: "",
      year: "",
    };
    var packageObj = val.value;
    var adults = 0;
    var children = 0;
    var totalTravelers = [];
    var noOfAdults = [];
    var noOfChild = [];
    if (Object.keys(packageObj).length > 0) {
      totalTravelers = packageObj.split(" ").filter((x, i) => !isNaN(x));
      adults = totalTravelers.length > 0 ? totalTravelers[0] : 0;
      children = totalTravelers.length > 1 ? totalTravelers[1] : 0;
      noOfAdults = Array(parseInt(adults)).fill(obj);
      noOfChild = Array(parseInt(children)).fill(obj);
      const newObj = {
        child: noOfChild,
        adult: noOfAdults,
      };
      this.props.selectPassengers(newObj);
      // this.setState({
      //     child: noOfChild,
      //     adult: noOfAdults
      // })
    }
  };

  // step zero functions
  //modal  onInputChnage
  stepZeroIpChange = (e) => {
    const { name } = e.target;
    var value = e.target.value;
    let errVal;
    switch (name) {
      case "email":
        errVal = !validEmailRegex.test(value)
          ? this.props.lang.langObj.invalid_email
          : "";
        break;
      case "telephone":
        value = value.replace(/[^+()/0-9]/gi, "");
        value = value.toString().slice(0, 16);
        // value = value.toString().slice(0, 10);
        // errVal = value.length === 10 ? '' : 'Mobile number is not valid';
        // parseInt(value);
        break;
      default:
    }
    this.setState({
      field_errors: {
        ...this.state.field_errors,
        [name]: errVal !== "undefined" ? errVal : "",
      },
    });
    this.setState({
      morePassengers: { ...this.state.morePassengers, [name]: value },
    });
  };
  //  modal open and close
  openTerms = () => {
    this.setState({
      openTerms: true,
    });
  };
  closeTerms = () => {
    this.setState({
      openTerms: false,
    });
  };
  openPsgInf = () => {
    this.setState({
      openPsgInf: true,
    });
  };
  closePsgInf = () => {
    this.setState({
      openPsgInf: false,
    });
  };

  //OPENhLEPsECTION
  openHelpModal = () => {
    this.props.openModal();
  };

  onOpenModal = (date) => {
    const sampleKeys = {
      first_name: "",
      last_name: "",
      email: "",
      telephone: "",
      remarks: "",
      adult_qty: "",
      child_qty: "",
      infant_qty: "",
      day: "",
      month: "",
      year: "",
      length_of_stay: "",
    };
    this.setState({
      anotherdate: date,
      open: true,
      show: false,
      keys: sampleKeys,
    });
  };

  onCloseModal = () => {
    const defaultObj = {
      adult_qty: "",
      child_qty: "",
      infant_qty: "",
      day: "",
      month: "",
      year: "",
      length_of_stay: "",
      first_name: "",
      last_name: "",
      email: "",
      telephone: "",
      remarks: "",
      package_code: "",
    };
    this.setState({ open: false, morePassengers: defaultObj });
  };

  // to step one
  forwardToStepOne = () => {
    $("html, body").animate({ scrollTop: 0 }, 0);
    this.props.changePageNumber(1);
  };

  // choose Pricing
  selectPrice = (index, data, price, date, duration) => {
    const { selectedLang } = this.props.lang;
    const { stepOneInputs } = this.props.cmp;
    var language = selectedLang.toUpperCase();
    var ctry =
      language === "NL" ? "België" : language === "EN" ? "Belgium" : "Belgique";
    stepOneInputs["country"] = { label: ctry, value: ctry };
    stepOneInputs["country"] = stepOneInputs.country
      ? stepOneInputs.country
      : { label: "belgium", value: "belgium" };
    this.props.changeStepOne(stepOneInputs);
    if (price) {
      // this.props.resetInputs()
      console.log(
        "table",
        index,
        data,
        price,
        date,
        duration,
        this.state.travelers,
        this.state.depatureMonth
      );
      var d = new Date();
      var year = d.getFullYear();
      var month = getMonthName(d.getMonth());
      var finalDate = this.props.cmp.selectedMonth
        ? this.props.cmp.selectedMonth.value
        : month + " " + year;
      const newObj = {
        index: index,
        price: price,
        data: data,
      };
      this.props.choosenPrice(newObj);
      // this.setState({
      //     index: index,
      //     price: price,
      //     data: data
      // })
      const dur =
        duration.split("-")[0] +
        " days - " +
        duration.split("-")[1] +
        " nights";
      const { package_cmp } = this.props.cmp.selectedPackage;
      const packName =
        "package" in package_cmp
          ? package_cmp.package.length > 0
            ? package_cmp.package[0].name
            : ""
          : "";
      const obj = {
        package_name: packName,
        package_code: this.props.match.params.id,
        amount: price,
        period: finalDate,
        qty: this.props.cmp.travelers.value,
        duration:
          duration.split("-")[0] +
          " days - " +
          duration.split("-")[1] +
          " nights",
        date: date,
        step_3: {},
      };
      this.showPackageSummary(obj);
    }
  };

  // packageSummary
  showPackageSummary = async (data) => {
    const dataObj = {
      url: "calculation/",
      body: data,
    };
    const newData = await apiPost(dataObj);
    this.props.selectPackage(newData.step_0);
    window.scrollTo(0, document.body.scrollHeight);
    // $("html, body").animate({ scrollBottom: 0 }, 0);
    this.getExtrasPackage(
      newData.step_0.start_date,
      this.props.cmp.travelers.value,
      data.duration
    );
    console.log("tableNewData", newData);
  };

  // // selectMonth
  // selectMonth = () => {
  //     this.setState({
  //         showMonth: true
  //     })
  // }
  // // select No of Days
  // selectTravelers = () => {
  //     this.setState({
  //         showTable: true
  //     })
  // }

  // month validation

  // monthValidation=(val)=>{

  //     console.log('monthvalidation',val)
  // }
  // monthVa

  monthValidation = async (val) => {
    this.props.chooseEmailSub({ emailSubject: val.label });
    var selLang = this.props.lang.selectedLang.toLowerCase();
    console.log("monthvalselLang", selLang);
    moment.locale(selLang);
    var obj = {
      csv_file: this.props.cmp.selectedPackage.csv_file,
      qty: val.value,
    };
    const data = {
      url: "monthvalidation/",
      body: obj,
    };
    const newData = await apiPost(data);
    const newMonths = newData.period.map((x, i) => {
      const dateObj = "01" + "-" + x.replace(" ", "-");
      // console.log('newDate', dateObj)
      // console.log('newDate1',new Date(dateObj))
      // console.log('newDateMoment',moment(new Date(dateObj)).format('MMMM YYYY').replace('-', " "))
      return {
        label: moment(new Date(dateObj)).format("MMMM YYYY").replace("-", " "),
        value: x,
      };
    });
    console.log("newMonths", newMonths);
    //   const  new_Months = newMonths.map((x, i) => {
    //         return {
    //             label:x.replace('-'," "),
    //             value: x
    //         }
    //     })
    this.props.selectMonth(newMonths[0]);
    this.props.mon_Options(newMonths);
    // this.setState({
    //     monOptions: newMonths,
    // }
    // ,()=>{
    this.selectNumOfTravelers(val, newMonths[0]);

    // }
    // )
    // console.log('monthValidation',newData)
  };

  // select Number of travelers
  selectNumOfTravelers = async (val, mon) => {
    // this.monthValidation(val)
    this.props.selectTravelers(val);
    this.setState(
      {
        travelers: val,
        // showSummary: false,
        index: "",
        data: "",
      },
      async () => {
        // var d = new Date()
        // var year = d.getFullYear()
        // var month = getMonthName(d.getMonth())
        // console.log('final date', this.props.cmp)
        // var finalDate = this.props.cmp.selectedMonth ? this.props.cmp.selectedMonth.value : this.props.cmp.selectedPackage.monOptions[0].value
        // var monthVal = this.props.cmp.selectedPackage.monOptions[0]
        // this.props.selectMonth(monthVal)
        var obj = {
          csv_file: this.props.cmp.selectedPackage.csv_file,
          period: mon.value,
          qty: val.value,
        };
        const data = {
          url: "csvdata/",
          body: obj,
        };
        const newData = await apiPost(data);
        if ("status" in newData && newData.status === 200) {
          console.log("newCsv Data", newData);
          this.conversion(newData);
          this.stepTwoFields(val);
        } else {
          alert("Something went wrong please try again");
        }
      }
    );

    // console.log('final date',newObj,dates,days)
  };

  //selectDepatureDate
  selectDepatureDate = async (val) => {
    this.props.selectMonth(val);
    console.log("selectinfg month", val);
    this.setState(
      {
        depatureMonth: val,
        // showSummary: false,
        index: "",
        data: "",
      },
      async () => {
        var obj = {
          csv_file: this.props.cmp.selectedPackage.csv_file,
          period: val.value,
          qty: this.props.cmp.travelers.value,
        };
        const data = {
          url: "csvdata/",
          body: obj,
        };
        const newData = await apiPost(data);
        this.conversion(newData);
      }
    );
  };

  // csv data conversion
  conversion = (data) => {
    let sample = [
      {
        departure_date: "12 Apr",
        duration_days: "7",
        duration_nights: "6",
        amount: "169",
      },
      {
        departure_date: "12 Apr",
        duration_days: "9",
        duration_nights: "8",
        amount: "473",
      },
      {
        departure_date: "13 Apr",
        duration_days: "5",
        duration_nights: "4",
        amount: "854",
      },
      {
        departure_date: "13 Apr",
        duration_days: "7",
        duration_nights: "6",
        amount: "169",
      },
      {
        departure_date: "13 Apr",
        duration_days: "9",
        duration_nights: "8",
        amount: "473",
      },
      {
        departure_date: "15 Apr",
        duration_days: "5",
        duration_nights: "4",
        amount: "854",
      },
      {
        departure_date: "15 Apr",
        duration_days: "9",
        duration_nights: "8",
        amount: "473",
      },
      {
        departure_date: "16 Apr",
        duration_days: "5",
        duration_nights: "4",
        amount: "854",
      },
      {
        departure_date: "16 Apr",
        duration_days: "7",
        duration_nights: "6",
        amount: "169",
      },
      {
        departure_date: "16 Apr",
        duration_days: "9",
        duration_nights: "8",
        amount: "473",
      },
      {
        departure_date: "22 Apr",
        duration_days: "5",
        duration_nights: "4",
        amount: "854",
      },
      {
        departure_date: "22 Apr",
        duration_days: "7",
        duration_nights: "6",
        amount: "169",
      },
      {
        departure_date: "22 Apr",
        duration_days: "9",
        duration_nights: "8",
        amount: "473",
      },
    ];
    let obj = this.csvDataConversion(data.data);
    let days = data.data.map((x) => {
      return x.duration_days + "-" + x.duration_nights;
    });
    console.log("days", days);
    days = [...new Set(days)];
    let dates = data.data.map((x) => {
      return x.departure_date;
    });
    console.log("dates", dates);
    dates = [...new Set(dates)];

    // console.log('formatted datas', newObj)
    this.setState(
      {
        sampleArr: dates,
      },
      () => {
        const slice = this.state.sampleArr.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );
        const newObj = {
          rows: days,
          col: slice,
          rowDatas: obj,
        };
        this.props.showTableData(newObj);
      }
    );
    // this.setState({
    //     rows: days,
    //     col: dates,
    //     rowDatas: obj,
    //     showTable: true
    // })
    // console.log('formatted datas', newObj)
  };

  // csv data format
  csvDataConversion = (data) => {
    // var rowsCount = 0;
    // var colsCount = 0;
    var hash = {};
    var durationDayHash = {};
    for (var i = 0; i < data.length; i++) {
      var item = data[i];
      var packageList = hash[item.departure_date];
      if (packageList == null) packageList = [];
      var packageItem = {
        amount: item.amount,
        day: item.duration_days + -+item.duration_nights,
      };
      durationDayHash[item.duration_days] = true;
      packageList.push(packageItem);
      hash[item.departure_date] = packageList;
    }
    return hash;
  };
  //selectMoretravelers
  selectMoreTravelers = (e, name) => {
    console.log("more4", e, name);
    this.setState(
      {
        [name]: e,
        morePassengers: { ...this.state.morePassengers, [name]: e.value },
      },
      () => {
        console.log("morepassgensrs", this.state);
      }
    );
  };

  // more passemgers request
  sendRequest = async () => {
    const requiredFields = copyObj(this.state.morePassengers);
    let errors = validateInputs(requiredFields);
    if (Object.keys(errors).length === 0) {
      this.setState({
        loading: true,
      });
      const data = {
        url: `${this.state.anotherdate ? "anotherday/" : "passengers/"}`,
        body: requiredFields,
      };
      const newData = await apiPost(data);
      console.log("update more passengers", newData);
      if ("status" in newData && newData.status === 201) {
        this.onCloseModal();
        this.setState({
          loading: false,
          show: true,
        });
      } else {
        alert("Something went wrong");
      }
      console.log("newData enuiry", newData);
    } else {
      this.setState({
        errors: errors,
        loading: false,
      });
    }
  };

  // step zero functions ends
  upgradesPackageChange = (e, type, keys, index, price) => {
    const { key } = keys;
    const { extrasPackage, packageObj, selectedPrice, package_cmp } =
      this.props.cmp;
    // const {total_qty,Total_amount}=packageObj
    // packageObj['Total_amount']=total_qty * price +parseInt(selectedPrice)
    console.log("extras package checkked", type, keys, index, price);
    var newType = extrasPackage[type];
    const sampleData = newType.map((x, i) => {
      if (i === index) {
        x["checked"] = true;
      } else {
        x["checked"] = false;
      }
      return x;
    });
    extrasPackage[type] = sampleData;
    // this.props.selectPackage(packageObj)
    this.props.getExtraComps(extrasPackage);
    console.log("extras package checkked dd", extrasPackage, sampleData);
    this.priceCalculation();
  };
  // car rental and transfer
  packChange = (e, type, keys, index, price) => {
    const { key } = keys;
    const { checked } = e.target;
    const { extrasPackage, packageObj, selectedPrice, package_cmp } =
      this.props.cmp;
    // const {total_qty,Total_amount}=packageObj
    // packageObj['Total_amount']=total_qty * price +parseInt(selectedPrice)
    console.log("extras package checkked", type, keys, index, price);
    var newType = extrasPackage[type];
    if (checked) {
      const sampleData = newType.map((x, i) => {
        if (i === index) {
          x["checked"] = true;
          if (type === "car_rental") {
            x["total_price"] = this.props.lang.langObj.later_to_pay;
          }
        } else {
          x["checked"] = false;
          if (type === "car_rental") {
            x["total_price"] = this.props.lang.langObj.later_to_pay;
          }
        }
        return x;
      });
      extrasPackage[type] = sampleData;
      // this.props.selectPackage(packageObj)
      this.props.getExtraComps(extrasPackage);
      console.log("extras package checkked dd", extrasPackage, sampleData);
    } else {
      const isIncPack = newType.some((x) => x.key === 0);
      if (isIncPack) {
        const isAll = newType
          .filter((x, i) => i !== index)
          .every((x) => x.checked === false);
        if (isAll) {
          const sampleData = newType.map((x, i) => {
            if (x.key === 0) {
              x["checked"] = true;
            } else {
              x["checked"] = false;
            }
            return x;
          });
          extrasPackage[type] = sampleData;
          this.props.getExtraComps(extrasPackage);
        } else {
          const sampleData = newType.map((x, i) => {
            if (i === index) {
              x["checked"] = false;
            }
            return x;
          });
          extrasPackage[type] = sampleData;
          this.props.getExtraComps(extrasPackage);
        }
      } else {
        const sampleData = newType.map((x, i) => {
          if (i === index) {
            x["checked"] = false;
          }
          return x;
        });
        extrasPackage[type] = sampleData;
        this.props.getExtraComps(extrasPackage);
      }
    }
    this.priceCalculation();
  };

  // airlinechecj
  airlineCheck = () => {
    console.log("addeded value clickinh");
  };

  //transfer,tour, car rental change
  upgradesExtrasChange = (e, type, keys, index, price) => {
    const { key } = keys;
    const { checked } = e.target;
    const { extrasPackage, packageObj, selectedPrice, package_cmp } =
      this.props.cmp;
    // const {total_qty,Total_amount}=packageObj
    // packageObj['Total_amount']=total_qty * price +parseInt(selectedPrice)
    console.log("extras package checkked", type, keys, index, price);
    var newType = extrasPackage[type];
    if (checked) {
      const sampleData = newType.map((x, i) => {
        if (i === index) {
          x["checked"] = true;
          x["total_price"] = this.props.lang.langObj.later_to_pay;
        } else {
          x["checked"] = false;
          x["total_price"] = this.props.lang.langObj.later_to_pay;
        }
        console.log("tourcheange");
        return x;
      });
      extrasPackage[type] = sampleData;
      // this.props.selectPackage(packageObj)
      this.props.getExtraComps(extrasPackage);
    } else {
      const sampleData = newType.map((x, i) => {
        if (i === index) {
          x["checked"] = false;
        }
        return x;
      });
      extrasPackage[type] = sampleData;
      // this.props.selectPackage(packageObj)
      this.props.getExtraComps(extrasPackage);
    }
    // console.log('extras package checkked dd', extrasPackage, sampleData)
    this.priceCalculation();
  };

  extrasPackageChange = (e, type, keys, index, price) => {
    console.log("added value working", type);
    const { name, checked } = e.target;
    const { extrasPackage, packageObj, selectedPrice, package_cmp } =
      this.props.cmp;
    if (checked) {
      extrasPackage[type][index]["checked"] = true;
      this.props.getExtraComps(extrasPackage);
      this.priceCalculation();
    } else {
      extrasPackage[type][index]["checked"] = false;
      this.props.getExtraComps(extrasPackage);
      this.priceCalculation();
    }
  };
  //price calculation
  priceCalculation = async () => {
    const { packageObj, extrasPackage, selectedPrice } = this.props.cmp;
    const obj = {
      package_name: packageObj.package_name,
      package_code: this.props.match.params.id,
      amount: selectedPrice,
      qty: packageObj.qty,
      duration: packageObj.duration,
      start_date: packageObj.start_date,
      end_date: packageObj.end_date,
      total_qty: packageObj.total_qty,
      Total_amount: packageObj.Total_amount,
      final_price: selectedPrice,
      extras: extrasPackage,
      qty_number: packageObj.qty_number,
      duration_number: packageObj.duration_number,
    };
    const dataObj = {
      url: "calculation/",
      body: { step_3: obj, selected_price: "" },
    };
    const newData = await apiPost(dataObj);
    this.props.selectPackage(newData.package_details);
    console.log("After price Cal", newData);
  };

  getCountry = async () => {
    // const { stepOneInputs } = this.props.cmp
    // stepOneInputs['country'] = stepOneInputs.country ? stepOneInputs.country : { label: 'belgium', value: 'belgium' }
    // this.props.changeStepOne(stepOneInputs)
    const data = {
      url: "country/",
      body: {
        text_language: this.props.lang.selectedLang.toUpperCase(),
      },
    };
    const newData = await apiPost(data);
    const list = newData.country.map((x) => {
      return { label: x, value: x };
    });
    this.setState({
      countryOptions: list,
    });
    console.log("countrylist", newData);
  };
  // get countrylist
  // stepone inputs change
  onInputChange = (e) => {
    const { stepOneInputs } = this.props.cmp;
    const { name } = e.target;
    var value = e.target.value;
    console.log("inputs change", name, value);
    var phoneno = /^(?=.*?[1-9])[0-9()-]+$/;
    let errVal;
    var confirm_email = "";
    switch (name) {
      case "first_name":
        const e = { target: { name: name, value: value } };
        this.adultIpChange(e, 0);
        break;
      case "last_name":
        const ip = { target: { name: name, value: value } };
        this.adultIpChange(ip, 0);
        break;
      case "house_number":
        value = value.toString().slice(0, 7);
        parseInt(value);
        break;
      case "postal_code":
        value = value.toString().slice(0, 10);
        parseInt(value);
        break;
      case "vat_number":
        console.log("vat number", value);
        value.toString().toUpperCase();
        console.log("after vak", value);
        value = value.replace(/[^A-Z0-9]/gi, "");
        // parseInt(value);
        break;
      case "telephone_number":
        console.log("Error Comesss", value);
        //  value= value==='e'?'':parseInt(value)
        value = value.replace(/[^+()/0-9]/gi, "");
        value = value.toString().slice(0, 16);
        // errVal = !phoneno.test(value) ? 'Phone number is not Valid' : ''
        // errVal = !phoneno.test(value) ? 'Invalid' : ''
        //value = value.toString().slice(0, 16);
        //parseInt(value);
        console.log("erorr Value ", errVal);
        break;
      case "email":
        if (value.length > 4) {
          errVal = !validEmailRegex.test(value)
            ? this.props.lang.langObj.invalid_email
            : "";
        }
        /* if (stepOneInputs.confirm_email) {
                        const compareEmail = value.localeCompare(stepOneInputs.confirm_email)
                        confirm_email = compareEmail === 0 ? '' : 'Email is mismatched'
                    } */
        break;
      case "confirm_email":
        if (value.length > 4) {
          const comparePwd = stepOneInputs.email.localeCompare(value);
          errVal =
            comparePwd === 0 ? "" : this.props.lang.langObj.email_not_match;
        }
        // errVal = !validEmailRegex.test(value) ? 'Email is not Valid' : ''
        break;
      default:
    }
    console.log("errVal", errVal);
    this.setState(
      {
        fieldErrOne: {
          ...this.state.fieldErrOne,
          confirm_email: confirm_email,
          [name]: errVal !== undefined ? errVal : "",
        },
      },
      () => {
        console.log("after servsl", this.state.fieldErrOne);
      }
    );
    stepOneInputs[name] = value;
    console.log("stepone change", stepOneInputs);
    // this.setState({
    //     stepOneInputs: { ...this.state.stepOneInputs, [name]: value }
    // }, () => {
    this.props.changeStepOne(stepOneInputs);
    // })
  };

  // steptwoInpust changes
  inputChange = (e, i, type) => {
    if (type === "adult") {
      this.adultIpChange(e, i);
    } else {
      this.childIpChange(e, i);
    }
  };
  // child
  childIpChange = (e, i) => {
    const { name, value } = e.target;
    var obj = {
      gender: "",
      first_name: "",
      last_name: "",
      day: "",
      month: "",
      year: "",
    };
    let childArr = copyObj(this.props.cmp.child);
    if (childArr[i]) {
      const newChild = childArr.map((data, ind) =>
        ind === i ? { ...data, [name]: value } : data
      );
      this.props.changeStepTwoChild(newChild);
    } else {
      obj[name] = value;
      childArr[i] = obj;
      this.props.changeStepTwoChild(childArr);
    }
  };

  // adult
  adultIpChange = (e, i) => {
    const { name, value } = e.target;
    var obj = {
      gender: "",
      first_name: "",
      last_name: "",
      day: "",
      month: "",
      year: "",
    };
    let adultArr = copyObj(this.props.cmp.adult);
    if (adultArr[i]) {
      const newAdult = adultArr.map((data, ind) =>
        ind === i ? { ...data, [name]: value } : data
      );
      this.props.changeStepTwoAdult(newAdult);
    } else {
      obj[name] = value;
      adultArr[i] = obj;
      this.props.changeStepTwoAdult(adultArr);
    }
  };

  // select country
  selectCountry = (val) => {
    const { stepOneInputs } = this.props.cmp;
    stepOneInputs["country"] = val;
    this.props.changeStepOne(stepOneInputs);
    // this.setState({
    //     showCountry: true,
    //     country: val,
    //     stepOneInputs: { ...this.state.stepOneInputs, 'country': val.value }

    // })
  };
  selectGender = (i, val, type, name, label) => {
    if (type === "adult") {
      this.adultDropdownChange(val, i, name, label);
    } else {
      this.childDropdownChange(val, i, name, label);
    }
  };

  // childDropdowns
  childDropdownChange = (val, i, name, label) => {
    var obj = {
      gender: "",
      first_name: "",
      last_name: "",
      day: "",
      month: "",
      year: "",
    };
    let childArr = copyObj(this.props.cmp.child);
    if (childArr[i]) {
      const newChild = childArr.map((data, ind) =>
        ind === i ? { ...data, [name]: val } : data
      );
      var age = this.getAge(newChild[i]);
      if (age > 12) {
        newChild[i][name] = "";
        this.props.changeStepTwoChild(newChild);
        newChild[i].day = "";
        newChild[i].month = "";
        newChild[i].year = "";
        Alert.error(this.props.lang.langObj.child_warning_message, {
          position: "top-right",
          effect: "jelly",
          onShow() {},
          beep: true,
          timeout: 2000,
          offset: 50,
        });
      } else {
        this.props.changeStepTwoChild(newChild);
      }
    } else {
      obj[name] = val;
      childArr[i] = obj;
      var age = this.getAge(childArr[i]);
      if (age > 12) {
        childArr[i][name] = "";
        this.props.changeStepTwoChild(childArr);
        childArr[i].day = "";
        childArr[i].month = "";
        childArr[i].year = "";
        Alert.error(this.props.lang.langObj.child_warning_message, {
          position: "top-right",
          effect: "jelly",
          onShow() {},
          beep: true,
          timeout: 2000,
          offset: 50,
        });
      } else {
        this.props.changeStepTwoChild(childArr);
      }
    }
  };

  getAge = (ages) => {
    console.log("dateStringd", ages);
    var day = ages.day ? ages.day.value : 0;
    var mnth = ages.month ? monthObj[ages.month.label] : 0;
    var year = ages.year ? ages.year.value : 0;

    if (year) {
      var today = new Date();
      // var birthDate = new Date(dateString);
      //   var age = today.getFullYear() - year;
      //   var m = today.getMonth() - parseInt(mnth);
      //   if (m < 0 || (m === 0 && today.getDate() < day)) {
      //     age--;
      //   }
      var diff = moment().diff(
        moment(year + "-" + mnth + "-" + day, "YYYY-M-D"),
        "seconds"
      );

      // console.log(diff, "diffffffffffff");
      var age = diff / (60 * 60 * 24 * 365.25);
      console.log("s/elected date", age);
      return age;
    } else {
      console.log("not fully selected");
      return 0;
    }
  };
  // adultDropdownChange
  adultDropdownChange = (val, i, name, label) => {
    var obj = {
      gender: "",
      first_name: "",
      last_name: "",
      day: "",
      month: "",
      year: "",
    };
    let adultArr = copyObj(this.props.cmp.adult);
    if (adultArr[i]) {
      const newAdult = adultArr.map((data, ind) =>
        ind === i ? { ...data, [name]: val } : data
      );
      var age = this.adultAge(newAdult[i]);
      console.log("final adultage", age);
      if (age < 12) {
        newAdult[i][name] = "";
        this.props.changeStepTwoAdult(newAdult);
        newAdult[i].day = "";
        newAdult[i].month = "";
        newAdult[i].year = "";
        Alert.error(this.props.lang.langObj.adult_warning_message, {
          position: "top-right",
          effect: "jelly",
          onShow() {},
          beep: true,
          timeout: 2000,
          offset: 50,
        });
      } else {
        this.props.changeStepTwoAdult(newAdult);
      }
    } else {
      obj[name] = val;
      adultArr[i] = obj;
      var age = this.adultAge(adultArr[i]);
      if (age < 18) {
        adultArr[i][name] = "";
        this.props.changeStepTwoAdult(adultArr);
        adultArr[i].day = "";
        adultArr[i].month = "";
        adultArr[i].year = "";
        Alert.error(this.props.lang.langObj.adult_warning_message, {
          position: "top-right",
          effect: "jelly",
          onShow() {},
          beep: true,
          timeout: 2000,
          offset: 50,
        });
      } else {
        this.props.changeStepTwoAdult(adultArr);
      }
    }
  };

  // adult ages
  adultAge = (ages) => {
    console.log("dateStringd", ages);
    var day = ages.day ? ages.day.value : 0;
    var mnth = ages.month ? monthObj[ages.month.label] : 0;
    var year = ages.year ? ages.year.value : 0;
    if (year) {
      var today = new Date();
      // var birthDate = new Date(dateString);
      var age = today.getFullYear() - year;
      var m = today.getMonth() - parseInt(mnth);
      if (m < 0 || (m === 0 && today.getDate() < day)) {
        age--;
      }
      // console.log('s/elected date',day,mnth,year,age)
      return age;
    } else {
      return 18;
      // console.log('not fully selected')
    }
  };

  selectDays = (i, e) => {
    var copyObj = this.state.step2Arr;
    copyObj[i].date = i;
    this.setState({ step2Arr: copyObj });
  };
  selectYear = (i, e) => {
    var copyObj = this.state.step2Arr;
    console.log("console year", i, copyObj);
    copyObj[i].year = i;
    console.log("console year", i, copyObj);

    this.setState({ step2Arr: copyObj });
  };
  selectMonth = (i, e) => {
    var copyObj = this.state.step2Arr;
    copyObj[i].month = i;
    this.setState({ step2Arr: copyObj });
  };
  // show invoice components
  changeInvoice = () => {
    this.props.changeInvoice(!this.props.cmp.invoice);
    // this.setState({ invoice: !this.state.invoice })
  };

  //contact status
  checkChange = (e) => {
    const { checked, name } = e.target;
    const { stepOneInputs } = this.props.cmp;
    stepOneInputs[name] = checked;
    console.log("checked", name, stepOneInputs);
    this.props.changeStepOne(stepOneInputs);
  };

  //termsChange
  termsChange = (e) => {
    const { checked } = e.target;
    this.setState({
      terms: checked,
    });
  };

  // nextPage
  nextPage = () => {
    const { pageNum } = this.props.cmp;
    switch (pageNum) {
      case 1:
        this.validateStaepOne();
        break;
      case 2:
        this.validateStepTwo();
        break;
      case 3:
        this.validateStepThree();
        break;
      case 4:
        this.validateStepFour();
        break;
      default:
        break;
    }
  };

  // vaidate step4
  validateStepFour = () => {
    const { options, errVal } = this.props.opt;
    const { checkboxes, err_Val } = this.props.checks;
    const { option, err } = this.props.type;
    const { priceCalcObj } = this.props.cmp;

    // console.log('validate fpit after',packageVars,option)
    if (
      priceCalcObj.insurance_amount[0].cancellation_insurance !== "0.00" &&
      priceCalcObj.insurance_amount[0].assistance_insurance !== "0.00" &&
      priceCalcObj.insurance_amount[0].all_in_insurance !== "0.00"
    ) {
      if (checkboxes.length > 0) {
        this.props.showErr(false);
      } else {
        this.props.showErr(true);
      }
    }

    // if()
    if (options.length > 0) {
      this.props.err_Val(false);
    } else {
      this.props.err_Val(true);
    }
    if (option.length > 0) {
      console.log(" no err", option);
      this.props.err(false);
    } else {
      console.log("have err", option);
      this.props.err(true);
    }
    if (this.state.terms) {
      this.setState(
        {
          errTerm: false,
        },
        () => {
          this.finalStep(err, errVal, err_Val);
        }
      );
    } else {
      this.setState(
        {
          errTerm: true,
        },
        () => {
          this.finalStep(err, errVal, err_Val);
        }
      );
    }
  };

  // finalstep
  finalStep = () => {
    const { selectedLang } = this.props.lang;
    const { options, errVal } = this.props.opt;
    const language = this.props.lang.selectedLang.toLowerCase();
    const { checkboxes, errMsg } = this.props.checks;
    const { option, err } = this.props.type;
    const {
      packageObj,
      priceCalcObj,
      stepOneInputs,
      child,
      adult,
      extrasPackage,
    } = this.props.cmp;
    var packageVars = this.props.cmp.selectedPackage.package_cmp.package[0];
    console.log("err msg", err, errMsg, errVal, options, option);
    if (!err && !errMsg && !errVal && !this.state.errTerm) {
      this.setState({
        paymentLoading: true,
      });
      console.log("running to payment", packageObj);
      var stepOne = copyObj(stepOneInputs);
      packageObj["package_code"] = this.props.match.params.id;
      packageObj["Total_amount"] =
        option === "full payment"
          ? packageObj.Total_amount
          : (parseInt(packageVars.partial_amount) / 100) *
            parseInt(packageObj.Total_amount);
      stepOne["country"] = stepOneInputs.country.value;
      var adultOpt = copyObj(adult);
      var newAdult = adultOpt.map((x, i) => {
        return {
          ...x,
          gender: x.gender.value,
          day: x.day.value,
          month: x.month.value,
          year: x.year.value,
        };
      });
      var childOpt = copyObj(child);
      var newChild = childOpt.map((x, i) => {
        return {
          ...x,
          gender: x.gender.value,
          day: x.day.value,
          month: x.month.value,
          year: x.year.value,
        };
      });
      const extrasOpt = copyObj(extrasPackage);
      const finalObj = Object.assign(
        {},
        { text_language: selectedLang.toUpperCase() },
        stepOne,
        { extras: extrasOpt },
        { priceCal: priceCalcObj },
        packageObj,
        { packObj:packageObj },
        { child: newChild },
        { adult: newAdult },
        { selected_assistance: checkboxes[0] },
        { payment_type: option },
        { payment_method: options[0].replace(/[0-9]/g, "") },  
        { payment_id: options},
        { language: language},
        { redirect_url: `${frontEndUrl}${language}/success?pack=${packageObj['package_code']}`}
      );
      
      
      this.createPayment(finalObj);
    } else {
      if (errMsg) {
        $("html, body").animate({ scrollTop: 0 }, 0);
      }
    }
  };

  createPayment = async (obj) => {

    const language = this.props.lang.selectedLang.toLowerCase();
    const data = {
      url: "userbooking/",
      body: obj,
    };
    const newData = await apiPost(data);
    console.log("BEFORE URLpayment status", newData);
    if ("status" in newData && newData.status === 201) {
      const url = newData.payment_created.redirectUrl;
      const id = newData.payment_created.hostedCheckoutId;
      localStorage.setItem("id", id);
      localStorage.setItem("refid", newData.ref_id);
      // window.location.replace(url);
      
      window.location.replace(url);

      
    } else if ("status" in newData && newData.status === 202) {
      localStorage.setItem("refid", newData.ref_id);
      const url = `${frontEndUrl}${language}/pending-state/${newData.ref_id}`;

      // console.log("url", url);
      window.location.replace(url);
      // this.props.history.push(`${language}/pending-state/${newData.ref_id}`)
    }
    console.log("expecting payment", newData);
    // alert("expectiong payment", newData)
  };

  validateStaepOne = () => {
    const { stepOneInputs, invoice } = this.props.cmp;
    const required = _.pick(
      stepOneInputs,
      "first_name",
      "email",
      "contact_status",
      "telephone_number",
      "street",
      "last_name",
      "house_number",
      "postal_code",
      "city",
      "country",
      "confirm_email",
      `${invoice ? "company_name" : ""}`,
      `${invoice ? "vat_number" : ""}`
    );
    let errors = validateInputs(required);
    const isField_err = Object.values(this.state.fieldErrOne).every(
      (x) => x === null || x === "" || x === undefined
    );
    console.log("errors", errors, isField_err, this.state.fieldErrOne);
    if (Object.keys(errors).length === 0 && isField_err) {
      this.setState({
        errOne: errors,
      });
      $("html, body").animate({ scrollTop: 0 }, 0);
      this.props.changePageNumber(this.props.cmp.pageNum + 1);
      // this.stepTwoFields()
    } else {
      this.setState({
        errOne: errors,
      });
    }
  };
  // edit section
  editSection = (num) => {
    this.props.changePageNumber(num);
  };

  // validateStepTWO
  validateStepTwo = () => {
    console.log("steptwo running");
    var newAdults = copyObj(this.props.cmp.adult);
    var newChilds = copyObj(this.props.cmp.child);
    //    var isChild=newChilds.length===noOfChild.length?true:false
    //    var isAdult=newAdults.length===noOfAdults.length?true:false
    var joinedArr = this.arrCheck(newAdults.concat(newChilds));
    if (joinedArr === 1) {
      this.setState({
        errTwo: "",
      });
      $("html, body").animate({ scrollTop: 0 }, 0);
      this.props.changePageNumber(this.props.cmp.pageNum + 1);
    } else {
      this.setState({
        errTwo: this.props.lang.langObj.step_2_required_fields,
      });
    }
    console.log("validate two", joinedArr);
  };

  // step three
  validateStepThree = async () => {
    this.props.resetInsurance();
    console.log("stepthree", this.props.cmp);
    const { packageObj, extrasPackage, selectedPrice } = this.props.cmp;
    const obj = {
      package_name: packageObj.package_name,
      package_code: this.props.match.params.id,
      amount: packageObj.amount,
      qty: packageObj.qty,
      duration: packageObj.duration,
      start_date: packageObj.start_date,
      end_date: packageObj.end_date,
      total_qty: packageObj.total_qty,
      Total_amount: packageObj.Total_amount,
      final_price: selectedPrice,
      extras: extrasPackage,
      qty_number: packageObj.qty_number,
      duration_number: packageObj.duration_number,
    };
    const dataObj = {
      url: "calculation/",
      body: { step_3: obj, selected_price: "" },
    };
    const newData = await apiPost(dataObj);
    // this.setState({
    //     priceCalcObj:newData
    // })

    this.props.priceCal(newData);
    this.props.selectPackage(newData.package_details);

    console.log("stepthreeafter", newData);
    $("html, body").animate({ scrollTop: 0 }, 0);
    this.props.changePageNumber(this.props.cmp.pageNum + 1);
  };
  // arrInputFiledsCheck
  arrCheck = (newUpgrades) => {
    console.log("check j", newUpgrades);
    var packageErr = true;
    for (var i = 0; i < newUpgrades.length; i++) {
      console.log("packageerrr aRR", newUpgrades[i]);
      packageErr =
        Object.values(newUpgrades[i]).every((x) => x !== "") * packageErr;
    }
    return packageErr;
  };
  prevPage = () => {
    $("html, body").animate({ scrollTop: 0 }, 0);
    this.props.changePageNumber(this.props.cmp.pageNum - 1);
    // this.setState({
    //     pageNum: this.props.cmp.pageNum - 1
    // })
  };
  // show invoice components

  // next
  // nextData=()=>{
  //  var selectedPage=this.state.currentPage+1
  //  const offset=selectedPage* this.state.perPage
  //  this.setState({offset:offset,currentPage:selectedPage},()=>{
  //      this.changeData()
  //  })

  // }

  nextData = () => {
    var selectedPage = this.state.currentPage + 1;
    const offset = selectedPage * this.state.perPage;
    this.setState({ offset: offset, currentPage: selectedPage }, () => {
      this.changeData();
    });
  };

  prevData = () => {
    var selectedPage = this.state.currentPage - 1;
    const offset = selectedPage * this.state.perPage;
    this.setState({ offset: offset, currentPage: selectedPage }, () => {
      this.changeData();
    });
  };
  changeData = () => {
    const { sampleArr, perPage, offset, currentPage } = this.state;
    console.log(
      "changdinf table data",
      sampleArr,
      perPage,
      offset,
      currentPage
    );
    // const count=data.length
    const sliceArr = sampleArr.slice(offset, offset + perPage);
    const newObj = {
      rows: this.props.cmp.rows,
      col: sliceArr,
      rowDatas: this.props.cmp.rowDatas,
    };
    console.log("final array nex", sliceArr, sampleArr);
    this.props.showTableData(newObj);
  };

  render() {
    const { langObj, selectedLang } = this.props.lang;
    const stayOptions = [
      {
        label: `5 ${langObj.days} - 4 ${langObj.nights}`,
        value: "5days4nights",
      },
      {
        label: `6 ${langObj.days} - 5 ${langObj.nights}`,
        value: "6days5nights",
      },
      {
        label: `9 ${langObj.days} - 8 ${langObj.nights}`,
        value: "9days8nights",
      },
    ];
    const languagess = selectedLang.toUpperCase();
    const dateFormat = languagess === "EN" ? "MMMM Do YYYY" : "Do MMMM YYYY";
    const termsUrl =
      languagess === "NL"
        ? "https://www.connections.be/nl-be/reisinformatie/algemene-reisvoorwaarden"
        : languagess === "FR"
        ? "https://www.connections.be/fr-be/info-de-voyage/conditions-generales-de-voyages"
        : languagess === "EN"
        ? "https://www.connections.be/en/travel-information/terms-and-conditions"
        : "";
    const year = new Date().getFullYear();
    const years = Array.from(new Array(200), (val, index) => {
      return { label: year - index, value: year - index };
    });
    //const months = Array.from(new Array(12), (val, index) => { return { 'label': index + 1, value: index + 1 } });
    const dates = Array.from(new Array(31), (val, index) => {
      return { label: index + 1, value: index + 1 };
    });
    const monthArray = [
      { label: langObj.month_1, value: 1 },
      { label: langObj.month_2, value: 2 },
      { label: langObj.month_3, value: 3 },
      { label: langObj.month_4, value: 4 },
      { label: langObj.month_5, value: 5 },
      { label: langObj.month_6, value: 6 },
      { label: langObj.month_7, value: 7 },
      { label: langObj.month_8, value: 8 },
      { label: langObj.month_9, value: 9 },
      { label: langObj.month_10, value: 10 },
      { label: langObj.month_11, value: 11 },
      { label: langObj.month_12, value: 12 },
    ];

    const {
      packageObj,
      priceCalcObj,
      daysMonthsYears,
      travelers,
      showTable,
      rows,
      col,
      rowDatas,
      selectedMonth,
      index,
      data,
      showSummary,
      child,
      adult,
      pageNum,
      invoice,
      stepOneInputs,
      extrasPackage,
    } = this.props.cmp;

    const { package_cmp, travelOptions, monOptions } =
      this.props.cmp.selectedPackage;
    var packageCmp = this.props.cmp.selectedPackage.package_cmp;
    var adults = 0;
    var children = 0;
    var totalTravelers = [];
    var noOfAdults = [];
    var noOfChild = [];
    var imageUrl = "";
    var packName = "";
    var price = "";
    if (Object.keys(packageObj).length > 0) {
      totalTravelers = packageObj.qty.split(" ").filter((x, i) => !isNaN(x));
      adults = totalTravelers.length > 0 ? totalTravelers[0] : 0;
      children = totalTravelers.length > 1 ? totalTravelers[1] : 0;
      noOfAdults = Array.from(Array(parseInt(adults)).keys());
      noOfChild = Array.from(Array(parseInt(children)).keys());
    }
    console.log("packagecmp", this.props);
    const {
      showCountry,
      step2Arr,
      fieldErrOne,
      country,
      errTwo,
      showDays,
      showGender,
      showYear,
      errOne,
      paymentLoading,
      loading,
    } = this.state;
    // step zero render
    const { morePassengers, keys, packageSummary, errors, field_errors, show } =
      this.state;
    if (packageCmp) {
      var packageCommonDetails = packageCmp.package[0];
      imageUrl =
        "package" in package_cmp
          ? package_cmp.package.length > 0
            ? package_cmp.package[0].image
            : ""
          : "";
      packName =
        "package" in package_cmp
          ? package_cmp.package.length > 0
            ? package_cmp.package[0].name
            : ""
          : "";
      price =
        "package" in package_cmp
          ? package_cmp.package.length > 0
            ? package_cmp.package[0].from_price
            : ""
          : "";
      if ("package" in packageCmp) {
        var packageVars = packageCmp.package[0];
      }
      console.log("packageVars", packageVars);
    }
    const { options, errVal } = this.props.opt;
    const { errMsg, selected } = this.props.checks;
    const { option, err } = this.props.type;
    // ends

    // gender options
    const genderOptions = [
      { value: "male", label: langObj.gender_1 },
      { value: "female", label: langObj.gender_2 },
    ];
    // Moment.globalMoment = moment;
    // Moment.globalLocale = 'fr';
    // Moment.globalFormat = 'MMM YYYY';a
    var selLang = this.props.lang.selectedLang.toLowerCase();
    console.log("selLang", selLang);
    moment.locale(selLang); // default the locale to English
    var localLocale = moment();
    // console.log('moment', localLocale, moment(new Date()).format(dateFormat))
    return pageNum === 0 ? (
      <PageWrapper>
        <ImageContainer img={imageUrl}>
          <Name className="text-capitalize">{packName}</Name>
          <PriceTag>
            {langObj.dashboard_from}{" "}
            <span className="font-sm mx-1">
              {" "}
              <span className="font-sm mx-1">€</span>{" "}
            </span>{" "}
            {price}
          </PriceTag>
        </ImageContainer>
        <StepZeroContainer>
          <StepZeroH6>{langObj.step_0_description}</StepZeroH6>
          {/* <Moment>October 2020</Moment> */}
          <StepZeroH3>{langObj.step_0_intro}</StepZeroH3>
          {/* package detail starts */}
          {packageCmp ? (
            <IncludedPackage>
              {"airline" in package_cmp
                ? package_cmp.airline.length > 0
                  ? package_cmp.airline.map((x, i) => {
                      return (
                        <PackageLabel
                          img={PlaneIcon}
                          heading={`${packageCommonDetails.flight_status} - ${packageCommonDetails.flight_name}`}
                        >
                          <StepZeroHighlightText>
                            {x.name}
                          </StepZeroHighlightText>
                          <StepZeroStepZeroHighlightText>
                            {`${
                              package_cmp.package[0].luggage_included
                                ? package_cmp.package[0].luggage_qty +
                                  ` kg of ${langObj.luggage}`
                                : ""
                            }`}
                          </StepZeroStepZeroHighlightText>
                        </PackageLabel>
                      );
                    })
                  : ""
                : ""}
              {"hotel" in package_cmp
                ? package_cmp.hotel.length > 0
                  ? package_cmp.hotel.map((x, i) => {
                      return (
                        <PackageLabel
                          cls="m-0"
                          img={HotelIcon}
                          heading={langObj.component_2}
                        >
                          <StepZeroHighlightText>
                            {x.name}
                          </StepZeroHighlightText>
                          {/* <StepZeroStepZeroHighlightText>{x.room_name}</StepZeroStepZeroHighlightText> */}
                          {x.breakfast ? (
                            <StepZeroStepZeroHighlightText className="first-letter-cap">
                              {langObj.breakfast}
                            </StepZeroStepZeroHighlightText>
                          ) : (
                            ""
                          )}
                        </PackageLabel>
                      );
                    })
                  : ""
                : ""}
              <FlexWrapper className="m-0 more-passengers-container">
                <PackageSections className="step-zero-package-section">
                  {"tour" in package_cmp
                    ? package_cmp.tour.length > 0
                      ? package_cmp.tour.map((x, i) => {
                          return (
                            <PackageLabel
                              img={TourIcon}
                              heading={langObj.component_3}
                            >
                              <StepZeroHighlightText>
                                {x.name}
                              </StepZeroHighlightText>
                            </PackageLabel>
                          );
                        })
                      : ""
                    : ""}
                  {"excursion" in package_cmp
                    ? package_cmp.excursion.length > 0
                      ? package_cmp.excursion.map((x, i) => {
                          return (
                            <PackageLabel
                              img={ExcursionIcon}
                              heading={langObj.component_4}
                            >
                              <StepZeroHighlightText>
                                {x.name}
                              </StepZeroHighlightText>
                            </PackageLabel>
                          );
                        })
                      : ""
                    : ""}
                </PackageSections>
                <PackageSections className="step-zero-package-section">
                  {"car_rental" in package_cmp
                    ? package_cmp.car_rental.length > 0
                      ? package_cmp.car_rental.map((x, i) => {
                          return (
                            <PackageLabel
                              img={ExtraIcon}
                              heading={langObj.component_6}
                            >
                              <StepZeroHighlightText>
                                {x.name}
                              </StepZeroHighlightText>
                            </PackageLabel>
                          );
                        })
                      : ""
                    : ""}
                  <StepZeroStepZeroSeparator className="hr-none" />
                  {"transfer" in package_cmp
                    ? package_cmp.transfer.length > 0
                      ? package_cmp.transfer.map((x, i) => {
                          return (
                            <PackageLabel
                              img={ExtraIcon}
                              heading={langObj.component_5}
                            >
                              <StepZeroHighlightText>
                                {x.name}
                              </StepZeroHighlightText>
                            </PackageLabel>
                          );
                        })
                      : ""
                    : ""}
                  <StepZeroStepZeroSeparator className="hr-none" />
                  {"added_value" in package_cmp
                    ? package_cmp.added_value.length > 0
                      ? package_cmp.added_value.map((x, i) => {
                          return (
                            <PackageLabel
                              img={ExtraIcon}
                              heading={langObj.component_7}
                            >
                              <StepZeroHighlightText>
                                {x.name}
                              </StepZeroHighlightText>
                            </PackageLabel>
                          );
                        })
                      : ""
                    : ""}
                </PackageSections>
              </FlexWrapper>
            </IncludedPackage>
          ) : (
            ""
          )}
          {/* package detail ends */}
          {/* selecting no of travelers */}
          <FlexWrapper className="traveleres-section">
            <PackageSections className="travelers-col">
              <StepZeroH4>{langObj.step_0_qty}</StepZeroH4>
              {packageVars && packageVars.traveller_count ? (
                <LinkText
                onClick={() => this.onOpenModal(false)}
                className="underline no-of-travelers c-pointer web-more-passengers"
              >
                {langObj.step_0_passengers}
              </LinkText>
              ) : (null) }
              {/* more than 4 passengers modal */}
              <Modal
                classNames={{
                  overlay: "modal-passengers-overlay",
                  modal: "modal-passengers-container",
                  closeButton: "modal-close-btn",
                }}
                open={this.state.open}
                onClose={this.onCloseModal}
                center
              >
                {!show ? (
                  <EnquiryContainer>
                    <Text className="">{langObj.more_4_intro}!</Text>
                    <FlexPackageIcon>
                      <PackageIcon src={TravelIcon} alt="package imag" />
                      <h3 className="primary-text text-bold">
                        {langObj.more_4_head}
                      </h3>
                    </FlexPackageIcon>
                    <Text className=" my-2">{langObj.more_4_description}</Text>
                    <HelpUsFrom className="my-4">
                      <h4 className="primary-text">{langObj.more_4_title1}</h4>
                      <DropdownGroup className="align-base more-passengers-container">
                        <div className="w-25 more-passengers-single-section">
                          <Dropdown
                            label={`0 ${langObj.adults}`}
                            options={numbers}
                            // show={showTable}
                            value={this.state.adult_qty}
                            classname="dropdown-section w-100"
                            changeDropdown={(e) =>
                              this.selectMoreTravelers(e, "adult_qty")
                            }
                          />
                          <FlexWrapper className="justify-end my-1">
                            {errors.adult_qty && (
                              <span
                                className={`${
                                  morePassengers.adult_qty ? "d-none" : "error"
                                }`}
                              >
                                {errors.adult_qty}
                              </span>
                            )}
                            <Note>+12 {langObj.years}</Note>
                          </FlexWrapper>
                        </div>
                        <div className="w-25 mx-4 more-passengers-single-section">
                          <Dropdown
                            label={`0 ${langObj.children}`}
                            options={numbers}
                            // show={showTable}
                            value={this.state.child_qty}
                            classname="dropdown-section w-100 "
                            changeDropdown={(e) =>
                              this.selectMoreTravelers(e, "child_qty")
                            }
                          />
                          <FlexWrapper className="justify-end my-1">
                            {errors.child_qty && (
                              <span
                                className={`${
                                  morePassengers.child_qty ? "d-none" : "error"
                                }`}
                              >
                                {errors.child_qty}
                              </span>
                            )}
                            <Note>
                              +2 {langObj.to} 12 {langObj.years}
                            </Note>
                          </FlexWrapper>
                        </div>
                        <div className="w-25 more-passengers-single-section">
                          <Dropdown
                            label={`0 ${langObj.infants}`}
                            options={numbers}
                            value={this.state.infant_qty}
                            // show={showTable}
                            classname="dropdown-section w-100"
                            changeDropdown={(e) =>
                              this.selectMoreTravelers(e, "infant_qty")
                            }
                          />
                          <FlexWrapper className="justify-end my-1">
                            {errors.infant_qty && (
                              <span
                                className={`${
                                  morePassengers.infant_qty ? "d-none" : "error"
                                }`}
                              >
                                {errors.infant_qty}
                              </span>
                            )}
                            <Note>{langObj.more_4_pass_index}</Note>
                          </FlexWrapper>
                        </div>
                      </DropdownGroup>
                    </HelpUsFrom>
                    <HelpUsFrom className="my-4">
                      <h4 className="primary-text">{langObj.more_4_title2}</h4>
                      <FlexWrapper className="align-base more-passengers-container">
                        <DropdownGroup className="w-70 align-base more-passengers-container more-passengers-single-section">
                          <div className="w-25 more-passengers-single-section more-passengers-margin">
                            <Dropdown
                              label={langObj.day}
                              options={this.state.dateOptions}
                              // show={showTable}
                              value={this.state.day}
                              classname="dropdown-section w-100"
                              changeDropdown={(e) =>
                                this.selectMoreTravelers(e, "day")
                              }
                            />
                            {errors.day && (
                              <span
                                className={`${
                                  morePassengers.day ? "d-none" : "error"
                                }`}
                              >
                                {errors.day}
                              </span>
                            )}
                          </div>
                          <div className="w-25 mx-2 more-passengers-single-section more-passengers-margin">
                            <Dropdown
                              label={langObj.month}
                              options={monthArray}
                              // show={showTable}
                              value={this.state.month}
                              classname="dropdown-section w-100"
                              changeDropdown={(e) =>
                                this.selectMoreTravelers(e, "month")
                              }
                            />
                            {errors.month && (
                              <span
                                className={`${
                                  morePassengers.month ? "d-none" : "error"
                                }`}
                              >
                                {errors.month}
                              </span>
                            )}
                          </div>
                          <div className="w-25 more-passengers-single-section more-passengers-margin">
                            <Dropdown
                              label={langObj.year}
                              options={this.state.yearOptions}
                              value={this.state.year}
                              // show={showTable}
                              classname="dropdown-section w-100"
                              changeDropdown={(e) =>
                                this.selectMoreTravelers(e, "year")
                              }
                            />
                            {errors.year && (
                              <span
                                className={`${
                                  morePassengers.year ? "d-none" : "error"
                                }`}
                              >
                                {errors.year}
                              </span>
                            )}
                          </div>
                        </DropdownGroup>
                        <div className="w-30 more-passengers-single-section more-passengers-margin">
                          <Dropdown
                            label={langObj.length_of_stay}
                            options={stayOptions}
                            value={this.state.length_of_stay}
                            // show={showTable}
                            classname="dropdown-section w-100"
                            changeDropdown={(e) =>
                              this.selectMoreTravelers(e, "length_of_stay")
                            }
                          />
                          <FlexWrapper className="justify-end my-1">
                            {errors.length_of_stay && (
                              <span
                                className={`${
                                  morePassengers.length_of_stay
                                    ? "d-none"
                                    : "error"
                                }`}
                              >
                                {errors.length_of_stay}
                              </span>
                            )}
                            <Note>{langObj.length_of_stay}</Note>
                          </FlexWrapper>
                        </div>
                      </FlexWrapper>
                    </HelpUsFrom>
                    <HelpUsFrom className="my-3">
                      <h4 className="primary-text">{langObj.more_4_title3}</h4>
                      <FlexWrapper className="more-passengers-container">
                        <InputFiled className="more-passengers-single-section">
                          <h6 className=" m-0">{langObj.enquiry_first_name}</h6>
                          <Input
                            label={langObj.enquiry_first_name}
                            name="first_name"
                            type="text"
                            inputChange={this.stepZeroIpChange}
                            val={morePassengers.first_name}
                            classname="w-100 bg-grey"
                          />
                          {errors.first_name && (
                            <span
                              className={`${
                                morePassengers.first_name ? "d-none" : "error"
                              }`}
                            >
                              {errors.first_name}
                            </span>
                          )}
                        </InputFiled>
                        <InputFiled className="more-passengers-single-section">
                          <h6 className=" m-0">{langObj.enquiry_last_name}</h6>
                          <Input
                            name="last_name"
                            inputChange={this.stepZeroIpChange}
                            val={morePassengers.last_name}
                            label={langObj.enquiry_last_name}
                            type="text"
                            classname="w-100 bg-grey"
                          />
                          {errors.last_name && (
                            <span
                              className={`${
                                morePassengers.last_name.length > 0
                                  ? "d-none"
                                  : "error"
                              }`}
                            >
                              {errors.last_name}
                            </span>
                          )}
                        </InputFiled>
                      </FlexWrapper>
                      <FlexWrapper className="more-passengers-container">
                        <InputFiled className="more-passengers-single-section">
                          <h6 className=" m-0">{langObj.enquiry_email}</h6>
                          <Input
                            inputChange={this.stepZeroIpChange}
                            val={morePassengers.email}
                            name="email"
                            type="email"
                            label={langObj.enquiry_email}
                            classname="w-100 bg-grey"
                          />
                          {errors.email && (
                            <span
                              className={`${
                                morePassengers.email.length > 0
                                  ? "d-none"
                                  : "error"
                              }`}
                            >
                              {errors.email}
                            </span>
                          )}
                          {field_errors.email && (
                            <span className="error mx-2">
                              {field_errors.email}
                            </span>
                          )}
                        </InputFiled>
                        <InputFiled className="more-passengers-single-section">
                          <h6 className=" m-0">{langObj.enquiry_ph_number}</h6>
                          <Input
                            inputChange={this.stepZeroIpChange}
                            val={morePassengers.telephone}
                            name="telephone"
                            type="number"
                            label={langObj.enquiry_ph_number}
                            classname="w-100 bg-grey"
                          />
                          {errors.telephone && (
                            <span
                              className={`${
                                morePassengers.telephone.length > 0
                                  ? "d-none"
                                  : "error"
                              }`}
                            >
                              {errors.telephone}
                            </span>
                          )}
                          {field_errors.telephone && (
                            <span className="error mx-2">
                              {field_errors.telephone}
                            </span>
                          )}
                        </InputFiled>
                      </FlexWrapper>
                      <FlexWrapper>
                        <InputFiled className="w-100">
                          <h6 className=" m-0 mb-2">{langObj.step_4_title4}</h6>
                          <Textarea
                            inputChange={this.stepZeroIpChange}
                            val={morePassengers.remarks}
                            name="remarks"
                            label={langObj.more_4_pass_desp}
                            classname="w-100 m-0 bg-grey"
                          />
                          {errors.remarks && (
                            <span
                              className={`${
                                morePassengers.remarks.length > 0
                                  ? "d-none"
                                  : "error"
                              }`}
                            >
                              {errors.remarks}
                            </span>
                          )}
                        </InputFiled>
                      </FlexWrapper>
                      <ButtonWrapper>
                        {loading ? (
                          <Loader />
                        ) : (
                          <Button
                            submit={this.sendRequest}
                            righticon="fa fa-chevron-right"
                            classname="button-primary"
                            name={langObj.send_this_request}
                          />
                        )}
                      </ButtonWrapper>
                    </HelpUsFrom>
                  </EnquiryContainer>
                ) : (
                  <EnquirySuccsess className="mt-5">
                    <InputFiled className="w-100 ">
                      <h5 className="w-100 ">{langObj.response_title}</h5>
                      <p className="w-100 ">{langObj.response_desp1}</p>
                    </InputFiled>
                    <InputFiled className="w-100 ">
                      <h5 className="w-100 ">{langObj.response_desp2}</h5>
                      <p className="w-100 ">{langObj.response_desp3}</p>
                    </InputFiled>
                    <InputFiled className="w-100 ">
                      <Button
                        submit={this.onCloseModal}
                        name={langObj.close}
                        classname="button-primary bg-danger my-3"
                      />
                    </InputFiled>
                  </EnquirySuccsess>
                )}
              </Modal>
              {/* modal ends */}
            </PackageSections>
            <PackageSections className="travelers-col1">
              <Dropdown
                label={langObj.select}
                options={travelOptions}
                show={showTable}
                val={travelers}
                classname="dropdown-section"
                changeDropdown={this.monthValidation}
              />
            </PackageSections>
            {packageVars && packageVars.traveller_count ? (
              <LinkText
              onClick={() => this.onOpenModal(false)}
              className="underline no-of-travelers c-pointer mobile-more-passengers"
            >
              {langObj.step_0_passengers}
            </LinkText>
            ) : (null)}
          </FlexWrapper>
          {/* selecting no of travelers */}
          <StepZeroStepZeroHR className="triangle-down-hr" />
          <div className="triangle-down"></div>
          {/* Package Table starts */}
          {showTable ? (
            <React.Fragment>
              <FlexWrapper className="departure-date mb-0">
                <StepZeroH4>{langObj.step_0_d_date}</StepZeroH4>
                <LinkText className="primary-text">
                  *{langObj.step_0_note1}
                </LinkText>
              </FlexWrapper>
              <FlexWrapper className="margin-y traveleres-section mt-0">
                <PackageSections className="travelers-col1">
                  <Dropdown
                    label={langObj.select}
                    options={monOptions}
                    val={selectedMonth}
                    // show={showMonth}
                    changeDropdown={this.selectDepatureDate}
                    classname="dropdown-section"
                  />
                </PackageSections>
                <DateSection className="travelers-col travelers-dates margin-425">
                  <StepZeroStepZeroFloatingContainer>
                    <Icon
                      className={
                        this.state.offset === 0
                          ? "grey-text margin-r"
                          : "c-pointer margin-r"
                      }
                      onClick={this.state.offset === 0 ? "" : this.prevData}
                      src={this.state.offset === 0 ? LeftIcon : EarlierIcon}
                      alt="Earlier dates foto"
                    />
                    <StepZeroHighlightText
                      className={
                        this.state.offset === 0 ? "grey-text " : "c-pointer"
                      }
                      onClick={this.state.offset === 0 ? "" : this.prevData}
                    >
                      {langObj.earlier_dates}
                    </StepZeroHighlightText>
                  </StepZeroStepZeroFloatingContainer>
                  <StepZeroStepZeroFloatingContainer>
                    <StepZeroHighlightText
                      className={
                        this.state.offset + this.state.perPage >
                        this.state.sampleArr.length
                          ? "grey-text"
                          : "c-pointer"
                      }
                      onClick={
                        this.state.offset + this.state.perPage >
                        this.state.sampleArr.length
                          ? ""
                          : this.nextData
                      }
                    >
                      {langObj.later_dates}
                    </StepZeroHighlightText>
                    <Icon
                      className={
                        this.state.offset + this.state.perPage >
                        this.state.sampleArr.length
                          ? "grey-text margin-l"
                          : "c-pointer margin-l"
                      }
                      src={this.state.offset === 0 ? RightIcon : LaterIcon}
                      onClick={
                        this.state.offset + this.state.perPage >
                        this.state.sampleArr.length
                          ? ""
                          : this.nextData
                      }
                      alt="Later dates foto"
                    />
                  </StepZeroStepZeroFloatingContainer>
                </DateSection>
              </FlexWrapper>
              <TableWrapper>
                <Table
                  datas={rowDatas}
                  row={rows}
                  col={col}
                  view="package_detail"
                  tableCell={index}
                  tableCellData={data}
                  choosePrice={this.selectPrice}
                />
              </TableWrapper>
              {packageCommonDetails.change_date ? (
                <FlexWrapper className="another-date">
                  <LinkText
                    className="c-pointer"
                    onClick={() => this.onOpenModal(true)}
                  >
                    {langObj.step_0_alter_day}
                  </LinkText>
                </FlexWrapper>
              ) : (
                ""
              )}
              <StepZeroStepZeroHR className="triangle-down-hr" />
              <div className="triangle-down"></div>
            </React.Fragment>
          ) : (
            ""
          )}
          {/* Package Table ends */}
          {/* summary section starts*/}
          {showSummary ? (
            <React.Fragment>
              {/* <HR /> */}
              <SummaryContainer>
                <StepZeroH4>{langObj.step_0_title}</StepZeroH4>
                <SummaryPackageDetail className="summary=package-detail">
                  <div className="w-30  w-auto-768">
                    <StepZeroStepZeroHighlightText className="text-capitalize font-weight-bold">
                      {packageObj.package_name}
                    </StepZeroStepZeroHighlightText>
                  </div>
                  <div className="w-40 page-border w-auto-768 page-border-right-0 page-border-left-0">
                    <StepZeroStepZeroHighlightText>
                      {packageObj.qty_number[0]}{" "}
                      {packageObj.qty_number[0] > 1
                        ? langObj.adults
                        : langObj.adult}
                      {packageObj.qty_number.length > 1
                        ? `, ${packageObj.qty_number[1]} ${
                            packageObj.qty_number[1] > 1
                              ? langObj.children
                              : langObj.child
                          }`
                        : ""}
                    </StepZeroStepZeroHighlightText>
                  </div>
                  <div className="w-40 page-border w-auto-768 page-border-right-0">
                    <StepZeroStepZeroHighlightText>
                      {packageObj.total_qty}x{" "}
                      <span className="font-sm mx-1"> € </span>{" "}
                      {packageObj.amount}
                    </StepZeroStepZeroHighlightText>
                  </div>
                </SummaryPackageDetail>
                <SummaryPackageDetail className="summary=package-detail">
                  <div className="w-30 w-auto-768">
                    <StepZeroStepZeroHighlightText className="text-capitalize">
                      {langObj.departure}:{" "}
                      {moment(new Date(packageObj.start_date))
                        .format(dateFormat)
                        .replace(/ste|de/, "")}
                    </StepZeroStepZeroHighlightText>
                  </div>
                  <div className="w-40 page-border w-auto-768 page-border-right-0 page-border-left-0">
                    <StepZeroStepZeroHighlightText>
                      {packageObj.duration_number[0]} {langObj.days} -{" "}
                      {packageObj.duration_number[1]} {langObj.nights}
                    </StepZeroStepZeroHighlightText>
                  </div>
                  <div className="w-40 page-border  w-auto-768 page-border-right-0">
                    <StepZeroStepZeroHighlightText className="text-capitalize ">
                      {langObj.return}:{" "}
                      {moment(new Date(packageObj.end_date))
                        .format(dateFormat)
                        .replace(/ste|de/, "")}
                    </StepZeroStepZeroHighlightText>
                  </div>
                </SummaryPackageDetail>
              </SummaryContainer>
              <StepZeroStepZeroHR />
              <SummaryContainer>
                <FlexWrapper className="summary-btn-wrapper">
                  <div>
                    <h4 className="font-weight-bold">
                      {langObj.step_0_price}{" "}
                      <span className="font-sm mx-1"> € </span>{" "}
                      {packageObj.final_price}
                    </h4>
                    <LinkText>{langObj.step_0_note2}</LinkText>
                  </div>
                  <Button
                    submit={this.forwardToStepOne}
                    righticon="fa fa-chevron-right"
                    classname="button-primary h-100  step-0-continue"
                    name={langObj.continue}
                  />
                </FlexWrapper>
              </SummaryContainer>
            </React.Fragment>
          ) : (
            ""
          )}
          {/* summary section ends*/}
        </StepZeroContainer>
        <HelpSection />
      </PageWrapper>
    ) : // step 0 ends
    // step one starts
    paymentLoading ? (
      <LoadingWrapper>
        <div className="text-center">
          <Loader />
          <h6 className="loader-bold-text">Just a second</h6>
          <p className="loader-text">{langObj.loading_your_request}</p>
        </div>
      </LoadingWrapper>
    ) : (
      <React.Fragment>
        <FormSectionWrapper>
          <FormSection>
            <FormWrapper>
              <H4>{`${langObj.step}  ${pageNum} ${langObj.of} 4`}</H4>
              <StepsContainer>
                <Bar className={pageNum >= 1 ? "selected-bar" : ""} />
                <Bar className={pageNum >= 2 ? "selected-bar" : ""} />
                <Bar className={pageNum >= 3 ? "selected-bar" : ""} />
                <Bar className={pageNum >= 4 ? "selected-bar" : ""} />
              </StepsContainer>
              {/* step 1 form starts */}
              {pageNum === 1 ? (
                <React.Fragment>
                  <InputFieldsSection>
                    <H3>{langObj.step_1_head1}</H3>
                    <P>{langObj.step_1_description}</P>
                    <FlexWrapper className="step-one-flexwrapper">
                      <InputContainer className="step-one-input-container">
                        <Label>{langObj.step_1_first_name}</Label>
                        <Input
                          label={langObj.step_1_first_name}
                          name="first_name"
                          type="text"
                          inputChange={this.onInputChange}
                          val={stepOneInputs.first_name}
                        />
                        {errOne.first_name && (
                          <span
                            className={`${
                              stepOneInputs.first_name ? "d-none" : "error"
                            }`}
                          >
                            {errOne.first_name}
                          </span>
                        )}
                      </InputContainer>
                      <InputContainer className="step-one-input-container">
                        <Label>{langObj.step_1_last_name}</Label>
                        <Input
                          label={langObj.step_1_last_name}
                          name="last_name"
                          type="text"
                          inputChange={this.onInputChange}
                          val={stepOneInputs.last_name}
                        />
                        {errOne.last_name && (
                          <span
                            className={`${
                              stepOneInputs.last_name ? "d-none" : "error"
                            }`}
                          >
                            {errOne.last_name}
                          </span>
                        )}
                      </InputContainer>
                    </FlexWrapper>
                    <FlexWrapper className="step-one-flexwrapper">
                      <InputContainer className="step-one-input-container">
                        <Label>{langObj.step_1_email}</Label>
                        <Input
                          label="name@domain.com"
                          name="email"
                          type="email"
                          inputChange={this.onInputChange}
                          val={stepOneInputs.email}
                        />
                        <div>
                          {errOne.email && (
                            <span
                              className={`${
                                stepOneInputs.email || fieldErrOne.email
                                  ? "d-none"
                                  : "error mr-2"
                              }`}
                            >
                              {errOne.email}
                            </span>
                          )}
                          {fieldErrOne.email && (
                            <span className="error">{fieldErrOne.email}</span>
                          )}
                        </div>
                      </InputContainer>
                      <InputContainer className="step-one-input-container">
                        <Label>{langObj.step_1_confirm_email}</Label>
                        <Input
                          label="name@domain.com"
                          name="confirm_email"
                          type="email"
                          inputChange={this.onInputChange}
                          val={stepOneInputs.confirm_email}
                        />
                        <div>
                          {errOne.confirm_email && (
                            <span
                              className={`${
                                stepOneInputs.confirm_email ||
                                fieldErrOne.confirm_email
                                  ? "d-none"
                                  : "error mr-2"
                              }`}
                            >
                              {errOne.confirm_email}
                            </span>
                          )}
                          {fieldErrOne.confirm_email && (
                            <span className="error">
                              {fieldErrOne.confirm_email}
                            </span>
                          )}
                        </div>
                      </InputContainer>
                    </FlexWrapper>
                    <FlexWrapper className=" step-one-flexwrapper">
                      <InputContainer className="step-one-input-container">
                        <Label>{langObj.step_1_ph_num}</Label>
                        <Input
                          name="telephone_number"
                          type="text"
                          inputChange={this.onInputChange}
                          val={stepOneInputs.telephone_number}
                          label="+32(0)499 999 999"
                        />
                        {errOne.telephone_number && (
                          <span
                            className={`${
                              stepOneInputs.telephone_number
                                ? "d-none"
                                : "error"
                            }`}
                          >
                            {errOne.telephone_number}
                          </span>
                        )}
                        {fieldErrOne.telephone_number && (
                          <span className="error mx-2">
                            {fieldErrOne.telephone_number}
                          </span>
                        )}
                      </InputContainer>
                      {/* <div className='w-47'> */}
                      <div className="step-one-input-container w-47">
                        <InputContainer className="checkbox-wrapper mbtm-10 w-100">
                          <Checkbox
                            val="contact_status"
                            name="contact_status"
                            labelCls="mb-0"
                            check={stepOneInputs.contact_status}
                            // name={this.props.name}
                            change={this.checkChange}
                          />
                          <Label className="margin-x ">
                            {langObj.step_1_allow_contact}
                          </Label>
                        </InputContainer>
                        {errOne.contact_status && (
                          <span
                            className={`${
                              stepOneInputs.contact_status ? "d-none" : "error"
                            }`}
                          >
                            {errOne.contact_status}
                          </span>
                        )}
                      </div>
                      {/* {stepOneInputs.contact_status ? <div className='d-grid'>
                                                    <Label>Cotact number</Label>
                                                    <Input
                                                        name='contact_number'
                                                        type='number'
                                                        inputChange={this.onInputChange}
                                                        val={stepOneInputs.contact_number}
                                                        label="Number"
                                                    />
                                                </div> : ''} */}
                      {/* </div> */}
                    </FlexWrapper>
                  </InputFieldsSection>
                  <InputFieldsSection>
                    <H3>{langObj.step_1_head2}</H3>
                    <FlexWrapper className="step-one-flexwrapper">
                      <InputContainer className="street-field step-one-input-container">
                        <Label>{langObj.step_1_street}</Label>
                        <Input
                          name="street"
                          type="text"
                          inputChange={this.onInputChange}
                          val={stepOneInputs.street}
                          label={langObj.step_1_street}
                        />
                        {errOne.street && (
                          <span
                            className={`${
                              stepOneInputs.street ? "d-none" : "error"
                            }`}
                          >
                            {errOne.street}
                          </span>
                        )}
                      </InputContainer>
                      <InputContainer className="housenum-field step-one-input-container">
                        <Label>{langObj.step_1_h_number}</Label>
                        <Input
                          name="house_number"
                          type="text"
                          inputChange={this.onInputChange}
                          val={stepOneInputs.house_number}
                          label={langObj.step_1_h_number}
                        />
                        {errOne.house_number && (
                          <span
                            className={`${
                              stepOneInputs.house_number ? "d-none" : "error"
                            }`}
                          >
                            {errOne.house_number}
                          </span>
                        )}
                      </InputContainer>
                    </FlexWrapper>
                    <FlexWrapper className="step-one-flexwrapper">
                      <InputContainer className="housenum-field step-one-input-container">
                        <Label>{langObj.step_1_p_code}</Label>
                        <Input
                          name="postal_code"
                          type="number"
                          inputChange={this.onInputChange}
                          val={stepOneInputs.postal_code}
                          label="9999"
                        />
                        {errOne.postal_code && (
                          <span
                            className={`${
                              stepOneInputs.postal_code ? "d-none" : "error"
                            }`}
                          >
                            {errOne.postal_code}
                          </span>
                        )}
                      </InputContainer>
                      <InputContainer className="city-field step-one-input-container">
                        <Label>{langObj.step_1_city}</Label>
                        <Input
                          name="city"
                          type="text"
                          inputChange={this.onInputChange}
                          val={stepOneInputs.city}
                          label={langObj.step_1_city}
                        />
                        {errOne.city && (
                          <span
                            className={`${
                              stepOneInputs.city ? "d-none" : "error"
                            }`}
                          >
                            {errOne.city}
                          </span>
                        )}
                      </InputContainer>
                      <InputContainer className="country-field step-one-input-container">
                        <Label>{langObj.step_1_country}</Label>
                        <Dropdown
                          label="Country"
                          options={this.state.countryOptions}
                          show={showCountry}
                          val={stepOneInputs.country}
                          changeDropdown={this.selectCountry}
                          classname="dropdown-section"
                        />
                        {errOne.country && (
                          <span
                            className={`${
                              stepOneInputs.country ? "d-none" : "error"
                            }`}
                          >
                            {errOne.country}
                          </span>
                        )}
                      </InputContainer>
                    </FlexWrapper>
                    <JustifyFlex className="space-between step-one-invoice">
                      {!invoice ? (
                        <Label
                          onClick={this.changeInvoice}
                          className="underline c-pointer first-letter-cap"
                        >
                          {langObj.step_1_invoice}
                        </Label>
                      ) : (
                        <React.Fragment>
                          <Label className="primary-text first-letter-cap">
                            {langObj.step_1_company_desp}
                          </Label>
                          <Label
                            onClick={this.changeInvoice}
                            className="underline c-pointer first-letter-cap"
                          >
                            {langObj.step_1_no_invoice}{" "}
                            <span classsName="mx-1">X</span>
                          </Label>
                        </React.Fragment>
                      )}
                    </JustifyFlex>
                    {invoice ? (
                      <FlexWrapper className="step-one-flexwrapper">
                        <InputContainer className="step-one-input-container">
                          <Label>{langObj.step_1_company_name}</Label>
                          <Input
                            name="company_name"
                            type="text"
                            inputChange={this.onInputChange}
                            val={stepOneInputs.company_name}
                            label={langObj.step_1_company_name}
                          />
                          {errOne.company_name && (
                            <span
                              className={`${
                                stepOneInputs.company_name ? "d-none" : "error"
                              }`}
                            >
                              {errOne.company_name}
                            </span>
                          )}
                        </InputContainer>
                        <InputContainer className="step-one-input-container">
                          <Label>{langObj.step_1_vat_number}</Label>
                          <Input
                            name="vat_number"
                            type="text"
                            classname=""
                            inputChange={this.onInputChange}
                            val={stepOneInputs.vat_number}
                            label={langObj.step_1_vat_number}
                          />
                          {errOne.vat_number && (
                            <span
                              className={`${
                                stepOneInputs.vat_number ? "d-none" : "error"
                              }`}
                            >
                              {errOne.vat_number}
                            </span>
                          )}
                        </InputContainer>
                      </FlexWrapper>
                    ) : (
                      ""
                    )}
                  </InputFieldsSection>
                </React.Fragment>
              ) : (
                ""
              )}
              {/* Step-1 form ends */}

              {/* step 2 form starts */}
              {pageNum === 2 ? (
                <InputFieldsSection>
                  {/* passenger  information modal */}
                  <Modal
                    classNames={{
                      overlay: "modal-passengers-overlay",
                      modal: "modal-passengers-container",
                      closeButton: "modal-close-btn",
                    }}
                    open={this.state.openPsgInf}
                    onClose={this.closePsgInf}
                    center
                  >
                    <EnquiryContainer>
                      <div>
                        <FlexPackageIcon>
                          <PackageIcon
                            src={TravelIcon}
                            width="25px"
                            alt="package imag"
                          />
                          <h3 className="primary-text text-bold first-letter-cap">
                            {langObj.step_2_pr_name}
                          </h3>
                        </FlexPackageIcon>
                        <InputFieldsSection>
                          <InputFieldsSection>
                            {/* <h6 className='font-weight-bold'>Title</h6> */}
                            <p className="text-grey font-sm">
                              <Linkify
                                properties={{ target: "_blank" }}
                                componentDecorator={componentDecorator}
                              >
                                {langObj.passenger_name_mesage}
                              </Linkify>
                            </p>
                          </InputFieldsSection>
                          {/* <InputFieldsSection>
                                                        <h6 className='font-weight-bold'>Title</h6>
                                                        <p className='text-grey font-sm'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                                    </InputFieldsSection>
                                                    <InputFieldsSection>
                                                        <h6 className='font-weight-bold'>Title</h6>
                                                        <p className='text-grey font-sm'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                                    </InputFieldsSection> */}
                        </InputFieldsSection>
                        <InputFiled className="w-100 ">
                          <Button
                            submit={this.closePsgInf}
                            name={langObj.close}
                            classname="button-close my-3"
                          />
                        </InputFiled>
                      </div>
                    </EnquiryContainer>
                  </Modal>

                  {/* passenger information modal ends */}
                  <H3>{langObj.step_2_head1}</H3>
                  <P>{langObj.step_2_description}</P>
                  <div className="infrm-name mt-4">
                    <div
                      onClick={this.openPsgInf}
                      className="upgraded-card-text p-info-msg c-pointer d-flex align-center"
                    >
                      <img
                        className="mx-2"
                        src={ImportantIcon}
                        alt="imporant-logo"
                      />
                      <p className="m-0">{langObj.step_2_pr_name}</p>
                    </div>
                  </div>
                  {errTwo.length > 0 ? (
                    <span className="error font-md my-2">{errTwo}</span>
                  ) : (
                    ""
                  )}
                  {adult.map((x, i) => {
                    return (
                      <InputFieldsSection>
                        <FloatingHeader>
                          <H5 className="first-letter-cap">
                            {langObj.adult} {i + 1}
                          </H5>
                          <HR />
                        </FloatingHeader>
                        <FlexWrapper className="step-one-flexwrapper">
                          <InputContainer className="form-step2-gender-field step-one-input-container">
                            <Label>{langObj.step_2_gender}</Label>
                            <Dropdown
                              label={langObj.select}
                              options={genderOptions}
                              // show={adult[i] ? true : false}
                              val={adult[i] ? adult[i].gender : ""}
                              changeDropdown={(e) =>
                                this.selectGender(
                                  i,
                                  e,
                                  "adult",
                                  "gender",
                                  "adultGender"
                                )
                              }
                              classname="dropdown-section"
                            />
                          </InputContainer>
                        </FlexWrapper>
                        <FlexWrapper className="step-one-flexwrapper">
                          <InputContainer className="step-one-input-container">
                            <Label>{langObj.step_2_first_name}</Label>
                            <Input
                              name="first_name"
                              type="text"
                              inputChange={(e) =>
                                this.inputChange(e, i, "adult")
                              }
                              val={adult[i] ? adult[i].first_name : ""}
                              label={langObj.step_2_first_name}
                            />
                          </InputContainer>
                          <InputContainer className="step-one-input-container">
                            <Label>{langObj.step_2_last_name}</Label>
                            <Input
                              name="last_name"
                              type="text"
                              inputChange={(e) =>
                                this.inputChange(e, i, "adult")
                              }
                              val={adult[i] ? adult[i].last_name : ""}
                              label={langObj.step_2_last_name}
                            />
                          </InputContainer>
                        </FlexWrapper>
                        <JustifyFlex className="step-one-flexwrapper">
                          <InputContainer className="date-month-field step-one-input-container">
                            <Label>{langObj.step_2_dob}</Label>
                            <Dropdown
                              label="DD"
                              options={dates}
                              // show={adult[i]?true : false}
                              val={adult[i] ? adult[i].day : ""}
                              changeDropdown={(e) =>
                                this.selectGender(
                                  i,
                                  e,
                                  "adult",
                                  "day",
                                  "adultDay"
                                )
                              }
                              classname="dropdown-section"
                            />
                          </InputContainer>
                          <InputContainer className="date-month-field step-one-input-container">
                            <Label className="invisible">Date of birth</Label>
                            <Dropdown
                              label="MM"
                              options={monthArray}
                              // show={adult[i]?true : false}/
                              val={adult[i] ? adult[i].month : ""}
                              changeDropdown={(e) =>
                                this.selectGender(
                                  i,
                                  e,
                                  "adult",
                                  "month",
                                  "adultMonth"
                                )
                              }
                              classname="dropdown-section"
                            />
                          </InputContainer>
                          <InputContainer className="date-month-field step-one-input-container">
                            <Label className="invisible">Date of birth</Label>
                            <Dropdown
                              label="YYYY"
                              options={years}
                              // show={adult[i]? true : false}
                              val={adult[i] ? adult[i].year : ""}
                              changeDropdown={(e) =>
                                this.selectGender(
                                  i,
                                  e,
                                  "adult",
                                  "year",
                                  "adultYear"
                                )
                              }
                              classname="dropdown-section"
                            />
                          </InputContainer>
                        </JustifyFlex>
                      </InputFieldsSection>
                    );
                  })}
                  {child.map((x, i) => {
                    return (
                      <InputFieldsSection>
                        <FloatingHeader>
                          <H5 className="first-letter-cap">
                            {langObj.child} {i + 1}
                          </H5>
                          <HR />
                        </FloatingHeader>
                        <FlexWrapper>
                          <InputContainer className="form-step2-gender-field">
                            <Label>{langObj.step_2_gender}</Label>
                            <Dropdown
                              label={langObj.select}
                              options={genderOptions}
                              val={child[i] ? child[i].gender : ""}
                              show={child[i] ? true : false}
                              changeDropdown={(e) =>
                                this.selectGender(
                                  i,
                                  e,
                                  "child",
                                  "gender",
                                  "childGender"
                                )
                              }
                              classname="dropdown-section"
                            />
                          </InputContainer>
                        </FlexWrapper>
                        <FlexWrapper className="step-one-flexwrapper">
                          <InputContainer className="step-one-input-container">
                            <Label>{langObj.step_2_first_name}</Label>
                            <Input
                              name="first_name"
                              type="text"
                              inputChange={(e) =>
                                this.inputChange(e, i, "child")
                              }
                              val={child[i] ? child[i].first_name : ""}
                              label={langObj.step_2_first_name}
                            />
                          </InputContainer>
                          <InputContainer className="step-one-input-container">
                            <Label>{langObj.step_2_last_name}</Label>
                            <Input
                              name="last_name"
                              type="text"
                              inputChange={(e) =>
                                this.inputChange(e, i, "child")
                              }
                              val={child[i] ? child[i].last_name : ""}
                              label={langObj.step_2_last_name}
                            />
                          </InputContainer>
                        </FlexWrapper>
                        <JustifyFlex className="step-one-flexwrapper">
                          <InputContainer className="date-month-field step-one-input-container">
                            <Label>{langObj.step_2_dob}</Label>
                            <Dropdown
                              label="DD"
                              options={dates}
                              val={child[i] ? child[i].day : ""}
                              // show={child[i] ? true : false}
                              changeDropdown={(e) =>
                                this.selectGender(
                                  i,
                                  e,
                                  "child",
                                  "day",
                                  "childDay"
                                )
                              }
                              classname="dropdown-section"
                            />
                          </InputContainer>
                          <InputContainer className="date-month-field step-one-input-container">
                            <Label className="invisible">Date of birth</Label>
                            <Dropdown
                              label="MM"
                              options={monthArray}
                              val={child[i] ? child[i].month : ""}
                              // show={child[i] ? true : false}
                              changeDropdown={(e) => {
                                console.log(e, i, "monthhhhhhhhhhhhhhhhh");
                                this.selectGender(
                                  i,
                                  e,
                                  "child",
                                  "month",
                                  "childMonth"
                                );
                              }}
                              classname="dropdown-section"
                            />
                          </InputContainer>
                          <InputContainer className="date-month-field step-one-input-container">
                            <Label className="invisible">Date of birth</Label>
                            <Dropdown
                              label="YYYY"
                              options={years}
                              val={child[i] ? child[i].year : ""}
                              // show={child[i] ? true : false}
                              changeDropdown={(e) => {
                                this.selectGender(
                                  i,
                                  e,
                                  "child",
                                  "year",
                                  "childMonth"
                                );
                              }}
                              classname="dropdown-section"
                            />
                          </InputContainer>
                        </JustifyFlex>
                      </InputFieldsSection>
                    );
                  })}
                </InputFieldsSection>
              ) : (
                ""
              )}
              {/* step 2 form ends */}
              {/* step3 form starts */}
              {pageNum === 3 ? (
                <InputFieldsSection>
                  {"upgrade" in extrasPackage && extrasPackage.upgrade ? (
                    <React.Fragment>
                      <H3>{langObj.step_3_title}</H3>
                      <P>{langObj.step_3_description}</P>
                    </React.Fragment>
                  ) : (
                    <H3 className="first-letter-cap">{langObj.no_upgrade}</H3>
                  )}
                  <FourthStepSection>
                    {"hotel" in extrasPackage &&
                    extrasPackage.hotel.length > 0 ? (
                      <React.Fragment>
                        {extrasPackage.hotel.length > 1 ? (
                          <FourthStepSubHeader>
                            {langObj.step_3_upgrade}
                          </FourthStepSubHeader>
                        ) : (
                          ""
                        )}
                        {extrasPackage.hotel.length > 1 ? (
                          <FourthStepHeader>
                            {langObj.step_3_title1}
                          </FourthStepHeader>
                        ) : (
                          ""
                        )}
                        {extrasPackage.hotel.map((x, i) => {
                          return (
                            <UpgradeCard
                              name={x.name + i}
                              check={x.checked ? true : false}
                              disable={x.checked ? true : false}
                              change={(e) =>
                                this.upgradesPackageChange(
                                  e,
                                  "hotel",
                                  x,
                                  i,
                                  x.price
                                )
                              }
                              // text={!x.key ? '{langObj.step_3_avail}' : `+ € ${x.price}/pp`}
                              heading={x.name}
                              content={x.description}
                              img={"image" in x ? x.image : ""}
                            >
                              {x.key ? (
                                <P className="included-text">
                                  <span classsName="font-12">+ </span>
                                  <span className="font-small mx-1">
                                    {" "}
                                    €{" "}
                                  </span>{" "}
                                  {x.price} /{" "}
                                  <span className="font-14">
                                    {langObj.per_person}
                                  </span>
                                </P>
                              ) : (
                                <P className="included-text">
                                  {langObj.step_3_avail}
                                </P>
                              )}
                            </UpgradeCard>
                          );
                        })}
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </FourthStepSection>
                  {"airline" in extrasPackage &&
                  extrasPackage.airline.length > 2 ? (
                    <FourthStepSection>
                      {extrasPackage.airline.length > 2 ? (
                        <FourthStepHeader>
                          {langObj.step_3_title2}
                        </FourthStepHeader>
                      ) : (
                        ""
                      )}
                      <Airplane data={extrasPackage.airline[0]}>
                        {extrasPackage.airline.map((x, i) => {
                          return x.key === 1 || x.key === 0 ? (
                            <InformationSection bg={x.checked}>
                              <CheckboxWrapper>
                                <div className="checkbox-group">
                                  <input
                                    type="checkbox"
                                    onChange={(e) =>
                                      this.upgradesPackageChange(
                                        e,
                                        "airline",
                                        x,
                                        i,
                                        x.price
                                      )
                                    }
                                    // value={this.props.val}
                                    disable={x.checked ? true : false}
                                    name={x.name + i}
                                    checked={x.checked ? true : false}
                                    id={x.name + i}
                                  />
                                  <label
                                    id="check-box-id"
                                    htmlFor={x.name + i}
                                  ></label>
                                </div>
                                {/* <Checkbox 
                           name={x.name+i}
                           check={x.checked?true:false}
                          disable={x.key?false:true}
                          change={(e)=>this.extrasPackageChange(e,'airline',x,i,x.price)}
                           /> */}
                                <DetailSection>
                                  <H3Text>{x.name}</H3Text>
                                  <p className="upgrade-card-text word-break">
                                    {x.description}
                                  </p>
                                </DetailSection>
                              </CheckboxWrapper>
                              {x.key ? (
                                <P className="included-text">
                                  <span classsName="font-12">+ </span>
                                  <span className="font-small mx-1">
                                    {" "}
                                    €{" "}
                                  </span>{" "}
                                  {x.price} /{" "}
                                  <span className="font-14">
                                    {langObj.per_person}
                                  </span>
                                </P>
                              ) : (
                                <P className="included-text">
                                  {langObj.step_3_avail}
                                </P>
                              )}
                            </InformationSection>
                          ) : (
                            ""
                          );
                        })}
                      </Airplane>
                    </FourthStepSection>
                  ) : (
                    ""
                  )}
                  {"airline" in extrasPackage &&
                  extrasPackage.airline.length > 2 ? (
                    <Separator />
                  ) : (
                    ""
                  )}
                  <FourthStepSection>
                    {"excursion" in extrasPackage &&
                    extrasPackage.excursion.length > 0 ? (
                      <React.Fragment>
                        <FourthStepSubHeader>
                          {langObj.component_extra}
                        </FourthStepSubHeader>
                        <FourthStepHeader>
                          {langObj.step_3_title6}
                        </FourthStepHeader>
                        {extrasPackage.excursion.map((x, i) => {
                          console.log("x.days", x.days);
                          const days = x.days
                            ? Object.keys(x.days).map((item, i) => {
                                return {
                                  name: item.slice(0, 2),
                                  val: x.days[item],
                                };
                              })
                            : [];
                          return (
                            <ExcursionCard
                              name={x.name + i}
                              check={x.checked ? true : false}
                              disable={x.key ? false : true}
                              change={(e) =>
                                this.extrasPackageChange(
                                  e,
                                  "excursion",
                                  x,
                                  i,
                                  parseInt(x.adult_price) +
                                    parseInt(x.child_price)
                                )
                              }
                              img={"image" in x ? x.image : ""}
                              heading={x.name}
                              days={days}
                              content={x.description}
                              // text={!x.key ? '{langObj.step_3_avail}' : `+ € ${x.adult_price} /adult ${child.length > 0 ? `| + € ${x.child_price} /child` : ''}`}
                            >
                              {x.key ? (
                                <P className="included-text">
                                  <span classsName="font-12">+</span>
                                  <span className="small mx-1"> € </span>
                                  {x.adult_price} / {langObj.adult}{" "}
                                  {child.length > 0 ? (
                                    <span>
                                      <span className="small mx-1"> € </span>
                                      {x.child_price
                                        ? x.child_price
                                        : x.adult_price}{" "}
                                      / {langObj.child}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </P>
                              ) : (
                                <P className="included-text">
                                  {langObj.step_3_avail}
                                </P>
                              )}
                            </ExcursionCard>
                          );
                        })}
                        <Separator />
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </FourthStepSection>

                  <FourthStepSection>
                    {"tour" in extrasPackage &&
                    extrasPackage.tour.length > 0 ? (
                      <React.Fragment>
                        <FourthStepSubHeader>
                          {langObj.component_3}
                        </FourthStepSubHeader>
                        <FourthStepHeader>
                          {langObj.step_3_title7}
                        </FourthStepHeader>
                        {extrasPackage.tour.map((x, i) => {
                          return (
                            <TourExcursionCard
                              name={x.name + i}
                              duration={x.duration}
                              check={x.checked ? true : false}
                              disable={x.key ? false : true}
                              change={(e) =>
                                this.upgradesExtrasChange(
                                  e,
                                  "tour",
                                  x,
                                  i,
                                  x.from_price
                                )
                              }
                              img={"image" in x ? x.image : ""}
                              heading={x.name}
                              content={x.description}
                              // text={!x.key ? '{langObj.step_3_avail}' : `+ € ${x.from_price} /pp`}
                            >
                              {x.key ? (
                                <P className="included-text">
                                  <span classsName="font-12">+ </span>
                                  <span className="small mx-1"> € </span>{" "}
                                  {x.from_price} /{" "}
                                  <span className="font-14">
                                    {langObj.per_person}
                                  </span>
                                </P>
                              ) : (
                                <P className="included-text">
                                  {langObj.step_3_avail}
                                </P>
                              )}
                            </TourExcursionCard>
                          );
                        })}
                        <Label>{langObj.step_3_note}</Label>
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </FourthStepSection>
                  <FourthStepSection>
                    {extrasPackage.transfer.length > 0 ||
                    extrasPackage.car_rental.length > 0 ||
                    extrasPackage.added_value.length > 0 ? (
                      <FourthStepSubHeader>
                        {langObj.step_3_extra}
                      </FourthStepSubHeader>
                    ) : (
                      ""
                    )}
                    {"transfer" in extrasPackage &&
                    extrasPackage.transfer.length > 0 ? (
                      <React.Fragment>
                        <FourthStepHeader>
                          {langObj.step_3_title3}
                        </FourthStepHeader>
                        {extrasPackage.transfer.map((x, i) => {
                          if (x.price) {
                            return (
                              <UpgradeCard
                                name={x.name + i}
                                check={x.checked ? true : false}
                                // disable={x.key && x.checked ? false : true}
                                change={(e) =>
                                  this.packChange(e, "transfer", x, i, x.price)
                                }
                                // text={!x.key ? '{langObj.step_3_avail}' : `+ € ${x.price}/pp`}
                                heading={x.name}
                                content={x.description}
                                img={"image" in x ? x.image : ""}
                              >
                                {x.key ? (
                                  <P className="included-text">
                                    <span classsName="font-12">+ </span>
                                    <span className="small mx-1"> € </span>{" "}
                                    {x.price} /{" "}
                                    <span className="font-14">
                                      {langObj.per_person}
                                    </span>
                                  </P>
                                ) : (
                                  <P className="included-text">
                                    {langObj.step_3_avail}
                                  </P>
                                )}
                              </UpgradeCard>
                            );
                          }
                        })}
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </FourthStepSection>
                  <FourthStepSection>
                    {"car_rental" in extrasPackage &&
                    extrasPackage.car_rental.length > 0 ? (
                      <React.Fragment>
                        <FourthStepHeader>
                          {langObj.step_3_title4}
                        </FourthStepHeader>
                        {extrasPackage.car_rental.map((x, i) => {
                          return (
                            <UpgradeCard
                              name={x.name + i}
                              check={x.checked ? true : false}
                              // disable={x.key && !x.checked? false : true}
                              change={(e) =>
                                this.packChange(e, "car_rental", x, i, x.price)
                              }
                              // text={!x.key ? '{langObj.step_3_avail}' : `+ €  ${x.price} /pd`}
                              heading={x.name}
                              content={x.description}
                              img={"image" in x ? x.image : ""}
                            >
                              {x.key ? (
                                <P className="included-text">
                                  <span classsName="font-12">+ </span>
                                  <span className="small mx-1"> € </span>{" "}
                                  {x.price} /{" "}
                                  <span className="font-14">
                                    {langObj.per_day}
                                  </span>
                                </P>
                              ) : (
                                <P className="included-text">
                                  {langObj.step_3_avail}
                                </P>
                              )}
                            </UpgradeCard>
                          );
                        })}
                        <Label>{langObj.step_3_note}</Label>
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </FourthStepSection>
                  <FourthStepSection>
                    {"added_value" in extrasPackage &&
                    extrasPackage.added_value.length > 0 ? (
                      <React.Fragment>
                        <FourthStepHeader>
                          {langObj.step_3_title5}
                        </FourthStepHeader>
                        {extrasPackage.added_value.map((x, i) => {
                          return (
                            <UpgradeCard
                              name={x.name + i}
                              check={x.checked ? true : false}
                              disable={x.key ? false : false}
                              // change={this.airlineCheck}
                              change={(e) =>
                                this.extrasPackageChange(
                                  e,
                                  "added_value",
                                  x,
                                  i,
                                  x.fixed_price
                                )
                              }
                              // text={!x.key ? '{langObj.step_3_avail}' : `+ €  ${x.fixed_price}`}
                              heading={x.name}
                              content={x.description}
                              img={"image" in x ? x.image : ""}
                            >
                              {x.key ? (
                                <P className="included-text">
                                  <span classsName="font-12">+ </span>
                                  <span className="small mx-1"> € </span>{" "}
                                  {x.fixed_price}{" "}
                                </P>
                              ) : (
                                <P className="included-text">
                                  {langObj.step_3_avail}
                                </P>
                              )}
                            </UpgradeCard>
                          );
                        })}
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </FourthStepSection>
                  {extrasPackage.transfer.length > 0 ||
                  extrasPackage.car_rental.length > 0 ||
                  extrasPackage.added_value.length > 0 ? (
                    <Separator />
                  ) : (
                    ""
                  )}
                </InputFieldsSection>
              ) : (
                ""
              )}
              {/* step3 form ends */}
              {/* step 4 starts */}
              {pageNum === 4 ? (
                <InputFieldsSection>
                  <H3>{langObj.step_4_title}</H3>
                  <P>{langObj.step_4_description}</P>
                  {priceCalcObj.insurance_amount[0].cancellation_insurance ===
                    "0.00" &&
                  priceCalcObj.insurance_amount[0].assistance_insurance ===
                    "0.00" &&
                  priceCalcObj.insurance_amount[0].all_in_insurance ===
                    "0.00" ? null : (
                    <FourthStepSection>
                      <FourthStepSubHeader>
                        {langObj.step_4_head1}
                      </FourthStepSubHeader>
                      {errMsg ? (
                        <span className="error font-md">
                          {langObj.step_4_select_insurance}
                        </span>
                      ) : (
                        ""
                      )}
                      <FourthStepHeader>
                        {langObj.step_4_title1}
                      </FourthStepHeader>
                      <Insurance />
                    </FourthStepSection>
                  )}

                  <FourthStepSection>
                    <FloatingContainer>
                      <FourthStepSubHeader>
                        {langObj.step_4_head2}
                      </FourthStepSubHeader>
                      <StepsContainer>
                        <img src={ImportantIcon} alt="imporant-logo" />
                        <p className="upgraded-card-text margin-x">
                          {langObj.step_4_note1}
                        </p>
                      </StepsContainer>
                    </FloatingContainer>
                    <FourthStepHeader>
                      {langObj.step_4_booker_details}
                    </FourthStepHeader>
                    <PricingContainer>
                      <UserDetails
                        edit={() => this.editSection(1)}
                        heading={langObj.step_4_title2}
                      >
                        <FloatingContainer className="step-four-passenger-details">
                          <SingleSection className="w-30">
                            <NormalText>
                              {langObj.step_1_first_name} -{" "}
                              {langObj.step_1_last_name}
                            </NormalText>
                            <HighlightText className="font-weight-bold">
                              {stepOneInputs.first_name}{" "}
                              {stepOneInputs.last_name}
                            </HighlightText>
                          </SingleSection>
                          <SingleSection className="w-30">
                            <NormalText>{langObj.step_1_email}</NormalText>
                            <HighlightText className="font-weight-bold">
                              {stepOneInputs.email}
                            </HighlightText>
                          </SingleSection>
                          <SingleSection className="w-30">
                            <NormalText>{langObj.step_1_ph_num}</NormalText>
                            <HighlightText className="font-weight-bold">
                              {stepOneInputs.telephone_number}
                            </HighlightText>
                          </SingleSection>
                        </FloatingContainer>
                        <FloatingContainer className="step-four-passenger-details">
                          <SingleSection className="w-30">
                            <NormalText>
                              {langObj.step_1_street} &{" "}
                              {langObj.step_1_h_number}
                            </NormalText>
                            <HighlightText className="font-weight-bold">
                              {stepOneInputs.street}{" "}
                              {stepOneInputs.house_number}
                            </HighlightText>
                          </SingleSection>
                          <SingleSection className="w-20">
                            <NormalText>{langObj.step_1_p_code}</NormalText>
                            <HighlightText className="font-weight-bold">
                              {stepOneInputs.postal_code}
                            </HighlightText>
                          </SingleSection>
                          <SingleSection className="w-20">
                            <NormalText>{langObj.step_1_city}</NormalText>
                            <HighlightText className="font-weight-bold">
                              {stepOneInputs.city}
                            </HighlightText>
                          </SingleSection>
                          <SingleSection className="w-20">
                            <NormalText>{langObj.step_1_country}</NormalText>
                            <HighlightText className="font-weight-bold">
                              {stepOneInputs.country.value}
                            </HighlightText>
                          </SingleSection>
                        </FloatingContainer>
                      </UserDetails>
                      {this.props.cmp.stepOneInputs.contact_status ? (
                        <StepsContainer className="text-underline">
                          <img src={Tick} alt="tick icon" />
                          <Label className="margin-l">
                            {langObj.step_1_allow_contact}
                          </Label>
                        </StepsContainer>
                      ) : (
                        ""
                      )}
                      {invoice ? (
                        <UserDetails
                          edit={() => this.editSection(1)}
                          heading={langObj.step_4_company_details}
                        >
                          <FloatingContainer className="step-four-passenger-details">
                            <SingleSection>
                              <NormalText>
                                {langObj.step_1_company_name}
                              </NormalText>
                              <HighlightText className="font-weight-bold">
                                {stepOneInputs.company_name}
                              </HighlightText>
                            </SingleSection>
                            <SingleSection>
                              <NormalText>
                                {langObj.step_1_vat_number}
                              </NormalText>
                              <HighlightText className="font-weight-bold">
                                {stepOneInputs.vat_number}
                              </HighlightText>
                            </SingleSection>
                          </FloatingContainer>
                        </UserDetails>
                      ) : (
                        ""
                      )}
                    </PricingContainer>
                  </FourthStepSection>
                  <FourthStepSection>
                    <FourthStepHeader>{langObj.step_2_head1}</FourthStepHeader>
                    <PricingContainer>
                      {adult.length > 0 ? (
                        <UserDetails
                          edit={() => this.editSection(2)}
                          heading={langObj.adult}
                        >
                          {adult.map((x, i) => {
                            return (
                              <FloatingContainer className="justify=unset step-four-passenger-details">
                                <SingleSection className="w-10">
                                  <NormalText>
                                    {langObj.step_2_gender}
                                  </NormalText>
                                  <HighlightText className="text-capitalize font-weight-bold">
                                    {x.gender.value === "male"
                                      ? langObj.gender_1
                                      : langObj.gender_2}
                                  </HighlightText>
                                </SingleSection>
                                <SingleSection className="m-0 px-3 step-four-single-section  w-40">
                                  <NormalText>
                                    {langObj.step_2_first_name} -{" "}
                                    {langObj.step_2_last_name}
                                  </NormalText>
                                  <HighlightText className="font-weight-bold">
                                    {x.first_name} {x.last_name}
                                  </HighlightText>
                                </SingleSection>
                                <SingleSection className="m-0 step-four-single-section px-3  w-40">
                                  <NormalText>{langObj.step_2_dob}</NormalText>
                                  <HighlightText className="text-capitalize font-weight-bold">
                                    {moment(
                                      new Date(
                                        x.day.value +
                                          " " +
                                          getMonthName(x.month.value - 1) +
                                          " " +
                                          x.year.value
                                      )
                                    )
                                      .format(dateFormat)
                                      .replace(/ste|de/, "")}
                                  </HighlightText>
                                </SingleSection>
                              </FloatingContainer>
                            );
                          })}
                        </UserDetails>
                      ) : (
                        ""
                      )}
                      {child.length > 0 ? (
                        <UserDetails
                          edit={() => this.editSection(2)}
                          heading={langObj.children}
                        >
                          {child.map((x, i) => {
                            return (
                              <FloatingContainer className="justify=unset step-four-passenger-details">
                                <SingleSection className="w-10">
                                  <NormalText>
                                    {langObj.step_2_gender}
                                  </NormalText>
                                  <HighlightText className="font-weight-bold text-capitalize">
                                    {x.gender.value === "male"
                                      ? langObj.gender_1
                                      : langObj.gender_2}
                                  </HighlightText>
                                </SingleSection>
                                <SingleSection className="m-0 px-3 step-four-single-section  w-40">
                                  <NormalText>
                                    {langObj.step_2_first_name} -{" "}
                                    {langObj.step_2_last_name}
                                  </NormalText>
                                  <HighlightText className="font-weight-bold">
                                    {x.first_name} {x.last_name}
                                  </HighlightText>
                                </SingleSection>
                                <SingleSection className="m-0 px-3 step-four-single-section  w-40">
                                  <NormalText>{langObj.step_2_dob}</NormalText>
                                  <HighlightText className="text-capitalize font-weight-bold">
                                    {moment(
                                      new Date(
                                        x.day.value +
                                          " " +
                                          getMonthName(x.month.value - 1) +
                                          " " +
                                          x.year.value
                                      )
                                    )
                                      .format(dateFormat)
                                      .replace(/ste|de/, "")}
                                  </HighlightText>
                                </SingleSection>
                              </FloatingContainer>
                            );
                          })}
                        </UserDetails>
                      ) : (
                        ""
                      )}
                    </PricingContainer>
                  </FourthStepSection>
                  <FourthStepSection>
                    <FourthStepHeader>{langObj.step_4_note2}</FourthStepHeader>
                    <TextArea
                      name="remarks"
                      val={stepOneInputs.remarks}
                      inputChange={this.onInputChange}
                    />
                  </FourthStepSection>
                  <FourthStepSection>
                    <FourthStepSubHeader>
                      {langObj.step_4_head3}
                    </FourthStepSubHeader>
                    <FourthStepHeader>{langObj.step_4_title5}</FourthStepHeader>
                    <PricingContainer>
                      <PackageDetails className="my-2">
                        <FourthStepSubHeader className="font-weight-bold font-md text-capitalize">
                          {packageObj.package_name}
                        </FourthStepSubHeader>
                        <P className="text-capitalize">
                          {langObj.departure}:{" "}
                          {moment(new Date(packageObj.start_date))
                            .format(dateFormat)
                            .replace(/ste|de/, "")}{" "}
                          / {packageObj.duration_number[0]} {langObj.days} -{" "}
                          {packageObj.duration_number[1]} {langObj.nights} /
                          {langObj.return}:{" "}
                          {moment(new Date(packageObj.end_date))
                            .format(dateFormat)
                            .replace(/ste|de/, "")}
                        </P>
                        <FloatingHeader className="step-four-packages">
                          <P>
                            {packageObj.qty_number[0]}{" "}
                            {packageObj.qty_number[0] > 1
                              ? langObj.adults
                              : langObj.adult}
                            {packageObj.qty_number.length > 1
                              ? `, ${packageObj.qty_number[1]} ${
                                  packageObj.qty_number[1] > 1
                                    ? langObj.children
                                    : langObj.child
                                }`
                              : ""}
                          </P>
                          <P>
                            {packageObj.total_qty} x{" "}
                            <span className="small mx-1"> € </span>{" "}
                            {this.props.cmp.selectedPrice}={" "}
                            <span className="small mx-1"> € </span>{" "}
                            {parseInt(packageObj.total_qty) *
                              parseInt(this.props.cmp.selectedPrice)}
                          </P>
                        </FloatingHeader>
                      </PackageDetails>
                      <PriceDetails heading={langObj.step_4_head3}>
                        {"hotel" in priceCalcObj
                          ? priceCalcObj.hotel.map((x, i) => {
                              return (
                                <FloatingHeader className="step-four-packages">
                                  <P className="first-letter-cap">
                                    {langObj.component_2}:{" "}
                                    <span className="text-capitalize">
                                      {x.name}
                                    </span>
                                  </P>
                                  {"price" in x ? (
                                    <P>
                                      {packageObj.total_qty}x{" "}
                                      <span className="small mx-1"> € </span>
                                      {x.price}=
                                      <span className="small mx-1"> € </span>
                                      {x.total_price}
                                    </P>
                                  ) : (
                                    <P>{langObj.step_3_avail}</P>
                                  )}
                                  {/* <P>{'price' in x ? `${packageObj.total_qty}x ${<span className='font-sm mx-1'> € </span>} ${x.price} =  ${x.total_price}` :
                                                            x.total_price}</P> */}
                                </FloatingHeader>
                              );
                            })
                          : ""}
                        {"airline" in priceCalcObj
                          ? priceCalcObj.airline.map((x, i) => {
                              return (
                                <FloatingHeader className="step-four-packages">
                                  <P className="first-letter-cap">
                                    {langObj.component_1}:{" "}
                                    <span className="text-capitalize">
                                      {x.name}
                                    </span>
                                  </P>
                                  {"price" in x ? (
                                    <P>
                                      {packageObj.total_qty}x{" "}
                                      <span className="small mx-1"> € </span>
                                      {x.price}=
                                      <span className="small mx-1"> € </span>
                                      {x.total_price}
                                    </P>
                                  ) : (
                                    <P>{langObj.step_3_avail}</P>
                                  )}
                                </FloatingHeader>
                              );
                            })
                          : ""}
                      </PriceDetails>
                      {priceCalcObj.tour.length > 0 ? (
                        <PriceDetails heading={langObj.component_3}>
                          {"tour" in priceCalcObj
                            ? priceCalcObj.tour.map((x, i) => {
                                return (
                                  <FloatingHeader className="step-four-packages">
                                    <P className="first-letter-cap">{x.name}</P>
                                    {"price" in x ? (
                                      <P>
                                        {packageObj.total_qty}x{" "}
                                        <span className="small mx-1"> € </span>
                                        {x.price}=
                                        <span className="small mx-1"> € </span>
                                        {x.total_price}
                                      </P>
                                    ) : (
                                      <P>{langObj.later_to_pay}</P>
                                    )}
                                  </FloatingHeader>
                                );
                              })
                            : ""}
                        </PriceDetails>
                      ) : (
                        ""
                      )}
                      {priceCalcObj.excursion.length > 0 ? (
                        <PriceDetails heading={langObj.component_extra}>
                          {"excursion" in priceCalcObj
                            ? priceCalcObj.excursion.map((x, i) => {
                                return (
                                  <FloatingHeader className="step-four-packages">
                                    <P className="first-letter-cap">
                                      <span className="text-capitalize">
                                        {x.name}
                                      </span>
                                    </P>
                                    {"adult_price" in x ? (
                                      <P>
                                        {adult.length}
                                        <span className="text-lowercase">
                                          x
                                        </span>{" "}
                                        <span className="small mx-1"> € </span>
                                        {x.adult_price}
                                        {child.length > 0 ? (
                                          <span className="text-lowercase">
                                            {" "}
                                            + {child.length} x{" "}
                                            <span className="small mx-1">
                                              {" "}
                                              €{" "}
                                            </span>
                                            {x.child_price
                                              ? x.child_price
                                              : x.adult_price}
                                          </span>
                                        ) : (
                                          ""
                                        )}{" "}
                                        =<span className="small mx-1"> € </span>
                                        {x.total_price}
                                      </P>
                                    ) : (
                                      <P>{langObj.step_3_avail}</P>
                                    )}
                                  </FloatingHeader>
                                );
                              })
                            : ""}
                        </PriceDetails>
                      ) : (
                        ""
                      )}
                      {priceCalcObj.transfer.length > 0 ||
                      priceCalcObj.car_rental.length > 0 ||
                      priceCalcObj.added_value.length > 0 ? (
                        <PriceDetails heading={langObj.extras}>
                          {"transfer" in priceCalcObj
                            ? priceCalcObj.transfer.map((x, i) => {
                                console.log("ind price", typeof x.total_price);
                                return (
                                  <FloatingHeader className="step-four-packages">
                                    <P className="first-letter-cap">
                                      {langObj.component_5}:{" "}
                                      <span className="text-capitalize">
                                        {x.name}
                                      </span>
                                    </P>
                                    {"price" in x ? (
                                      <P>
                                        {x.total_qty} x{" "}
                                        <span className="small mx-1"> € </span>{" "}
                                        {x.price} ={" "}
                                        <span className="small mx-1"> € </span>{" "}
                                        {x.total_price}
                                      </P>
                                    ) : (
                                      <P>{langObj.step_3_avail}</P>
                                    )}
                                  </FloatingHeader>
                                );
                              })
                            : ""}
                          {"car_rental" in priceCalcObj
                            ? priceCalcObj.car_rental.map((x, i) => {
                                return (
                                  <FloatingHeader className="step-four-packages">
                                    <P className="first-letter-cap">
                                      {langObj.component_6}:{" "}
                                      <span className="text-capitalize">
                                        {x.name}
                                      </span>
                                    </P>
                                    {"price" in x ? (
                                      <P>
                                        {packageObj.total_qty}x{" "}
                                        <span className="small mx-1"> € </span>
                                        {x.price}=
                                        <span className="small mx-1"> € </span>
                                        {x.total_price}
                                      </P>
                                    ) : (
                                      <P>{langObj.later_to_pay}</P>
                                    )}
                                  </FloatingHeader>
                                );
                              })
                            : ""}
                          {"added_value" in priceCalcObj
                            ? priceCalcObj.added_value.map((x, i) => {
                                return (
                                  <FloatingHeader className="step-four-packages">
                                    <P className="first-letter-cap">
                                      {langObj.component_7}:{" "}
                                      <span className="text-capitalize">
                                        {x.name}
                                      </span>
                                    </P>
                                    {"price" in x ? (
                                      <P>
                                        {packageObj.total_qty}x{" "}
                                        <span className="small mx-1"> € </span>
                                        {x.price}=
                                        <span className="small mx-1"> € </span>
                                        {x.total_price}
                                      </P>
                                    ) : (
                                      <P>{langObj.step_3_avail}</P>
                                    )}
                                  </FloatingHeader>
                                );
                              })
                            : ""}
                        </PriceDetails>
                      ) : (
                        ""
                      )}
                      {priceCalcObj.insurance_amount[0]
                        .cancellation_insurance === "0.00" &&
                      priceCalcObj.insurance_amount[0].assistance_insurance ===
                        "0.00" &&
                      priceCalcObj.insurance_amount[0].all_in_insurance ===
                        "0.00" ? null : (
                        <PriceDetails heading={langObj.step_4_head1}>
                          <FloatingHeader className="step-four-packages">
                            {"name" in selected ? (
                              selected.name === "no_assistance" ? (
                                <React.Fragment>
                                  <P className="first-letter-cap">
                                    {langObj.no_insurance}
                                  </P>
                                  <P>
                                    0x <span className="font-sm mx-1"> € </span>{" "}
                                    0 ={" "}
                                    <span className="font-sm mx-1"> € </span> 0
                                  </P>
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <P className="first-letter-cap">
                                    {selected.name.replace(/_/g, " ")}
                                  </P>
                                  {selected.price ? (
                                    <P>
                                      {packageObj.total_qty}x{" "}
                                      <span className="font-sm mx-1"> € </span>{" "}
                                      {selected.price} ={" "}
                                      <span className="font-sm mx-1"> € </span>{" "}
                                      {(
                                        packageObj.total_qty * selected.price
                                      ).toFixed(2)}
                                    </P>
                                  ) : (
                                    <P>
                                      <span className="font-sm mx-1"> € </span>{" "}
                                      {selected.total_price}
                                    </P>
                                  )}
                                </React.Fragment>
                              )
                            ) : (
                              <React.Fragment>
                                <P className="first-letter-cap">
                                  {langObj.no_insurance}
                                </P>
                                <P className="first-letter-cap">
                                  0x <span className="font-sm mx-1"> € </span> 0
                                  = <span className="font-sm mx-1"> € </span> 0
                                </P>
                              </React.Fragment>
                            )}
                          </FloatingHeader>
                        </PriceDetails>
                      )}
                      {priceCalcObj.file_charges[0].total_price ===
                      "0.00" ? null : (
                        <PriceDetails heading={langObj.file_charges}>
                          {"file_charges" in priceCalcObj
                            ? priceCalcObj.file_charges.map((x, i) => {
                                return (
                                  <FloatingHeader className="step-four-packages">
                                    <P className="first-letter-cap">
                                      {langObj.per_person}
                                    </P>
                                    {"price" in x ? (
                                      <P>
                                        {packageObj.total_qty}x{" "}
                                        <span className="small mx-1"> € </span>
                                        {x.price}=
                                        <span className="small mx-1"> € </span>
                                        {x.total_price}
                                      </P>
                                    ) : (
                                      <P>{x.total_price}</P>
                                    )}
                                  </FloatingHeader>
                                );
                              })
                            : ""}
                        </PriceDetails>
                      )}
                    </PricingContainer>
                    <TotalPriceContainer>
                      <h5>
                        {langObj.step_0_price}{" "}
                        <span className="font-sm mx-1"> € </span>{" "}
                        {packageObj.final_price}
                      </h5>
                    </TotalPriceContainer>
                    <div className="note">
                      <Label className="total-price-note">
                        {langObj.step_0_note2}
                      </Label>
                    </div>
                  </FourthStepSection>
                  <FourthStepSection>
                    <FourthStepSubHeader>
                      {langObj.step_4_head4}
                    </FourthStepSubHeader>
                    <FourthStepHeader>{langObj.step_4_title6}</FourthStepHeader>
                    {errVal ? (
                      <span className="error">
                        {langObj.step_4_payment_method}
                      </span>
                    ) : (
                      ""
                    )}
                    {packageVars.partial_pay ? (
                      <React.Fragment>
                        <PricingContainer>
                          <PaymentDetails
                            heading={`${langObj.payment_head}?`}
                          />
                          <P>{langObj.step_4_note3}</P>
                        </PricingContainer>
                        <FlexWrapper className="step-four-passenger-details">
                          <PaymentMethods
                            val="full payment"
                            name={langObj.payment_desp_1}
                            price={`€ ${packageObj.final_price}`}
                          />
                          <P>- {langObj.or} -</P>
                          <PaymentMethods
                            val="partial payment"
                            name={langObj.payment_desp_2}
                            price={`€ ${(
                              (parseInt(packageVars.partial_amount) / 100) *
                              parseInt(packageObj.final_price)
                            ).toFixed(2)}`}
                          />
                        </FlexWrapper>
                        {err ? (
                          <span className="error">
                            {langObj.step_4_payment_type}
                          </span>
                        ) : (
                          ""
                        )}
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                    <PricingContainer>
                      <PaymentDetails heading={langObj.step_4_pay_op} />
                    </PricingContainer>
                    <PaymentOptions />
                    {/* terms and conditions */}
                    <Modal
                      classNames={{
                        overlay: "modal-passengers-overlay",
                        modal: "modal-passengers-container",
                        closeButton: "modal-close-btn",
                      }}
                      open={this.state.openTerms}
                      onClose={this.closeTerms}
                      center
                    >
                      <EnquiryContainer>
                        <div>
                          <FlexPackageIcon>
                            <i className="fa fa-info text-danger info-icon"></i>
                            <h3 className="primary-text text-bold">
                              Terms and conditions
                            </h3>
                          </FlexPackageIcon>
                          <InputFieldsSection>
                            <InputFieldsSection>
                              <h6 className="font-weight-bold">Title</h6>
                              <p className="text-grey font-sm">
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's standard dummy text ever since
                                the 1500s, when an unknown printer took a galley
                                of type and scrambled it to make a type specimen
                                book.
                              </p>
                            </InputFieldsSection>
                            <InputFieldsSection>
                              <h6 className="font-weight-bold">Title</h6>
                              <p className="text-grey font-sm">
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's standard dummy text ever since
                                the 1500s, when an unknown printer took a galley
                                of type and scrambled it to make a type specimen
                                book.
                              </p>
                            </InputFieldsSection>
                            <InputFieldsSection>
                              <h6 className="font-weight-bold">Title</h6>
                              <p className="text-grey font-sm">
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's standard dummy text ever since
                                the 1500s, when an unknown printer took a galley
                                of type and scrambled it to make a type specimen
                                book.
                              </p>
                            </InputFieldsSection>
                          </InputFieldsSection>
                          <InputFiled className="w-100 ">
                            <Button
                              submit={this.closeTerms}
                              name="Close"
                              classname="button-primary bg-danger my-3"
                            />
                          </InputFiled>
                        </div>
                      </EnquiryContainer>
                    </Modal>
                    {/* terms and condoitions */}
                  </FourthStepSection>
                  <TermsConditions>
                    <Checkbox
                      // disable={option===this.props.val?true:false}
                      id="terms"
                      name="terms"
                      check={this.state.terms}
                      change={this.termsChange}
                      val="terms"
                    />

                    <Label className="terms">
                      <a
                        href={termsUrl}
                        target="_blank"
                        // onClick={this.openTerms}
                        className="c-pointer terms-url text-underline ml-2"
                      >
                        {langObj.step_4_terms}
                      </a>
                    </Label>
                  </TermsConditions>
                  {this.state.errTerm && (
                    <span className="error err-mr-2 first-letter-cap">
                      {langObj.confirm_gen_con}
                    </span>
                  )}
                </InputFieldsSection>
              ) : (
                ""
              )}
              {/* step 4 ends */}
              <ButtonGroups>
                <Button
                  submit={this.prevPage}
                  // disable={pageNum === 1 ? true : false}
                  lefticon="fa fa-chevron-left"
                  classname="button-secondary"
                  name={langObj.previous}
                />
                {pageNum === 4 ? (
                  <Button
                    submit={this.validateStepFour}
                    // disable={pageNum === 4 ? true : false}
                    righticon="fa fa-chevron-right"
                    classname="button-primary"
                    name={langObj.confirm_and_pay}
                  />
                ) : (
                  <Button
                    submit={this.nextPage}
                    disable={pageNum === 4 ? true : false}
                    righticon="fa fa-chevron-right"
                    classname="button-primary"
                    name={langObj.continue}
                  />
                )}
              </ButtonGroups>
            </FormWrapper>
          </FormSection>
          <PackageDetailSection>
            <FormPackageCard />
          </PackageDetailSection>
        </FormSectionWrapper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  cmp: state.calc,
  type: state.paymentType,
  opt: state.paymentOptions,
  checks: state.insurance,
  lang: state.language,
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, {
    packageCmp,
    selectPackage,
    priceCal,
    showErr,
    err,
    err_Val,
    selectTravelers,
    showTableData,
    selectMonth,
    choosenPrice,
    selectPassengers,
    getExtraComps,
    changePageNumber,
    changeInvoice,
    changeStepOne,
    changeStepTwoAdult,
    changeStepTwoChild,
    selectType,
    choosenPackage,
    resetInputs,
    closeModal,
    openModal,
    resetInsurance,
    mon_Options,
    chooseLang,
    chooseEmailSub,
  })(index)
);
