import { PACKAGE_ITEM,PACKAGE_CMP } from './reducer';

export const selectPackage = (data) => ({
  type: PACKAGE_ITEM,
  payload:data
});

export const packageCmp = (data) => ({
    type: PACKAGE_CMP,
    payload:data
  });
