import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Popover, { ArrowContainer } from "react-tiny-popover";
import Linkify from "react-linkify";

import Button from "../Button";
import { priceCal, selectPackage } from "../Forms/actions";
import { selectItem, selected } from "./actions";
import { Colors } from "../../library/colors";
import { copyObj, apiPost } from "../../library/";
import IconInfo from "../../assets/images/ICON-INFO.png";
import Checkbox from "../Checkbox";
import Radio from "../RadioButtons";
const InsuranceSection = styled.div`
  background: white;
  margin: 20px 0px;
  padding: 18px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: ${(props) =>
    props.bg ? "2px 2px 11px 0px #d3d3d3ab" : "0px 0px 0px 0px transparent"};
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: baseline;
  }
`;
const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;
const InsuranceNormalText = styled.h5`
  font-size: 16px;
  margin: 0px 15px;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;
const InsurancePrimaryText = styled.h5`
  font-size: 16px;
  color: ${Colors.primaryColor};
`;
const InsuranceInformationSection = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`;
const InsuranceIcon = styled.img`
  height: 100%;
`;
const Text = styled.h6`
  font-size: 13px;
  font-weight: 100;
  margin: 0px 10px;
`;
const PriceSection = styled.div`
  display: flex;
  align-items: center;
  width: 40%;
  justify-content: space-between;
  flex-wrap: wrap;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;
const PopOverWrapper = styled.div`
  padding: 20px;
  background: white;
  border: 2px solid #8778781f;
  box-shadow: 1px 1px 11px 0px #d3d3d3ab;
  border-left: 6px solid ${Colors.primaryColor}!important;
`;
const componentDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank">
    {text}
  </a>
);

export class index extends Component {
  state = {
    checkboxes: [],
    previousVal: 0,
    isCanPopOpen: false,
    isAssPopOpen: false,
    isAllPopOpen: false,
  };

  onCloseModal = () => {
    this.setState({
      isCanPopOpen: false,
      isAssPopOpen: false,
      isAllPopOpen: false,
    });
  };
  selectInsurance = (e, price) => {
    const { total_qty } = this.props.cmp.packageObj;
    var newPrice = total_qty * price - this.state.previousVal;
    const { langObj } = this.props.lang;
    var checkState = copyObj(this.state.checkboxes);
    const { name, checked } = e.target;
    this.getClacObj(name, price);
    console.log("name,checkjed", name, checked, checkState);
    if (checked) {
      if (name === langObj.no_insurance) {
        checkState = [langObj.no_insurance];
      } else {
        checkState.push(name);
      }
      var newState = checkState.filter((x) => x === name);
      console.log("chcked adte", checkState, checkState.push(name));
      this.props.selectItem(newState);
      this.setState(
        {
          checkboxes: newState,
        },
        () => {
          console.log("checksrate", this.state, name, checked);
        }
      );
    }

    // else{
    //    var newState= checkState.filter(x=>x!==name)
    //    this.setState({
    //     checkboxes:newState
    // },()=>{
    //     console.log('checksrate',this.state)
    //  })
    // }
    console.log("checkbox workng");
  };

  getClacObj = async (name, price) => {
    const {
      packageObj,
      package_cmp,
      priceCalcObj,
      selectedPrice,
      extrasPackage,
    } = this.props.cmp;
    const obj = {
      package_name: packageObj.package_name,
      package_code: packageObj.package_code,
      amount: packageObj.amount,
      qty: packageObj.qty,
      duration: packageObj.duration,
      start_date: packageObj.start_date,
      end_date: packageObj.end_date,
      total_qty: packageObj.total_qty,
      Total_amount: packageObj.Total_amount,
      final_price: selectedPrice,
      extras: extrasPackage,
      qty_number: packageObj.qty_number,
      duration_number: packageObj.duration_number,
    };
    const newobj = {
      step_3: obj,
      selected_price: { name: name, price: price },
    };
    const data = {
      url: "calculation/",
      body: newobj,
    };
    const newData = await apiPost(data);
    this.props.priceCal(newData);
    this.props.selected(newData.selected_insurance);
    // console.log('after selectinfg before insuranc',newData.final_data.package_details)
    this.props.selectPackage(newData.package_details);
    console.log("after selectinfg insuranc", newData);
  };
  render() {
    console.log("insurance props", this.props);
    const { priceCalcObj } = this.props.cmp;
    const { checkboxes } = this.props.checks;
    const { langObj } = this.props.lang;
    console.log("checkbox ", checkboxes);
    const { isCanPopOpen, isAssPopOpen, isAllPopOpen } = this.state;
    return "insurance_amount" in priceCalcObj ? (
      <React.Fragment>
        {priceCalcObj.insurance_amount[0].cancellation_insurance ===
        "0.00" ? null : (
          <>
            <InsuranceSection
              bg={
                checkboxes.indexOf(langObj.cancellation_insurance) === -1
                  ? false
                  : true
              }
            >
              <CheckboxWrapper>
                <Checkbox
                  cls="checkbox-group"
                  // type="radio"
                  disable={
                    checkboxes.indexOf(langObj.cancellation_insurance) === -1
                      ? false
                      : true
                  }
                  id={langObj.cancellation_insurance}
                  name={langObj.cancellation_insurance}
                  check={
                    checkboxes.indexOf(langObj.cancellation_insurance) === -1
                      ? false
                      : true
                  }
                  change={(e) =>
                    this.selectInsurance(
                      e,
                      priceCalcObj.insurance_amount[0].cancellation_insurance
                    )
                  }
                  val="cancel"
                />
                <InsuranceNormalText>
                  {langObj.cancellation_insurance}
                </InsuranceNormalText>
              </CheckboxWrapper>
              <PriceSection>
                <Popover
                  isOpen={isCanPopOpen}
                  padding={10} // adjust padding here!
                  disableReposition
                  containerClassName="pop-over-inf insurance-popover"
                  onClickOutside={() => this.setState({ isCanPopOpen: false })}
                  position={"right"} // preferred position
                  content={({ position, targetRect, popoverRect }) => (
                    <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                      position={position}
                      targetRect={targetRect}
                      popoverRect={popoverRect}
                      arrowColor={Colors.primaryColor}
                      arrowSize={10}
                      arrowStyle={{ opacity: 1 }}
                    >
                      <PopOverWrapper>
                        <h5 className="primary-text font-sm first-letter-cap">
                          {langObj.more_information}
                        </h5>
                        <h5 className="font-weight-bold primary-text first-letter-cap word-break">
                          {langObj.cancellation_insurance}
                        </h5>
                        <div className="my-2">
                          <div className="my-3">
                            {/* <h6 className='font-weight-bold'>Title</h6> */}
                            <p className="text-grey font-sm">
                              <Linkify
                                properties={{ target: "_blank" }}
                                componentDecorator={componentDecorator}
                              >
                                {langObj.more_info_cancellation_insurance}
                              </Linkify>
                            </p>
                          </div>
                          <Button
                            submit={this.onCloseModal}
                            name={langObj.close}
                            classname="button-close my-3"
                          />
                        </div>
                      </PopOverWrapper>
                    </ArrowContainer>
                  )}
                >
                  <InsuranceInformationSection
                    onClick={() =>
                      this.setState({ isCanPopOpen: !isCanPopOpen })
                    }
                  >
                    <InsuranceIcon src={IconInfo} alt="information icon" />
                    <Text className="underline">
                      {langObj.more_information}
                    </Text>
                  </InsuranceInformationSection>
                </Popover>

                <InsurancePrimaryText>
                  + <span className="small-text"> € </span>{" "}
                  {priceCalcObj.insurance_amount[0].cancellation_insurance} /pp
                </InsurancePrimaryText>
              </PriceSection>
            </InsuranceSection>
          </>
        )}

        {/*Assistance insurance */}
        {priceCalcObj.insurance_amount[0].assistance_insurance ===
        "0.00" ? null : (
          <>
            <InsuranceSection
              bg={
                checkboxes.indexOf(langObj.assistance_insurance) === -1
                  ? false
                  : true
              }
            >
              <CheckboxWrapper>
                <Checkbox
                  cls="checkbox-group"
                  // type="radio"
                  disable={
                    checkboxes.indexOf(langObj.assistance_insurance) === -1
                      ? false
                      : true
                  }
                  id={langObj.assistance_insurance}
                  name={langObj.assistance_insurance}
                  check={
                    checkboxes.indexOf(langObj.assistance_insurance) === -1
                      ? false
                      : true
                  }
                  change={(e) =>
                    this.selectInsurance(
                      e,
                      priceCalcObj.insurance_amount[0].assistance_insurance
                    )
                  }
                  val="Assist"
                />
                <InsuranceNormalText>
                  {langObj.assistance_insurance}
                </InsuranceNormalText>
              </CheckboxWrapper>
              <PriceSection>
                <Popover
                  isOpen={isAssPopOpen}
                  padding={10} // adjust padding here!
                  disableReposition
                  containerClassName="pop-over-inf insurance-popover"
                  onClickOutside={() => this.setState({ isAssPopOpen: false })}
                  position={"right"} // preferred position
                  content={({ position, targetRect, popoverRect }) => (
                    <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                      position={position}
                      targetRect={targetRect}
                      popoverRect={popoverRect}
                      arrowColor={Colors.primaryColor}
                      arrowSize={10}
                      arrowStyle={{ opacity: 1 }}
                    >
                      <PopOverWrapper>
                        <h5 className="primary-text first-letter-cap font-sm">
                          {langObj.more_information}
                        </h5>
                        <h5 className="font-weight-bold primary-text first-letter-cap word-break">
                          {langObj.assistance_insurance}
                        </h5>
                        <div className="my-2">
                          <div className="my-3">
                            {/* <h6 className='font-weight-bold'>Title</h6> */}
                            <p className="text-grey font-sm">
                              <Linkify
                                properties={{ target: "_blank" }}
                                componentDecorator={componentDecorator}
                              >
                                {langObj.more_info_assistance_insurance}
                              </Linkify>
                            </p>
                          </div>
                          <Button
                            submit={this.onCloseModal}
                            name={langObj.close}
                            classname="button-close my-3"
                          />
                        </div>
                      </PopOverWrapper>
                    </ArrowContainer>
                  )}
                >
                  <InsuranceInformationSection
                    onClick={() =>
                      this.setState({ isAssPopOpen: !isAssPopOpen })
                    }
                  >
                    <InsuranceIcon src={IconInfo} alt="information icon" />
                    <Text className="underline">
                      {langObj.more_information}
                    </Text>
                  </InsuranceInformationSection>
                </Popover>

                <InsurancePrimaryText>
                  + <span className="small-text"> € </span>{" "}
                  {priceCalcObj.insurance_amount[0].assistance_insurance}/pp
                </InsurancePrimaryText>
              </PriceSection>
            </InsuranceSection>
          </>
        )}
        {/*Assistance insurance */}
        {/* cancel + assistance */}
        {priceCalcObj.insurance_amount[0].all_in_insurance === "0.00" ? null : (
          <>
            <InsuranceSection
              bg={
                checkboxes.indexOf(langObj.c_a_insurance) === -1 ? false : true
              }
            >
              <CheckboxWrapper>
                <Checkbox
                  cls="checkbox-group"
                  // type="radio"
                  disable={
                    checkboxes.indexOf(langObj.c_a_insurance) === -1
                      ? false
                      : true
                  }
                  id={langObj.c_a_insurance}
                  name={langObj.c_a_insurance}
                  check={
                    checkboxes.indexOf(langObj.c_a_insurance) === -1
                      ? false
                      : true
                  }
                  change={(e) =>
                    this.selectInsurance(
                      e,
                      priceCalcObj.insurance_amount[0].all_in_insurance
                    )
                  }
                  val="cancelassist"
                />
                <InsuranceNormalText>
                  {langObj.c_a_insurance}
                </InsuranceNormalText>
              </CheckboxWrapper>
              <PriceSection>
                <Popover
                  isOpen={isAllPopOpen}
                  padding={10} // adjust padding here!
                  disableReposition
                  containerClassName="pop-over-inf insurance-popover"
                  onClickOutside={() => this.setState({ isAllPopOpen: false })}
                  position={"right"} // preferred position
                  content={({ position, targetRect, popoverRect }) => (
                    <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                      position={position}
                      targetRect={targetRect}
                      popoverRect={popoverRect}
                      arrowColor={Colors.primaryColor}
                      arrowSize={10}
                      arrowStyle={{ opacity: 1 }}
                    >
                      <PopOverWrapper>
                        <h5 className="primary-text first-letter-cap font-sm">
                          {langObj.more_information}
                        </h5>
                        <h5 className="font-weight-bold primary-text first-letter-cap word-break"></h5>
                        <div className="my-2">
                          <div className="my-3">
                            {/* <h6 className='font-weight-bold'>Title</h6> */}
                            <p className="text-grey font-sm">
                              <Linkify
                                properties={{ target: "_blank" }}
                                componentDecorator={componentDecorator}
                              >
                                {langObj.more_info_cancel_and_assistance}
                              </Linkify>
                            </p>
                          </div>
                          <Button
                            submit={this.onCloseModal}
                            name={langObj.close}
                            classname="button-close my-3"
                          />
                        </div>
                      </PopOverWrapper>
                    </ArrowContainer>
                  )}
                >
                  <InsuranceInformationSection
                    onClick={() =>
                      this.setState({ isAllPopOpen: !isAllPopOpen })
                    }
                  >
                    <InsuranceIcon src={IconInfo} alt="information icon" />
                    <Text className="underline">
                      {langObj.more_information}
                    </Text>
                  </InsuranceInformationSection>
                </Popover>

                <InsurancePrimaryText>
                  + <span className="small-text"> € </span>{" "}
                  {priceCalcObj.insurance_amount[0].all_in_insurance} /pp
                </InsurancePrimaryText>
              </PriceSection>
            </InsuranceSection>
          </>
        )}

        {/* cancel + assistance */}
        {/* no insurance starts */}
        <InsuranceSection
          bg={checkboxes.indexOf(langObj.no_insurance) === -1 ? false : true}
        >
          <CheckboxWrapper className="w-75">
            <Checkbox
              cls="checkbox-group"
              // type="radio"
              radio={
                checkboxes.indexOf(langObj.no_insurance) === -1 ? false : true
              }
              id={langObj.no_insurance}
              name={langObj.no_insurance}
              check={
                checkboxes.indexOf(langObj.no_insurance) === -1 ? false : true
              }
              change={(e) => this.selectInsurance(e, 0)}
              val="no"
            />
            <InsuranceNormalText className="first-letter-cap">
              {langObj.no_insurance}
            </InsuranceNormalText>
          </CheckboxWrapper>
          <PriceSection className="width-auto">
            <InsurancePrimaryText>
              + <span className="small-text"> € </span> 0.00
            </InsurancePrimaryText>
          </PriceSection>
        </InsuranceSection>
        {/* no insurance ends */}
      </React.Fragment>
    ) : (
      <p>No insurance available</p>
    );
  }
}

const mapStateToProps = (state) => ({
  cmp: state.calc,
  checks: state.insurance,
  lang: state.language,
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, { priceCal, selectPackage, selected, selectItem })(
    index
  )
);
