import React, { Component } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment/min/moment-with-locales';

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
});
const colWidth = {
  width: '150px'
};

const col1Width = {
  width: '230px'
};


function createData(days, d1, d2, d3, d4, d5, d6, d7) {
  return { days, d1, d2, d3, d4, d5, d6, d7 };
}

const rows = [
  createData('5 days - 4 nights', '$160', '$159', '$67', '$424', '$156'),
  createData('6 days - 5 nights', '$110', '$109', '$60', '$124', '$536'),
  createData('9 days - 8 nights', '$120', '$179', '$623', '$624', '$565'),
];


export class index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      tableCell: '',
      tableCellData: ''
    }
  }

  choosePrice = (index, data) => {
    this.setState({
      tableCell: index,
      tableCellData: data
    })
  }
  render() {
    const { row, col, datas,view="" } = this.props
    var newCol = col
    if(view === "")
    {
      var i
      for (i = 0; i < 5; i++) {
        if (!newCol[i]) {
          newCol[i] = ''
        }
      }
      var colArr = newCol.length > 0 ? newCol.length > 5 ? newCol : newCol[4] = '' : []
      // moment('20 July'.replace(' ', '-').concat('-' + new Date().getFullYear())).lang('nl').format('DD-MMMM')
      console.log('table data', col, colArr)
    }
    const { tableCell, tableCellData } = this.state
    const { langObj } = this.props.lang
    var selLang=this.props.lang.selectedLang.toLowerCase()
    console.log('tabselLang',selLang)
    moment.locale(selLang);
    return (<TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className='text-capitalize ' style={col1Width}>{langObj.days}</TableCell>
            {col && col.length > 0 ? col.map((x, i) => {
              // const dateObj=x.replace(' ', '-').concat('-' + new Date().getFullYear())
              return <TableCell id='price-cell' className='primary-text'
                style={colWidth}>{x ? moment(new Date(x)).format('DD MMM').replace('.', '') : ''}</TableCell>
            }) : ''}
          </TableRow>
        </TableHead>
        <TableBody>
          {row && row.length > 0 ? row.map((row, index) => (
            <TableRow key={row}>
              <TableCell
                className='days-border'
                style={col1Width}
                component="th" scope="row">
                {row.split("-")[0] + ` ${langObj.days} - ` + row.split("-")[1] + ` ${langObj.nights}`}
              </TableCell>
              {col && col.length > 0 ? col.map((x, i) => {
                const price = x ? datas[x].filter(item => item.day === row).length > 0 ? datas[x].filter(item => item.day === row)[0].amount : '' : ''
                console.log('pricing details', price)
                return <TableCell style={colWidth} onClick={() =>
                  this.props.choosePrice(index, i, price, x, row)}
                  id='price-cell'
                  className={this.props.tableCell === index && price &&
                    this.props.tableCellData === i ? `button-primary green-border text-light font-weight-bold ${price ? 'c-pointer' : ''}` :
                    `${price ? 'c-pointer font-weight-bold' : ''}`}>
                  {price ? <span className='font-12'> € </span> : ''} {price}
                </TableCell>
              }) : 'No data available for this month'}

              {/* <TableCell align="right">{row.d6}</TableCell>
                    <TableCell align="right">{row.d7}</TableCell> */}
            </TableRow>
          )) : 'No data available for this month'}
        </TableBody>
      </Table>
    </TableContainer>)
  }
}

const mapStateToProps = (state) => ({
  lang: state.language
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(index)
