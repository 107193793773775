import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import _ from 'lodash'

import { chooseLang } from './action'
import { getPackages } from '../../pages/Home/actions'
import Button from '../Button'
import Textarea from '../TextArea'
import { Colors } from '../../library/colors'
import Loader from '../HeaderLoader'
import { validEmailRegex, validateInputs, apiPost, apiGet } from '../../library'
import logo from '../../assets/images/cnx-logo.svg'
import Input from '../Input'
import { changeStepOne } from '../Forms/actions'
// *** components styles starts *** //
const HeaderWrapper = styled.div`
box-sizing:border-box;
height:72px;
padding:0 5rem;
position:relative;
display:flex;
justify-content:space-between;
border-top:3px solid ${Colors.primaryColor};
@media only screen and (max-width: 768px) {
padding:0 1rem;
}  
`
const LogoContainer = styled.div`
padding:10px 0px;
`
const Logo = styled.img`
width:auto;
max-width:100%;
height: 50px;
`
const HelpLanguageSection = styled.div`
padding-bottom:10px;
display:flex;
justify-content:space-between;
@media only screen and (max-width: 768px) {
   display:none;
}
`
const HelpSection = styled.div`
margin:0px 30px;
align-self: flex-end;
`
const LanguageSection = styled.div`
display:flex;
justify-content:space-between;
`
const LanguageContainer = styled.div`
display:flex;
justify-content:center;
`
const Label = styled.div`
text-decoration:underline;
color:${Colors.primaryText};
font-size:14px;
`
const LanguageButton = styled.div`
width:30px;
height:60px;
background:#dddddd;
display:flex;
align-items:flex-end;
// border: 1px solid #eaeaea;
cursor:pointer;
// transition: all 1s;
&:nth-child(2) {
    margin:0px 8px;
  }
`
const Span = styled.div`
background:${Colors.primaryBg};
text-align:center;
height:25px;
font-size:13px;
width:100%;
padding:3px 0px;
// transition:background 1s;
text-transform:uppercase;
`
const SpanCopy = styled.div`
background:${Colors.primaryBg};
text-align:center;
height:25px;
font-size:13px;
width:30px;
padding:3px 0px;
// transition:background 1s;
text-transform:uppercase;
`
const EnquiryContainer = styled.div`
margin:20px 0px;
background:transparent;
`
const EnquirySuccsess = styled.div`
margin:20px 0px;
background:transparent;
`
const HelpUsFrom = styled.div`

`
const Text = styled.div`
font-size:18px;

`
const FlexWrapper = styled.div`
display:flex;
// align-items:center;
justify-content:space-between;
@media only screen and (max-width: 768px) {
    flex-direction:column;
}
`
const InputFiled = styled.div`
margin:15px 0px;
width:47%;
flex-wrap:wrap;
@media only screen and (max-width: 768px) {
    width:100%;
}
`
const ButtonWrapper = styled.div`
display:flex;
align-items:center;
justify-content:flex-end;
`
const ResponsiveSection = styled.div`
position:absolute;
background:#fff;
min-height:100vh;
z-index:4;
text-align:center;
padding:20px;
display:none;
top: 0;
left: 0;
right: 0;
@media only screen and (max-width: 768px) {
    display:inline-block;
 }
`
const SingleSection = styled.div`
margin:20px 0px;
`
// *** components styles ends *** //

export class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showNavbar: false,
            // selectedLang: 'en'/,
            open: false,
            loading: false,
            show: false,
            keys: {
                first_name: '',
                last_name: '',
                email: '',
                telephone: '',
                remarks: '',
                package_id: '',
                package_name: '',
            },
            field_errors: {},
            errors: {}
        }
    }
    componentDidMount() {
        localStorage.setItem('lang', 'EN')
        this.checkIfBrowserIsIE11();
    }

    // onInputChnage
    onInputChange = (e) => {
        const { name } = e.target
        var value = e.target.value
        let errVal;
        switch (name) {
            case 'email':
                errVal = !validEmailRegex.test(value) ? this.props.lang.langObj.invalid_email : ''
                break;
            case 'telephone':
                // value = value.toString().slice(0, 10);
                // errVal = value.length === 10 ? '' : 'Mobile number is not valid';
                // parseInt(value);
                value = value.replace(/[^+()/0-9]/gi, '');
                value = value.toString().slice(0, 16);
                break;
            default:
        }
        this.setState({
            field_errors: {
                ...this.state.field_errors,
                [name]: errVal !== 'undefined' ? errVal : '',
            },
        });
        this.setState({
            keys: { ...this.state.keys, [name]: value }
        })
    }

    // Change Language //
    chooseLanguage = async (lang) => {
        const language = lang.toLowerCase()
        // this.getCountry(lang)
        this.getPackage(lang)
        // this.setState({ selectedLang: lang })
        const obj = {
            url: 'translation/',
            body: {
                text_language: lang.toUpperCase()
            }
        }
        const newLng = await apiPost(obj)
        const newObj = { obj: newLng[0], lang: lang }
        this.props.chooseLang(newObj)
        this.props.history.push(`/${language}`)
        console.log('neeLang Data', newLng)
    }

    //getCountry
    getCountry = (lang) => {
        const { stepOneInputs } = this.props.cmp
        var language = lang.toUpperCase()
        var ctry = language === 'NL' ? 'België' : language === 'EN' ? 'Belgium' : 'Belgique'
        stepOneInputs['country'] = { label: ctry, value: ctry }
        this.props.changeStepOne(stepOneInputs)
    }


    // get packages
    getPackage = async (lang) => {
        const newKeys = {
            des_name: [],
            order_name: '',
            order_price: '',
            text_language: lang.toUpperCase(),
            package_code: '',
            detail: ''
        }
        const data = {
            url: 'user_pack_view/',
            body: newKeys
        }
        const newData = await apiPost(data)
        const { dashboard } = newData
        if (dashboard.status === 200) {
            this.props.getPackages(dashboard.data)
        } else {
        }
    }

    onOpenModal = () => {
        const sampleKeys = {
            first_name: '',
            last_name: '',
            email: '',
            telephone: '',
            remarks: '',
            package_id: '',
            package_name: '',
        }
        this.setState({ open: true, show: false, keys: sampleKeys });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    //Show navbar
    showNavbar = () => {
        this.setState({
            showNavbar: !this.state.showNavbar
        })
    }

    checkIfBrowserIsIE11 = () => {
        // true on IE11, false on Edge and other IEs/browsers.
        if (/Trident\/|MSIE/.test(window.navigator.userAgent)) {
            alert("Please update your browser, IE11 is no longer supported by Microsoft and has security leaks");
        }
    }


    sendRequest = async (language, emailSubject, packageDetail) => {
        const requiredFields = _.pick(this.state.keys, 'first_name', 'email', 'last_name', 'telephone', 'remarks')
        let errors = validateInputs(requiredFields);
        requiredFields['lang'] = language
        requiredFields['label'] = ""
        if (emailSubject !== "") {
            requiredFields['label'] = emailSubject
        }
        if (Object.keys(errors).length === 0) {
            this.setState({
                loading: true
            })
            if (packageDetail !== "") {
                requiredFields['package_name'] = packageDetail.package[0].name
                requiredFields['package_id'] = packageDetail.package[0].package_code
            }
            else {
                requiredFields['package_name'] = ""
                requiredFields['package_id'] = ""
            }
            const data = {
                url: 'enquiry/',
                body: requiredFields
            }
            const newData = await apiPost(data)
            if ('status' in newData && newData.status === 201) {
                this.setState({
                    loading: false,
                    show: true
                })
            } else {
                alert('Something went wrong')
            }
            console.log('newData enuiry', newData)
        } else {
            this.setState({
                errors: errors,
                loading: false
            })
        }
    }
    render() {
        console.log('header props', this.props)
        const { langObj, selectedLang, emailSubject } = this.props.lang
        const language = selectedLang.toLowerCase()
        let packageDetail = ""
        if (this.props.cmp.selectedPackage.package_cmp !== "") {
            packageDetail = this.props.cmp.selectedPackage.package_cmp
        }
        var package_cmp = this.props.cmp.selectedPackage.package_cmp
        var packName = ''
        if (package_cmp) {
            // var packageCommonDetails = packageCmp.package[0]
            // imageUrl = 'package' in package_cmp ? package_cmp.package.length > 0 ?
            //     package_cmp.package[0].image : PackageImage : PackageImage
            packName = 'package' in package_cmp ? package_cmp.package.length > 0 ?
                package_cmp.package[0].name : 'Connections' : 'Connections'
            // price = 'package' in package_cmp ? package_cmp.package.length > 0 ?
            //     package_cmp.package[0].from_price : '' : ''
            // if ('package' in packageCmp) {
            //     var packageVars = packageCmp.package[0]

            // }
            // console.log('packageVars', packageVars)
        }
        const { keys, field_errors, errors, show, loading, showNavbar } = this.state
        return (<HeaderWrapper>
            <Link to={`/${language}`}>
                <LogoContainer>
                    <Logo
                        src={logo}
                        alt='Connections logo' />
                </LogoContainer>
            </Link>
            <div class="unsupported-browser"></div>
            <div className="nav-btn">
                <div className={this.state.showNavbar ? 'is-active' : 'hamburger'} id="hamburger-1"
                    onClick={this.showNavbar} for="nav-check">
                    <span className="line"></span>
                    <span className="line"></span>
                    <span className="line"></span>
                </div>
            </div>
            {/* mobile width content */}
            {showNavbar ? <ResponsiveSection>
                <Link to={`/${language}`}>
                    <SingleSection>
                        <Logo
                            src={logo}
                            className='app-logo'
                            alt='Connections logo' />
                    </SingleSection>
                </Link>
                <SingleSection className='my-4'>
                    <h5 className='primary-text font-md'>{langObj.dashboard_enquiry}</h5>
                    <p className="c-pointer text-underline" onClick={this.onOpenModal}>Click here</p>
                </SingleSection>
                <SingleSection className='my-4'>
                    <h5 className='primary-text font-md'>Call us</h5>
                    <p className=" font-weight-bold">+32 (0)70 23 33 13</p>
                </SingleSection>
                <SingleSection className='my-4'>
                    <h5 className='primary-text font-md'>E-mail</h5>
                    <p className="">contactcenter@connections.be</p>
                </SingleSection>
                <SingleSection className='my-5'>
                    <p className="grey-text my-5">Change language</p>
                    <LanguageContainer>
                        <SpanCopy className={selectedLang.toLowerCase() === 'nl' ? 'selected-lang' : ''}
                            onClick={() => this.chooseLanguage('nl')}>nl
                        <LanguageButton className={selectedLang.toLowerCase() === 'nl' ? 'selected-lang' : ''}></LanguageButton>
                        </SpanCopy>
                        <SpanCopy className={selectedLang.toLowerCase() === 'fr' ? 'selected-lang mx-3' : 'mx-3'}
                            onClick={() => this.chooseLanguage('fr')}>fr
                        <LanguageButton className={selectedLang.toLowerCase() === 'fr' ? 'selected-lang' : ''}></LanguageButton>
                        </SpanCopy>
                        <SpanCopy className={selectedLang.toLowerCase() === 'en' ? 'selected-lang' : ''}
                            onClick={() => this.chooseLanguage('en')}>en
                        <LanguageButton className={selectedLang.toLowerCase() === 'en' ? 'selected-lang' : ''}></LanguageButton>
                        </SpanCopy>
                    </LanguageContainer>
                </SingleSection>
            </ResponsiveSection> : ''}
            {/* mobile width content */}
            <HelpLanguageSection>
                <HelpSection>
                    <Label className="c-pointer" onClick={this.onOpenModal}>{langObj.dashboard_enquiry}</Label>
                </HelpSection>
                <LanguageSection>
                    <LanguageButton className={selectedLang.toLowerCase() === 'nl' ? 'selected-lang' : ''}
                        onClick={() => this.chooseLanguage('nl')}>
                        <Span className={selectedLang.toLowerCase() === 'nl' ? 'selected-lang' : ''}>nl</Span>
                    </LanguageButton>
                    <LanguageButton className={selectedLang.toLowerCase() === 'fr' ? 'selected-lang' : ''}
                        onClick={() => this.chooseLanguage('fr')}>
                        <Span className={selectedLang.toLowerCase() === 'fr' ? 'selected-lang' : ''}>fr</Span>
                    </LanguageButton>
                    <LanguageButton className={selectedLang.toLowerCase() === 'en' ? 'selected-lang' : ''}
                        onClick={() => this.chooseLanguage('en')}>
                        <Span className={selectedLang.toLowerCase() === 'en' ? 'selected-lang' : ''}>en</Span>
                    </LanguageButton>
                </LanguageSection>
            </HelpLanguageSection>
            <Modal
                classNames={{
                    overlay: 'modal-overlay',
                    modal: 'modal-container',
                    closeButton: 'modal-close-btn model-close-btn'
                }}
                open={this.state.open}
                onClose={this.onCloseModal}
                center>
                {!show ? <EnquiryContainer>
                    <Text className='text-light '>{langObj.enquiry_intro}</Text>
                    <h3 className='text-light '>{langObj.enquiry_title}</h3>
                    <Text className='text-light my-2'>
                        {langObj.enquiry_description.replace(/XXX|XXXXXX/gi, packName)}
                        {/* You have a question about our <b className='text-capitalize'>{packName}</b> package? Since we love to help You
                 out on a personal level, fill out the below form and we'll contact you. */}
                    </Text>
                    <HelpUsFrom className='my-3'>
                        <FlexWrapper>
                            <InputFiled>
                                <h6 className='text-light m-0'>{langObj.enquiry_first_name}</h6>
                                <Input
                                    label={langObj.enquiry_first_name}
                                    name='first_name'
                                    type='text'
                                    inputChange={this.onInputChange}
                                    val={keys.first_name}
                                    classname='w-100' />
                                {errors.first_name && (
                                    <span className={`${keys.first_name.length > 0 ? 'd-none' : 'error'}`}>{errors.first_name}</span>
                                )}
                            </InputFiled>
                            <InputFiled>
                                <h6 className='text-light m-0'>{langObj.enquiry_last_name}</h6>
                                <Input
                                    name='last_name'
                                    inputChange={this.onInputChange}
                                    val={keys.last_name}
                                    label={langObj.enquiry_last_name}
                                    type='text'
                                    classname='w-100' />
                                {errors.last_name && (
                                    <span className={`${keys.last_name.length > 0 ? 'd-none' : 'error'}`}>{errors.last_name}</span>
                                )}
                            </InputFiled>
                        </FlexWrapper>
                        <FlexWrapper>
                            <InputFiled>
                                <h6 className='text-light m-0'>{langObj.enquiry_email}</h6>
                                <Input
                                    inputChange={this.onInputChange}
                                    val={keys.email}
                                    name='email'
                                    type='email'
                                    label={langObj.enquiry_email}
                                    classname='w-100' />
                                {errors.email && (
                                    <span className={`${keys.email.length > 0 ? 'd-none' : 'error'}`}>{errors.email}</span>
                                )}
                                {field_errors.email && (
                                    <span className="error mx-2">{field_errors.email}</span>
                                )}
                            </InputFiled>
                            <InputFiled>
                                <h6 className='text-light m-0'>{langObj.enquiry_ph_number}</h6>
                                <Input
                                    inputChange={this.onInputChange}
                                    val={keys.telephone}
                                    name='telephone'
                                    type='number'
                                    label={langObj.enquiry_ph_number}
                                    classname='w-100' />
                                {errors.telephone && (
                                    <span className={`${keys.telephone.length > 0 ? 'd-none' : 'error'}`}>{errors.telephone}</span>
                                )}
                                {field_errors.telephone && (
                                    <span className="error mx-2">{field_errors.telephone}</span>
                                )}
                            </InputFiled>
                        </FlexWrapper>
                        <FlexWrapper>
                            <InputFiled className='w-100'>
                                <h6 className='text-light m-0 mb-2'>{langObj.enquiry_content}(not mandatory)</h6>
                                <Textarea
                                    inputChange={this.onInputChange}
                                    val={keys.remarks}
                                    name='remarks'
                                    label={langObj.more_4_pass_desp}
                                    classname='w-100 m-0' />
                            </InputFiled>
                        </FlexWrapper>
                        <ButtonWrapper>
                            {loading ? <Loader /> : <Button
                                submit={() => this.sendRequest(language, emailSubject, packageDetail)}
                                righticon='fa fa-chevron-right'
                                classname="button-primary"
                                name={langObj.send_this_request} />}
                        </ButtonWrapper>
                    </HelpUsFrom>
                </EnquiryContainer> :
                    <EnquirySuccsess className='mt-5'>
                        <InputFiled className='w-100 text-light'>
                            <h5 className='w-100 text-light'>{langObj.response_title}</h5>
                            <p className='w-100 text-light'>{langObj.response_desp1}</p>
                        </InputFiled>
                        <InputFiled className='w-100 text-light'>
                            <h5 className='w-100 text-light'>{langObj.response_desp2}</h5>
                            <p className='w-100 text-light'>{langObj.response_desp3}</p>
                        </InputFiled>
                        <InputFiled className='w-100 text-light'>
                            <Button submit={this.onCloseModal}
                                name={langObj.close} classname='button-close my-3' />
                        </InputFiled>
                    </EnquirySuccsess>}
            </Modal>
        </HeaderWrapper>)
    }
}

const mapStateToProps = (state) => ({
    cmp: state.calc,
    lang: state.language
})

const mapDispatchToProps = {

}

export default withRouter(connect(mapStateToProps, { chooseLang, getPackages, changeStepOne })(Header))
