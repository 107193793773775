import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
// import * as linkify from 'linkifyjs';;
// import linkifyHtml from 'linkifyjs/html';
import ShowMoreText from "react-show-more-text";
import ReadMoreAndLess from "react-read-more-less";
import Linkify from "react-linkify";

import { Colors } from "../../library/colors";
import Checkbox from "../Checkbox";

const Container = styled.div`
  display: flex;
  margin: 20px 0px;
  justify-content: space-between;
  background: #fff;
  box-shadow: ${(props) =>
    props.bg ? "2px 2px 11px 0px #d3d3d3ab" : "0px 0px 0px 0px transparent"};
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;
const InformationSection = styled.div`
  padding: 20px;
`;
const CheckboxWrapper = styled.div`
  display: flex;
`;
const DetailSection = styled.div`
  margin-left: 20px;
`;
const H3Text = styled.h5`
  font-size: 16px;
`;
const H3 = styled.h5`
  font-size: 18px;
  font-weight: bold;
`;
const ImageSection = styled.div`
  // width:200px;
  // height:150px;
`;
const TourSection = styled.div`
  background: ${Colors.primaryColor};
  padding: 5px 10px 1px 10px;
`;
const Image = styled.img`
  @media only screen and (max-width: 600px) {
    width: 100%;
    height: auto;
  }
`;
var linkify = require("linkifyjs");
var linkifyHtml = require("linkifyjs/html");
const componentDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank">
    {text}
  </a>
);
export class index extends Component {
  executeOnClick(isExpanded) {
    console.log(isExpanded);
  }
  state = {
    charLimit: 150,
  };

  readMore = () => {
    this.setState({
      charLimit: -1,
    });
  };

  hideText = () => {
    this.setState({
      charLimit: 150,
    });
  };

  isValidURL = (string) => {
    var res = string.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    return res !== null;
  };
  render() {
    const { charLimit } = this.state;
    const { langObj } = this.props.lang;
    var texts = linkifyHtml(this.props.content);
    return (
      <Container bg={this.props.check}>
        <InformationSection>
          <CheckboxWrapper>
            <div className="checkbox-group">
              <input
                type="checkbox"
                onChange={this.props.change}
                // value={this.props.val}
                disabled={this.props.disable}
                name={this.props.name}
                checked={this.props.check}
                id={this.props.name}
              />
              <label id="check-box-id" htmlFor={this.props.name}></label>
            </div>
            <DetailSection>
              <H3>{this.props.heading}</H3>
              {/* {this.isValidURL(this.props.content)?
                       <a href={this.props.content} className='my-2 word-break'
                       target='_blank'>{this.props.content}</a>: */}
              {/* <ReadMoreAndLess
                            // ref={this.ReadMore}
                            className="read-more-content"
                            charLimit={140}
                            readMoreText="+  Read more"
                            readLessText="-  Hide"
                        > */}
              {this.props.content.length > 150 ? (
                <p className="word-break">
                  <Linkify
                    properties={{ target: "_blank" }}
                    componentDecorator={componentDecorator}
                  >
                    {this.props.content.slice(0, charLimit)}
                    {charLimit === -1 ? null : <span>...</span>}
                    {charLimit !== 150 ? (
                      <span
                        className="c-pointer readMoreText"
                        onClick={this.hideText}
                      >
                        {" "}
                        -{" "}
                        <span className="text-underline">
                          {langObj.step_4_readless}
                        </span>
                      </span>
                    ) : (
                      <span
                        className="c-pointer readMoreText"
                        onClick={this.readMore}
                      >
                        +{" "}
                        <span className="text-underline">
                          {langObj.step_4_readmore}
                        </span>
                      </span>
                    )}
                  </Linkify>
                </p>
              ) : (
                <p className="word-break">
                  <Linkify
                    properties={{ target: "_blank" }}
                    componentDecorator={componentDecorator}
                  >
                    {this.props.content}
                  </Linkify>
                </p>
              )}
              {/* </ReadMoreAndLess> */}
            </DetailSection>
          </CheckboxWrapper>
          {this.props.children}

          {/* <H3 className='primary-text'>{this.props.text}</H3> */}
        </InformationSection>
        {this.props.img ? (
          <ImageSection>
            <Image
              src={this.props.img}
              width="242px"
              height="150px"
              alt="image alt foto"
            />
            <TourSection>
              <h5 className="text-light">
                {" "}
                <i class="fa fa-calendar text-light"></i> {this.props.duration}{" "}
                {langObj.days} {langObj.component_3}
              </h5>
            </TourSection>
          </ImageSection>
        ) : (
          ""
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.language,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(index);
