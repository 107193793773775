import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import moment from 'moment/min/moment-with-locales';

import PackageImg from '../../assets/images/package-card.png'
import { Colors } from '../../library/colors'
import PackageLabel from '../PackageLabel'
import TravelIcon from '../../assets/images/ICON-TRAVELLERS.png'
import CalendarIcon from '../../assets/images/Icon-kalender.png'
import PlaneIcon from '../../assets/images/PLANE.png'
import HotelIcon from '../../assets/images/HOTEL-ICON.png'
import TourIcon from '../../assets/images/TOUR-ICON.png'
import ExtraIcon from '../../assets/images/EXTRA-ICON.png'
import ExcursionIcon from '../../assets/images/EXCURSION-ICON.png'

//component styles starts //

const PackageCardWrapper = styled.div`
box-shadow: 1px 1px 11px 0px #d3d3d3ab;
`
const H3 = styled.h4`
background-image: linear-gradient(#1a2e6a, #1f3688);
color:#ffffff;
font-size:20px;
padding: 8px 2rem;
font-weight: 500;
@media only screen and (max-width: 768px) {
  display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px;
}
`
const PackageImage = styled.div`
height:125px;
// width:100%;
background: url(${props => props.img ? props.img : ''});
    // height: 125px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`
const PackageDetails = styled.div`
padding:1.5rem;
`
const H4 = styled.h4`
margin-bottom:10px;
`
const HighlightText = styled.p`
font-size:14px;
text-transform:capitalize;
font-weight:600;
color:${Colors.primaryText};
margin: 0.3rem 0px;
`
const NormalText = styled.span`
font-size:14px;
font-weight:100;
color:${Colors.primaryText};
`
const LinkText = styled.span`
font-size:14px;
font-weight:100;
color:${Colors.primaryColor};
`
const PriceSection = styled.div`
margin:0px 1.5rem;
padding:1.5rem 0;
border-top: 1px solid #d3d3d3a3;
`
const P = styled.p`
color:${Colors.primaryText};
font-size:12px;
margin: 10px 0px;
`
const ShowHideIcon=styled.span`
font-size: 14px;
display:none;
@media only screen and (max-width: 768px) {
  display:block; 
}
`
const PackageCard=styled.div`
height:auto;
transition: height 2s;
@media only screen and (max-width: 768px) {
  height:${props=>props.show?'auto':0}; 
  opacity:${props=>props.show?1:0};
  display:${props=>props.show?'block':'none'};
  transition: height 2s;
}
`
//component styles ends//

export class index extends Component {
  state={
    show:false
  }

  showPackage=()=>{
    this.setState({
      show:!this.state.show
    })
  }
  render() {
    const {show}=this.state
    var adults = 0
    var childs = 0
    var totalTravelers = []
    var imageUrl = ''
    console.log('package summay', this.props)
    const {langObj,selectedLang}=this.props.lang
    console.log(langObj,"hdkjfghkjdshkjfghdfkjshgkjdfhkghvdkjfhgkjdfhgjhdfkjghkjdfhgkjdfhgkjdfhgkj")
    const languagess=selectedLang.toUpperCase()
        const dateFormat=languagess==='EN'?"MMMM Do YYYY":"Do MMMM YYYY"
    const { package_cmp, packageObj, extrasPackage, child, adult, selectedPackage } = this.props.cmp
    const { flight_status } = selectedPackage.package_cmp.package[0]
    if (Object.keys(packageObj).length > 0) {
      totalTravelers = packageObj.qty.split(" ").filter((x, i) => !isNaN(x))
      adults = totalTravelers.length > 0 ? totalTravelers[0] : 0
      childs = totalTravelers.length > 1 ? totalTravelers[1] : 0
      imageUrl = 'package' in package_cmp ? package_cmp.package.length > 0 ?
        package_cmp.package[0].image : '' : ''
    } else {
    }
    var selLang=this.props.lang.selectedLang.toLowerCase()
    console.log('selLang',selLang)
    moment.locale(selLang);
    
    return (Object.keys(packageObj).length > 0 ? <PackageCardWrapper>
      <H3 className='mb-0'>{langObj.step_1_pack} <ShowHideIcon onClick={this.showPackage}
      className="show-hide-icon">{show?'Close':'View'} 
      <i class={show?"fa fa-chevron-down mx-1":"fa fa-chevron-up mx-1"}></i></ShowHideIcon></H3>
      <PackageCard show={show}>
      <PackageImage img={imageUrl}>
      </PackageImage>
      {/* <PackageImage src={imageUrl} alt="package imag" /> */}
      <PackageDetails>
        <H4 className='text-capitalize'>{packageObj.package_name}</H4>
        <PackageLabel img={TravelIcon} heading={langObj.travelers}>
          <HighlightText>{packageObj.qty_number[0]} {packageObj.qty_number[0]>1?langObj.adults:langObj.adult}{packageObj.qty_number.length>1?`, ${packageObj.qty_number[1]} ${packageObj.qty_number[1]>1?langObj.children:langObj.child}`:''}</HighlightText>
        </PackageLabel>
        <PackageLabel img={CalendarIcon} heading={langObj.date}>
          <HighlightText>{moment(new Date(packageObj.start_date)).format(dateFormat).replace(/ste|de/,"")}</HighlightText>
          <HighlightText><span class="font-weight-normal">{packageObj.duration_number[0]} {langObj.days} - {packageObj.duration_number[1]} {langObj.nights}</span></HighlightText>
          <HighlightText>
{moment(new Date(packageObj.end_date)).format(dateFormat).replace(/ste|de/,"")}</HighlightText>
        </PackageLabel>
        {'airline' in extrasPackage ? extrasPackage.airline.length > 0 ?
          extrasPackage.airline.map((x, i) => {
            const name = extrasPackage.airline[0].name
            if (x.checked === true) {
              return <div>
                <PackageLabel img={PlaneIcon} heading={`${x.flight_status} - ${x.name}`}>
                  <HighlightText>{name}</HighlightText>
                  <NormalText className='d-block'>
                    {`${package_cmp.package[0].luggage_included ? package_cmp.package[0].luggage_qty + ` kg of ${langObj.luggage}` : ''}`}</NormalText>
                  <LinkText>{'price' in x ? `+ ${packageObj.total_qty}x € ${x.price}` : langObj.step_3_avail}</LinkText>
                </PackageLabel>
              </div>
            }
          })
          : '' : ''}
        {'hotel' in extrasPackage ? extrasPackage.hotel.length > 0 ?
          extrasPackage.hotel.map((x, i) => {
            if (x.checked === true) {
              return <PackageLabel img={HotelIcon} heading={x.name}>
                <div>
                  {/* <HighlightText>{x.name}</HighlightText> */}
                  {/* <NormalText className='d-block'>{x.description}</NormalText> */}
                  <LinkText>{x.key === 0 ? langObj.step_3_avail : ` + ${packageObj.total_qty} x  €  ${x.price}/pp`}</LinkText>
                </div>
              </PackageLabel>

            }
          })
          : '' : ''}
        {'tour' in extrasPackage ? extrasPackage.tour.length > 0 ?
          <React.Fragment>
            {extrasPackage.tour.some((x) => x.checked === true) ? <PackageLabel img={TourIcon} heading={langObj.component_3}>
              {extrasPackage.tour.map((x, i) => {
                if (x.checked === true) {
                  return <div>
                    <HighlightText>{x.name}</HighlightText>
                    <LinkText>{x.key === 0 ? langObj.step_3_avail : ` + ${langObj.from_price}, ${langObj.later_to_pay}`}</LinkText>
                  </div>
                }
              })}
            </PackageLabel> : ""}
          </React.Fragment>
          : '' : ''}

        {'excursion' in extrasPackage ? extrasPackage.excursion.length > 0 ?
          <React.Fragment>
            {extrasPackage.excursion.some((x) => x.checked === true) ? <PackageLabel img={ExcursionIcon} heading={langObj.component_extra}>
              {extrasPackage.excursion.map((x, i) => {
                if (x.checked === true) {
                  return <div>
                    <HighlightText>{x.name}</HighlightText>
                    <LinkText>{x.key === 0 ? langObj.step_3_avail : ` +  
                    ${adult.length} x € ${x.adult_price}/${langObj.adult} ${child.length > 0 ? `+ ${child.length} x € ${x.child_price?x.child_price:x.adult_price}/${langObj.child}` : ''}`}</LinkText>
                  </div>
                }
              })}
            </PackageLabel> : ""}
          </React.Fragment>
          : '' : ''}
        {'car_rental' in extrasPackage ? extrasPackage.car_rental.length > 0 ?
          <React.Fragment>
            {extrasPackage.car_rental.some((x) => x.checked === true) ? <PackageLabel img={ExtraIcon} heading={langObj.component_6}>
              {extrasPackage.car_rental.map((x, i) => {
                if (x.checked === true) {
                  return <div>
                    <HighlightText>{x.name}</HighlightText>
                    <LinkText>{x.key === 0 ? langObj.step_3_avail : `${langObj.dashboard_from} € ${x.price} ${langObj.per_day}`}</LinkText>
                    {x.key === 0 ? '' : <LinkText className='d-block'>{langObj.step_4_further_details}</LinkText>}
                  </div>
                }
              })}
            </PackageLabel> : ''}
          </React.Fragment>
          : '' : ''}

        {'transfer' in extrasPackage ? extrasPackage.transfer.length > 0 ?
          <React.Fragment>
            {extrasPackage.transfer.some((x) => x.checked === true) ? <PackageLabel img={ExtraIcon} heading={langObj.component_5}>
              {extrasPackage.transfer.map((x, i) => {
                if (x.checked === true) {
                  return <div>
                    <HighlightText>{x.name}</HighlightText>
                    <LinkText>{x.key === 0 ? langObj.step_3_avail : `${packageObj.total_qty} x € ${x.price}`}</LinkText>
                  </div>
                }
              })}
            </PackageLabel> : ""}
          </React.Fragment>
          : '' : ''}

        {'added_value' in extrasPackage ? extrasPackage.added_value.length > 0 ?
          <React.Fragment>
            {extrasPackage.added_value.some((x) => x.checked === true) ? <PackageLabel img={ExtraIcon} heading={langObj.component_7}>
              {extrasPackage.added_value.map((x, i) => {
                if (x.checked === true) {
                  return <div>
                    <HighlightText>{x.name}</HighlightText>
                    <LinkText>{x.key === 0 ? langObj.step_3_avail : `€ ${x.fixed_price}`}</LinkText>
                  </div>
                }
              })}
            </PackageLabel> : ""}
          </React.Fragment>
          : '' : ''}
        {/* <PackageLabel img={ExtraIcon} heading="Car Rental">
                    <HighlightText>Standard-Size</HighlightText>
                    <LinkText>Included in Package</LinkText>
                </PackageLabel> */}
      </PackageDetails>
      <PriceSection>
        <h4>{langObj.step_0_price} <span className='font-sm mx-1'> € </span> {packageObj.final_price}</h4>
              <P>{langObj.step_0_note2}</P>
      </PriceSection>
      </PackageCard>
    </PackageCardWrapper> : '')
  }
}

const mapStateToProps = (state) => ({
  cmp: state.calc,
  lang:state.language
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(index)
