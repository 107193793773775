import React, { Component } from 'react'
import {withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import ConfirmedImg from '../../assets/images/CONFIRMED.png'
import Status from '../Status'
import { apiPost } from '../../library/'
import PendingImg from '../../assets/images/PENDING.png'
import ErrorImg from '../../assets/images/ERROR.png'
import Loader from '../Loader'
import {chooseLang} from '../Header/action'
const H4 = styled.h4`
color: #223E92;
font-size:26px;`

const H6 = styled.h6`
font-size:21px;
margin:1rem 0px;
font-weight:normal;
color:#363636;
text-align: center;
`
const PaymentMessage = styled.div`
font-size:16px;
margin:2rem;
`
const LoadingWrapper = styled.div`
display:flex;
align-items:center;
justify-content:center;
min-height:80vh;
`
export class index extends Component {
    state = {
        id: '',
        refid: '',
        status: '',
        loading: true,
        url: ''
    }
    componentDidMount() {
        if (this.props.match.params && this.props.match.params.lang) {
            console.log('this.props home',this.props) 
            this.changeLang(this.props.match.params.lang)
         }else{
             console.log('no paras',this.props)
         }
        const id = localStorage.getItem('id')
        const refid = localStorage.getItem('refid')
        this.setState({
            id: id,
            refid: refid,
            loading: true
        }, () => {
            this.getPayment(id)
        })

    }

    changeLang =async (lang) => {
        console.log('language changing.....')
        const obj={
            url:'translation/',
            body:{
                text_language:lang.toUpperCase()
            }
        }
        const newLng=await apiPost(obj)
        const newObj={obj:newLng[0],lang:lang}
        this.props.chooseLang(newObj)
        // this.props.history.push('/')
        console.log('neeLang Data',newLng)
    } 

    getPayment = async (id) => {

        const items = JSON.parse(JSON.stringify(localStorage.getItem('persist:form')));
        const userquery = window.location.search;
        const urlParam = new URLSearchParams(userquery)
        const payId = urlParam.get('hostedCheckoutId')
        const pack = urlParam.get('pack')
        var url = ''
        const data = {
            url: "getpayment/",
            body: {
                id: payId
            }
        }
        const newData = await apiPost(data)
        console.log(newData)
        // if(newData.data.status==='paid'){
        //     this.sendEmail()
        // }
        if(newData['data']['status']==='SUCCESSFUL'){
            url = " "
            this.sendEmail()
        }
        else{
            url = `package-detail/${pack}?step=4`
        }
        // console.log('payment status', newData['data']['status'])
        this.setState({
            status: newData['data']['status'],
            url: url,
            loading: false
        })
    }

    sendEmail=async()=>{
        const data = {
            url: "confirmationmail/",
            body: {
                ref_id: this.state.refid
            }
        }
        const newData = await apiPost(data)
        console.log('email status', newData)
    }
    render() {
        const { status, id, loading, url } = this.state
        const {langObj,selectedLang}=this.props.lang
        const language=selectedLang.toLowerCase()
     return (loading ? <LoadingWrapper>
            <div className='text-center'>
                <Loader />
                <h6 className='loader-bold-text'>Just a second</h6>
     <p className='loader-text'>{langObj.loading_your_request}</p>
            </div>
        </LoadingWrapper> : <React.Fragment>
                {status === 'SUCCESSFUL' ? <Status img={ConfirmedImg} buttonName={langObj.c_button} result="SUCCESSFUL" destination_url={url}>
     <H4>{langObj.c_booking}</H4>
                    <H6>{langObj.ref} <b className='text-uppercase'>{this.state.refid}</b></H6>
     <PaymentMessage className='margin-y'>{langObj.c_details}</PaymentMessage>
                </Status> : ''}
                {/* {status === 'open' ? <Status img={PendingImg} buttonName='Back to connections.be'>
                    <H4>{langObj.p_booking} </H4>
                    <H6>{langObj.ref} <b>{id}</b> </H6>
     <PaymentMessage className='margin-y'>{langObj.p_details}</PaymentMessage>
                </Status> : ''} */}
                {status === 'REJECTED' || status === 'CANCELLED_BY_CONSUMER' ? <Status img={ErrorImg} buttonName={langObj.f_button} result="REJECTED" destination_url={url}>
     <H4>{langObj.f_booking}</H4>
                    <H6>{langObj.f_message}</H6>
     <PaymentMessage className='margin-y'>{langObj.f_details}</PaymentMessage>
                </Status> : ''}
            </React.Fragment>)
    }
}

const mapStateToProps = (state) => ({
lang:state.language
})

const mapDispatchToProps = {

}

export default withRouter(connect(mapStateToProps, {chooseLang})(index))







