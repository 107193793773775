import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Link, withRouter } from 'react-router-dom'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import _ from 'lodash'

import Input from '../Input'
import Dropdown from '../Dropdown'
import { Colors } from '../../library/colors'
import PackageImage from '../../assets/images/package-detailimg.png'
import PackageLabel from '../PackageLabel'
import TravelIcon from '../../assets/images/ICON-TRAVELLERS.png'
import CalendarIcon from '../../assets/images/Icon-kalender.png'
import PlaneIcon from '../../assets/images/PLANE.png'
import HotelIcon from '../../assets/images/HOTEL-ICON.png'
import Textarea from '../TextArea'
import TourIcon from '../../assets/images/TOUR-ICON.png'
import ExtraIcon from '../../assets/images/EXTRA-ICON.png'
import ExcursionIcon from '../../assets/images/EXCURSION-ICON.png'
import LaterIcon from '../../assets/images/later-icon.png'
import EarlierIcon from '../../assets/images/earlier-icon.png'
import {selectPackage,packageCmp} from './actions'
import { copyObj, apiPost, validEmailRegex, validateInputs, getMonthName } from '../../library'

import Button from '../Button'
import Table from '../Table'

const PageWrapper = styled.div`
display:flex;
justify-content:space-between;
`
const ImageContainer = styled.div`
width:32%;
height:602px;
background-image:url(${props => props.img ? props.img : PackageImage});
background-position: center; 
background-repeat: no-repeat; 
background-size: cover; 
position:relative;
`
const StepZeroContainer = styled.div`
width:63%;
`
const PackageName = styled.div`

`
const StepZeroH3 = styled.h3`
font-size:20px;
margin:8px 0px;
color:${Colors.primaryColor};
`
const StepZeroH4 = styled.h4`
font-size:20px;
color:${Colors.primaryColor};
`
const StepZeroH6 = styled.h6`
font-size:20px;
font-weight:normal;
color:${Colors.primaryText};
`
const StepZeroHighlightText = styled.p`
font-size:16px;
font-weight:600;
color:${Colors.primaryText};
margin: 0.1rem 0px;
`
const StepZeroStepZeroHighlightText = styled.span`
font-size:16px;
font-weight:100;
color:${Colors.primaryText};
`
const IncludedPackage = styled.div`
margin:10px 0px;
`
const FlexWrapper = styled.div`
display:flex;
justify-content:space-between;
`
const PackageSections = styled.div`
width:40%;
`
const LinkText = styled.p`
font-size:12px;
font-weight:100;
color:${Colors.primaryText};
`
const StepZeroStepZeroSeparator = styled.br`
`
const SummaryContainer = styled.div`
margin:1.5rem 0px;

`
const StepZeroStepZeroHR = styled.hr`
width: 100%;
margin: 30px 0px;
border: 1px solid #EBEBEB;
`
const SummaryPackageDetail = styled.div`
display:flex;
align-items:center;
margin: 10px;
justify-content:space-between;
`
const TableWrapper = styled.div`
margin:20px 0px;
`
const DateSection = styled.div`
display:flex;
align-items:center;
width:40%;
justify-content:space-between;
`
const Icon = styled.img`
height:100%;
`
const StepZeroStepZeroFloatingContainer = styled.div`
display:flex;
align-items:center;
`
const EnquiryContainer = styled.div`
margin:20px 0px;
background:transparent;
`
const EnquirySuccsess = styled.div`
margin:20px 0px;
background:transparent;
`
const HelpUsFrom = styled.div`

`
const Text = styled.div`
font-size:18px;
color:grey;
`
// const FlexWrapper = styled.div`
// display:flex;
// // align-items:center;
// justify-content:space-between;
// `
const InputFiled = styled.div`
margin:15px 0px;
width:47%;
flex-wrap:wrap;
`
const ButtonWrapper = styled.div`
display:flex;
align-items:center;
justify-content:flex-end;
`
const DropdownGroup = styled.div`
display:flex;
align-items:center;
`
const Note = styled.p`
font-size:12px;
color:gray;
text-align:right;
`
const Name = styled.h4`
background:${Colors.secondaryText};
color:#ffffff;
font-size:22px;
padding:8px 1rem;
position:absolute;
bottom:140px;
left:0;
// margin-top:-3px;
`
const PriceTag = styled.h5`
padding:8px 1rem;
color:#ffffff;
position:absolute;
bottom:100px;
left:0;
background:${Colors.primaryColor};
`

const options = [
    { value: '2 Adults, 1 Child', label: '2 Adults, 1 Child' },
];

const stayOptions = [{ label: '5 days - 4 nights', value: '5days4nights' },
{ label: '6 days - 5 nights', value: '6days5nights' },
{ label: '9 days - 8 nights', value: '9days8nights' }]

const numbers = [{ label: '0', value: '0' }, { label: '1', value: '1' }, { label: '2', value: '2' }, { label: '3', value: '3' },
{ label: '4', value: '4' }, { label: '5', value: '5' }, { label: '6', value: '6' },
{ label: '7', value: '7' }, { label: '8', value: '8' }, { label: '9', value: '9' }, { label: '10', value: '10' }]
export class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showTable: false,
            showSummary: false,
            index: '',
            show: false,
            data: '',
            rowDatas: {},
            rows: [],
            col: [],
            travelers: '',
            depatureMonth: '',
            price:'',
            date:'',
            duration:'',
            morePassengers: {
                adult_qty: '',
                child_qty: '',
                infant_qty: '',
                day: '',
                month: '',
                year: '',
                length_of_stay: '',
                first_name: '',
                last_name: '',
                email: '',
                telephone: '',
                remarks: '',
                package_code: ''
            },
            child_qty: '',
            adult_qty: '',
            infant_qty: '',
            length_of_stay: '',
            year: '',
            day: '',
            month: '',
            selectMonth: false,
            keys: {
                des_name: [],
                order_name: '',
                order_price: '',
                text_language: 'EN',
                package_code: '',
                detail: ''
            },
            loading: false,
            package_detail: [],
            package_cmp: {},
            errors: {},
            field_errors: {},
            yearOptions: [],
            dateOptions: [],
            monthOptions: [],
            travelOptions: [],
            monOptions: [],
            packageSummary:{}
        }
    }


    componentDidMount = async () => {
        const year = (new Date()).getFullYear();
        const years = Array.from(new Array(20), (val, index) => { return { 'label': index + year, value: index + year } });
        const months = Array.from(new Array(12), (val, index) => { return { 'label': index + 1, value: index + 1 } });
        const dates = Array.from(new Array(31), (val, index) => { return { 'label': index + 1, value: index + 1 } });
        this.setState({
            yearOptions: years,
            monthOptions: months,
            dateOptions: dates
        })
        console.log('packegedetails', this.props, years)
        if (this.props.match.params && this.props.match.params.id) {
            this.setState({
                loading: true
            })
            const newKeys = copyObj(this.state.keys)
            newKeys['package_code'] = this.props.match.params.id
            const data = {
                url: 'user_pack_view/',
                body: newKeys
            }
            const newData = await apiPost(data)
            this.props.packageCmp(newData)
            const newTravel = newData.qty.map((x, i) => { return { label: x, value: x } })
            const newMonths = newData.period.map((x, i) => { return { label: x, value: x } })
            console.log('selected package newData', newData)
            this.setState({
                package_cmp: newData,
                travelOptions: newTravel,
                monOptions: newMonths,
                csv_file: newData.package[0].csv_file,
                morePassengers: { ...this.state.morePassengers, package_code: newData.package[0].package_code }
                // package_detail: newData.package
            })
            console.log('selected packegedetails', newData)
        }
    }

    // onInputChnage
    stepZeroIpChange = (e) => {
        const { name } = e.target
        var value = e.target.value
        let errVal;
        switch (name) {
            case 'email':
                errVal = !validEmailRegex.test(value) ? 'Email is not Valid' : ''
                break;
            case 'telephone':
                value = value.toString().slice(0, 10);
                errVal = value.length === 10 ? '' : 'Mobile number is not valid';
                parseInt(value);
                break;
            default:
        }
        this.setState({
            field_errors: {
                ...this.state.field_errors,
                [name]: errVal !== 'undefined' ? errVal : '',
            },
        });
        this.setState({
            morePassengers: { ...this.state.morePassengers, [name]: value }
        })
    }


    onOpenModal = () => {
        const sampleKeys = {
            first_name: '',
            last_name: '',
            email: '',
            telephone: '',
            remarks: '',
            adult_qty: '',
            child_qty: '',
            infant_qty: '',
            day: '',
            month: '',
            year: '',
            length_of_stay: '',
        }
        this.setState({ open: true, show: false, keys: sampleKeys });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    // choose Pricing
    selectPrice = (index, data,price,date,duration) => {
        var d = new Date()
        var year = d.getFullYear()
        var month = getMonthName(d.getMonth())
        var finalDate =this.state.depatureMonth?this.state.depatureMonth.value:( month + ' ' + year)
        this.setState({
            index: index,
            price:price,
            data: data
        })
        const {package_cmp}=this.state
        const packName = 'package' in package_cmp ? package_cmp.package.length > 0 ?
            package_cmp.package[0].name : '' : ''
      const obj={
          package_name:packName,
          package_code:this.props.match.params.id,
          amount:price,
          period:finalDate,
          qty:this.state.travelers.value,
          duration:duration.split("-")[0]+' days - '+duration.split("-")[1]+' nights',
          date:date
      } 
      this.showPackageSummary(obj)
        console.log('table', index, data,price,date,duration,this.state.travelers,this.state.depatureMonth)
    }

    // packageSummary
showPackageSummary=async(data)=>{
const dataObj={
    url:'calculation/',
    body:data
}
const newData=await apiPost(dataObj)
this.props.selectPackage(newData.step_0)
this.setState({
    showSummary:true,
    packageSummary:newData.step_0
})
console.log('tableNewData',newData)
}

    // // selectMonth
    // selectMonth = () => {
    //     this.setState({
    //         showMonth: true
    //     })
    // }
    // // select No of Days
    // selectTravelers = () => {
    //     this.setState({
    //         showTable: true
    //     })
    // }


    // select Number of travelers
    selectNumOfTravelers = async (val) => {
        this.setState({
            travelers: val,
            showSummary:false,
            index:'',
            data:''
        }, async () => {
            var d = new Date()
            var year = d.getFullYear()
            var month = getMonthName(d.getMonth())
            var finalDate =this.state.depatureMonth?this.state.depatureMonth.value:( month + ' ' + year)
            var obj = {
                csv_file: this.state.csv_file,
                period: finalDate,
                qty: val.value
            }
            const data = {
                url: 'csvdata/',
                body: obj
            }
            const newData = await apiPost(data)
            this.conversion(newData)
        })

        // console.log('final date',newObj,dates,days)
    }

    //selectDepatureDate
    selectDepatureDate = async (val) => {
        console.log('selectinfg month',val)
        this.setState({
            depatureMonth: val,
            showSummary:false,
            index:'',
            data:''
        }, async () => {
            var obj = {
                csv_file: this.state.csv_file,
                period: val.value,
                qty: this.state.travelers.value
            }
            const data = {
                url: 'csvdata/',
                body: obj
            }
            const newData = await apiPost(data)
            this.conversion(newData)
        })

    }

    // csv data conversion
    conversion = (data) => {
        let sample=[{"departure_date":"12 Apr","duration_days":"7","duration_nights":"6","amount":"169"},{"departure_date":"12 Apr","duration_days":"9","duration_nights":"8","amount":"473"},{"departure_date":"13 Apr","duration_days":"5","duration_nights":"4","amount":"854"},{"departure_date":"13 Apr","duration_days":"7","duration_nights":"6","amount":"169"},{"departure_date":"13 Apr","duration_days":"9","duration_nights":"8","amount":"473"},{"departure_date":"15 Apr","duration_days":"5","duration_nights":"4","amount":"854"},{"departure_date":"15 Apr","duration_days":"9","duration_nights":"8","amount":"473"},{"departure_date":"16 Apr","duration_days":"5","duration_nights":"4","amount":"854"},{"departure_date":"16 Apr","duration_days":"7","duration_nights":"6","amount":"169"},{"departure_date":"16 Apr","duration_days":"9","duration_nights":"8","amount":"473"},{"departure_date":"22 Apr","duration_days":"5","duration_nights":"4","amount":"854"},{"departure_date":"22 Apr","duration_days":"7","duration_nights":"6","amount":"169"},{"departure_date":"22 Apr","duration_days":"9","duration_nights":"8","amount":"473"}]
        let obj = this.csvDataConversion(data.data)
        let days = data.data.map((x) => { return x.duration_days + -+x.duration_nights })
        days = [...new Set(days)]
        let dates = data.data.map((x) => { return x.departure_date })
        dates = [...new Set(dates)]
        this.setState({
            rows: days,
            col: dates,
            rowDatas: obj,
            showTable: true
        })
        console.log('formatted datas', obj, days, dates)
    }

    // csv data format
    csvDataConversion = (data) => {
        // var rowsCount = 0;
        // var colsCount = 0;
        var hash = {};
        var durationDayHash = {};
        for (var i = 0; i < data.length; i++) {
            var item = data[i]
            var packageList = hash[item.departure_date]
            if (packageList == null) packageList = []
            var packageItem = { amount: item.amount, day: item.duration_days + -+item.duration_nights }
            durationDayHash[item.duration_days] = true
            packageList.push(packageItem)
            hash[item.departure_date] = packageList
        }
        return hash
    }
    //selectMoretravelers
    selectMoreTravelers = (e, name) => {
        console.log('more4', e, name)
        this.setState({
            [name]: e,
            morePassengers: { ...this.state.morePassengers, [name]: e.value }
        }, () => {
            console.log('morepassgensrs', this.state)
        })
    }

    // more passemgers request
    sendRequest = async () => {
        const requiredFields = copyObj(this.state.morePassengers)
        let errors = validateInputs(requiredFields);
        if (Object.keys(errors).length === 0) {
            this.setState({
                loading: true
            })
            const data = {
                url: 'passengers/',
                body: requiredFields
            }
            const newData = await apiPost(data)
            console.log('update more passengers', newData)
            if ('status' in newData && newData.status === 201) {
                this.setState({
                    loading: false,
                    show: true
                })
            } else {
                alert('Something went wrong')
            }
            console.log('newData enuiry', newData)
        } else {
            this.setState({
                errors: errors,
                loading: false
            })
        }
    }

    render() {
        const { morePassengers, showTable, showSummary, showMonth, package_cmp, keys,packageSummary,
            errors, field_errors, show, monOptions, travelOptions } = this.state
        const imageUrl = 'package' in package_cmp ? package_cmp.package.length > 0 ?
            package_cmp.package[0].image : PackageImage : PackageImage
        const packName = 'package' in package_cmp ? package_cmp.package.length > 0 ?
            package_cmp.package[0].name : '' : ''
        const price = 'package' in package_cmp ? package_cmp.package.length > 0 ?
            package_cmp.package[0].from_price : '' : ''
        return (<PageWrapper>
            <ImageContainer img={imageUrl}>
                <Name>{packName}</Name>
                <PriceTag>from e {price}</PriceTag>
            </ImageContainer>
            <StepZeroContainer>
                <StepZeroH6>Great, you have selected a package deal!</StepZeroH6>
                <StepZeroH3>What’s included in this package?</StepZeroH3>
                {/* package detail starts */}
                <IncludedPackage>
                    {'airline' in package_cmp ? package_cmp.airline.length > 0 ? package_cmp.airline.map((x, i) => {
                        return <PackageLabel img={PlaneIcon} heading="Direct flight - Economy - Return">
                            <StepZeroHighlightText>{x.name}</StepZeroHighlightText>
                            <StepZeroStepZeroHighlightText>
                                {`${package_cmp.package[0].luggage_included ? package_cmp.package[0].luggage_qty + ' kg of luggage included' : ''}`}</StepZeroStepZeroHighlightText>
                        </PackageLabel>
                    }) : '' : ''}
                    {'hotel' in package_cmp ? package_cmp.hotel.length > 0 ? package_cmp.hotel.map((x, i) => {
                        return <PackageLabel img={HotelIcon} heading="Hotel">
                            <StepZeroHighlightText>{x.name}</StepZeroHighlightText>
                            <StepZeroStepZeroHighlightText>{x.room_name}</StepZeroStepZeroHighlightText>
                        </PackageLabel>
                    }) : '' : ''}
                    <FlexWrapper>
                        <PackageSections>
                            {'tour' in package_cmp ? package_cmp.tour.length > 0 ? package_cmp.tour.map((x, i) => {
                                return <PackageLabel img={TourIcon} heading="Tours">
                                    <StepZeroHighlightText>{x.name}</StepZeroHighlightText>
                                </PackageLabel>
                            }) : '' : ''}
                            {'excursion' in package_cmp ? package_cmp.excursion.length > 0 ? package_cmp.hotel.map((x, i) => {
                                return <PackageLabel img={ExcursionIcon} heading="Excursions">
                                    <StepZeroHighlightText>{x.name}</StepZeroHighlightText>
                                </PackageLabel>
                            }) : "" : ''}
                        </PackageSections>
                        <PackageSections>
                            {'car_rental' in package_cmp ? <PackageLabel img={ExtraIcon} heading="Car Rental">
                                {package_cmp.car_rental.length > 0 ?
                                    package_cmp.car_rental.map((x, i) => {
                                        return <StepZeroHighlightText>
                                            {x.name}</StepZeroHighlightText>
                                    }) : ''}
                                <StepZeroStepZeroSeparator />
                                <StepZeroStepZeroHighlightText>Transfer</StepZeroStepZeroHighlightText>
                                {package_cmp.transfer.length > 0 ?
                                    package_cmp.transfer.map((x, i) => {
                                        return <StepZeroHighlightText>
                                            {x.name}</StepZeroHighlightText>
                                    }) : ''}
                                <StepZeroStepZeroSeparator />
                                <StepZeroStepZeroHighlightText>Added value</StepZeroStepZeroHighlightText>
                                {package_cmp.added_value.length > 0 ?
                                    package_cmp.added_value.map((x, i) => {
                                        return <StepZeroHighlightText>
                                            {x.name}</StepZeroHighlightText>
                                    }) : ''}
                            </PackageLabel> : ''}
                        </PackageSections>
                    </FlexWrapper>
                </IncludedPackage>
                {/* package detail ends */}
                {/* selecting no of travelers */}
                <FlexWrapper>
                    <PackageSections>
                        <StepZeroH4>Select number of travellers</StepZeroH4>
                        <LinkText onClick={this.onOpenModal}
                            className='underline no-of-travelers'>
                            More than 4 passengers or children?
                        </LinkText>
                        {/* more than 4 passengers modal */}
                        <Modal
                            classNames={{
                                overlay: 'modal-passengers-overlay',
                                modal: 'modal-passengers-container',
                                closeButton: 'modal-close-btn'
                            }}
                            open={this.state.open}
                            onClose={this.onCloseModal}
                            center>
                            {!show ? <EnquiryContainer>
                                <Text className=''>No problem!</Text>
                                <h3 className='primary-text text-bold'>Travelling with more than persons</h3>
                                <Text className=' my-2'>
                                    In order to give you the best pricing and availability we'll have to contact you personally with an offer for more than 4 persons travelling in this package.
            </Text>
                                <HelpUsFrom className='my-4'>
                                    <h4 className='primary-text'>Select number of travellers</h4>
                                    <DropdownGroup className='align-base'>
                                        <div className='w-25'>
                                            <Dropdown
                                                label='0 Adult(s)'
                                                options={numbers}
                                                // show={showTable}
                                                value={this.state.adult_qty}
                                                classname='dropdown-section w-100'
                                                changeDropdown={(e) => this.selectMoreTravelers(e, 'adult_qty')}
                                            />
                                            <FlexWrapper className='justify-end'>
                                                {errors.adult_qty && (
                                                    <span className={`${morePassengers.adult_qty ? 'd-none' : 'error'}`}>{errors.adult_qty}</span>
                                                )}
                                                <Note>+12 years</Note>
                                            </FlexWrapper>
                                        </div>
                                        <div className='w-25 mx-4'>
                                            <Dropdown
                                                label='0 Child(ren)'
                                                options={numbers}
                                                // show={showTable}
                                                value={this.state.child_qty}
                                                classname='dropdown-section w-100 '
                                                changeDropdown={(e) => this.selectMoreTravelers(e, 'child_qty')}
                                            />
                                            <FlexWrapper className='justify-end'>
                                                {errors.child_qty && (
                                                    <span className={`${morePassengers.child_qty ? 'd-none' : 'error'}`}>{errors.child_qty}</span>
                                                )}
                                                <Note>+2 to 12 years</Note>

                                            </FlexWrapper>
                                        </div>
                                        <div className='w-25'>
                                            <Dropdown
                                                label='0 Infant(S)'
                                                options={numbers}
                                                value={this.state.infant_qty}
                                                // show={showTable}
                                                classname='dropdown-section w-100'
                                                changeDropdown={(e) => this.selectMoreTravelers(e, 'infant_qty')}
                                            />
                                            <FlexWrapper className='justify-end'>
                                                {errors.infant_qty && (
                                                    <span className={`${morePassengers.infant_qty ? 'd-none' : 'error'}`}>{errors.infant_qty}</span>
                                                )}
                                                <Note>Younger than 2 years</Note>
                                            </FlexWrapper>
                                        </div>
                                    </DropdownGroup>
                                </HelpUsFrom>
                                <HelpUsFrom className='my-4'>
                                    <h4 className='primary-text'>Preferred travel date</h4>
                                    <FlexWrapper className='align-base'>
                                        <DropdownGroup className='w-70 align-base'>
                                            <div className='w-25'>
                                                <Dropdown
                                                    label='Day'
                                                    options={this.state.dateOptions}
                                                    // show={showTable}
                                                    value={this.state.day}
                                                    classname='dropdown-section w-100'
                                                    changeDropdown={(e) => this.selectMoreTravelers(e, 'day')}
                                                />
                                                {errors.day && (
                                                    <span className={`${morePassengers.day ? 'd-none' : 'error'}`}>{errors.day}</span>
                                                )}
                                            </div>
                                            <div className='w-25 mx-2'>
                                                <Dropdown
                                                    label='Month'
                                                    options={this.state.monthOptions}
                                                    // show={showTable}
                                                    value={this.state.month}
                                                    classname='dropdown-section w-100'
                                                    changeDropdown={(e) => this.selectMoreTravelers(e, 'month')}
                                                />
                                                {errors.month && (
                                                    <span className={`${morePassengers.month ? 'd-none' : 'error'}`}>{errors.month}</span>
                                                )}
                                            </div>
                                            <div className='w-25'>
                                                <Dropdown
                                                    label='Year'
                                                    options={this.state.yearOptions}
                                                    value={this.state.year}
                                                    // show={showTable}
                                                    classname='dropdown-section w-100'
                                                    changeDropdown={(e) => this.selectMoreTravelers(e, 'year')}
                                                />
                                                {errors.year && (
                                                    <span className={`${morePassengers.year ? 'd-none' : 'error'}`}>{errors.year}</span>
                                                )}
                                            </div>
                                        </DropdownGroup>
                                        <div className='w-30'>
                                            <Dropdown
                                                label=''
                                                options={stayOptions}
                                                value={this.state.length_of_stay}
                                                // show={showTable}
                                                classname='dropdown-section w-100'
                                                changeDropdown={(e) => this.selectMoreTravelers(e, 'length_of_stay')}
                                            />
                                            <FlexWrapper className='justify-end'>
                                                {errors.length_of_stay && (
                                                    <span className={`${morePassengers.length_of_stay ? 'd-none' : 'error'}`}>{errors.length_of_stay}</span>
                                                )}
                                                <Note>Length of stay</Note>
                                            </FlexWrapper>
                                        </div>
                                    </FlexWrapper>
                                </HelpUsFrom>
                                <HelpUsFrom className='my-3'>
                                    <h4 className='primary-text'>Your contact details</h4>
                                    <FlexWrapper>
                                        <InputFiled>
                                            <h6 className=' m-0'>Firstname</h6>
                                            <Input
                                                label='Firstname'
                                                name='first_name'
                                                type='text'
                                                inputChange={this.stepZeroIpChange}
                                                val={morePassengers.first_name}
                                                classname='w-100 bg-grey' />
                                            {errors.first_name && (
                                                <span className={`${morePassengers.first_name ? 'd-none' : 'error'}`}>{errors.first_name}</span>
                                            )}
                                        </InputFiled>
                                        <InputFiled>
                                            <h6 className=' m-0'>Lastname</h6>
                                            <Input
                                                name='last_name'
                                                inputChange={this.stepZeroIpChange}
                                                val={morePassengers.last_name}
                                                label='Lastname'
                                                type='text'
                                                classname='w-100 bg-grey' />
                                            {errors.last_name && (
                                                <span className={`${morePassengers.last_name.length > 0 ? 'd-none' : 'error'}`}>{errors.last_name}</span>
                                            )}
                                        </InputFiled>
                                    </FlexWrapper>
                                    <FlexWrapper>
                                        <InputFiled>
                                            <h6 className=' m-0'>Email</h6>
                                            <Input
                                                inputChange={this.stepZeroIpChange}
                                                val={morePassengers.email}
                                                name='email'
                                                type='email'
                                                label='Email'
                                                classname='w-100 bg-grey' />
                                            {errors.email && (
                                                <span className={`${morePassengers.email.length > 0 ? 'd-none' : 'error'}`}>{errors.email}</span>
                                            )}
                                            {field_errors.email && (
                                                <span className="error mx-2">{field_errors.email}</span>
                                            )}
                                        </InputFiled>
                                        <InputFiled>
                                            <h6 className=' m-0'>Telephone number</h6>
                                            <Input
                                                inputChange={this.stepZeroIpChange}
                                                val={morePassengers.telephone}
                                                name='telephone'
                                                type='number'
                                                label='Telephone number'
                                                classname='w-100 bg-grey' />
                                            {errors.telephone && (
                                                <span className={`${morePassengers.telephone.length > 0 ? 'd-none' : 'error'}`}>{errors.telephone}</span>
                                            )}
                                            {field_errors.telephone && (
                                                <span className="error mx-2">{field_errors.telephone}</span>
                                            )}
                                        </InputFiled>
                                    </FlexWrapper>
                                    <FlexWrapper>
                                        <InputFiled className='w-100'>
                                            <h6 className=' m-0 mb-2'>Add a special remark to your request</h6>
                                            <Textarea
                                                inputChange={this.stepZeroIpChange}
                                                val={morePassengers.remarks}
                                                name='remarks'
                                                label='For example: I would like to have this package with 4 people'
                                                classname='w-100 m-0 bg-grey' />
                                            {errors.remarks && (
                                                <span className={`${morePassengers.remarks.length > 0 ? 'd-none' : 'error'}`}>{errors.remarks}</span>
                                            )}
                                        </InputFiled>
                                    </FlexWrapper>
                                    <ButtonWrapper>
                                        <Button
                                            submit={this.sendRequest}
                                            righticon='fa fa-chevron-right'
                                            classname="button-primary"
                                            name="Send this request" />
                                    </ButtonWrapper>
                                </HelpUsFrom>
                            </EnquiryContainer> :
                                <EnquirySuccsess className='mt-5'>
                                    <InputFiled className='w-100 '>
                                        <h5 className='w-100 '>Yes! We received your request !</h5>
                                        <p className='w-100 '>Within 48 hours we will contact you by phone or by email.</p>
                                    </InputFiled>
                                    <InputFiled className='w-100 '>
                                        <h5 className='w-100 '>If any other questions raises,</h5>
                                        <p className='w-100 '>you can contact us by calling 070 233 233</p>
                                    </InputFiled>
                                    <InputFiled className='w-100 '>
                                        <Button submit={this.onCloseModal}
                                            name='Close' classname='button-primary bg-danger my-3' />
                                    </InputFiled>
                                </EnquirySuccsess>}
                        </Modal>
                        {/* modal ends */}
                    </PackageSections>
                    <PackageSections>
                        <Dropdown
                            label='Select'
                            options={travelOptions}
                            show={showTable}
                            value={this.state.travelers}
                            classname='dropdown-section'
                            changeDropdown={this.selectNumOfTravelers}
                        />
                    </PackageSections>
                </FlexWrapper>
                {/* selecting no of travelers */}
                <StepZeroStepZeroHR />
                {/* Package Table starts */}
                {showTable ? <React.Fragment>
                    <FlexWrapper>
                        <StepZeroH4>Select departure date</StepZeroH4>
                        <LinkText className='primary-text'>*Prices shown are per person</LinkText>
                    </FlexWrapper>
                    <FlexWrapper className='margin-y'>
                        <PackageSections>
                            <Dropdown
                                label='Select'
                                options={monOptions}
                                value={this.state.depatureMonth}
                                show={showMonth}
                                changeDropdown={this.selectDepatureDate}
                                classname='dropdown-section'
                            />
                        </PackageSections>
                        <DateSection>
                            <StepZeroStepZeroFloatingContainer>
                                <Icon className='margin-r' src={EarlierIcon} alt='Earlier dates foto' />
                                <StepZeroHighlightText>Earlier dates</StepZeroHighlightText>
                            </StepZeroStepZeroFloatingContainer>
                            <StepZeroStepZeroFloatingContainer>
                                <StepZeroHighlightText>Later dates</StepZeroHighlightText>
                                <Icon className='margin-l' src={LaterIcon} alt='Later dates foto' />
                            </StepZeroStepZeroFloatingContainer>
                        </DateSection>
                    </FlexWrapper>
                    <TableWrapper>
                        <Table
                            datas={this.state.rowDatas}
                            row={this.state.rows}
                            col={this.state.col}
                            tableCell={this.state.index}
                            tableCellData={this.state.data}
                            choosePrice={this.selectPrice} />
                    </TableWrapper>
                    <FlexWrapper className="another-date">
                        <LinkText >Planning on travelling on another date?</LinkText>
                    </FlexWrapper>
                    <StepZeroStepZeroHR />
                </React.Fragment>
                    : ''}
                {/* Package Table ends */}
                {/* summary section starts*/}
                {showSummary ? <React.Fragment>
                    {/* <HR /> */}
                    <SummaryContainer>
                        <StepZeroH4>Your selected package summary</StepZeroH4>
                        <SummaryPackageDetail>
                <StepZeroStepZeroHighlightText className='text-capitalize'>{packageSummary.package_name}</StepZeroStepZeroHighlightText>
                <StepZeroStepZeroHighlightText>{packageSummary.qty.split(",")[0]}</StepZeroStepZeroHighlightText>
                <StepZeroStepZeroHighlightText>{packageSummary.total_qty}x € {this.state.price}</StepZeroStepZeroHighlightText>
                        </SummaryPackageDetail>
                        <SummaryPackageDetail>
                <StepZeroStepZeroHighlightText>{packageSummary.start_date}</StepZeroStepZeroHighlightText>
                <StepZeroStepZeroHighlightText>{packageSummary.duration}</StepZeroStepZeroHighlightText>
                <StepZeroStepZeroHighlightText>{packageSummary.end_date}</StepZeroStepZeroHighlightText>
                        </SummaryPackageDetail>
                    </SummaryContainer>
                    <StepZeroStepZeroHR />
                    <SummaryContainer>
                        <FlexWrapper>
                            <div>
                <h4>Total price € {packageSummary.Total_amount}</h4>
                                <LinkText >* Incl. here goes some extra legal text or something static</LinkText>
                            </div>
                            <Link to='/form'>
                                <Button
                                    righticon='fa fa-chevron-right'
                                    classname="button-primary"
                                    name="Continue" />
                            </Link>
                        </FlexWrapper>
                    </SummaryContainer>
                </React.Fragment> : ''}
                {/* summary section ends*/}
            </StepZeroContainer>
        </PageWrapper>)
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default withRouter(connect(mapStateToProps, {selectPackage,packageCmp})(index))
