import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { Colors } from '../../library/colors'
import logo from '../../assets/images/cnx-logo-long.svg'
import moment from 'moment'

// *** components styles starts *** //
const FooterWrapper = styled.div`
box-sizing:border-box;
min-height:104px;
padding:0 5rem;
display:flex;
background:${Colors.primaryColor};
justify-content:space-between;
align-items:center;
border-top:3px solid ${Colors.primaryColor};
@media only screen and (max-width: 768px) {
    padding:0 1rem;
}
`
const LogoContainer = styled.div`
padding:10px 0px;
display:flex;
width:200px;
align-items:center;
@media only screen and (max-width: 768px) {
flex-direction:column;
}
`
const Logo = styled.img`
`
const CopyrightSection = styled.div`
margin:0px 30px;
`
const Label = styled.label`
color:#ffffff;
font-size:14px;
`
const LinkSection=styled.div`
display:flex;
align-items:center;
margin:0px 30px;
@media only screen and (max-width: 768px) {
flex-direction:column;
align-items:baseline;
}
`
const Links=styled.label`
font-size:14px;
color:white;
cursor:pointer;
text-transform:capitalize;
text-decoration:underline;
&:nth-child(2) {
    margin:0px 20px 8px;
  }
@media only screen and (max-width: 768px) {
    margin:5px 0px!important;
}  
`
export class index extends Component {
    render() {
        const {langObj,selectedLang}=this.props.lang
        const language=selectedLang.toUpperCase()
        const policyUrl=language==="NL"?"https://www.connections.be/nl-be/connections/privacy":
        language==="FR"?"https://www.connections.be/fr-be/connections/privacy":language==="EN"?"https://www.connections.be/en/connections/privacy":""
        const legal=language==="NL"?"https://www.connections.be/nl-be/connections/legal-disclaimer":
        language==="FR"?"https://www.connections.be/fr-be/connections/limitation-de-responsabilite":language==="EN"?"https://www.connections.be/en/connections/legal-disclaimer":""
        const revoke=language==="NL"?"https://www.connections.be/nl-be/reisinformatie/herroepingsrecht":
        language==="FR"?"https://www.connections.be/fr-be/info-de-voyage/droit-de-r%C3%A9tractation":language==="EN"?"https://www.connections.be/en/travel-information/revoke-a-purchase":""
        var current_year = moment().year();
        return (<FooterWrapper>
            <LogoContainer>
                <Logo
                    src={logo}
                    alt='Connections logo' />
                <CopyrightSection>
                    <Label><b><span className='copyright'>{current_year} - Connections</span></b></Label>
                </CopyrightSection>
            </LogoContainer>
            <LinkSection>
        <Links>
        <a href={policyUrl} className='text-light' target='_blank'>
        {langObj.policy}
        </a>
        </Links>
        <Links>
        <a href={legal} className='text-light' target='_blank'>
        {langObj.legal_disclaimer}
        </a>
        </Links>
        <Links>
        <a href={revoke} className='text-light' target='_blank'>
        {langObj.revoke_right}
        </a>
        </Links>
            </LinkSection>
        </FooterWrapper>)
    }
}

const mapStateToProps = (state) => ({
lang:state.language
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(index)
