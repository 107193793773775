import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { Colors } from '../../library/colors'

const PackageLabelContainer = styled.div`
display:flex;
margin: 1.5rem 0px;
`
const Icon = styled.img`
height:100%;
margin-right: 15px;
width: 15px;
`
const PackageDetail = styled.div`
display:flex;
flex-direction:column;
padding: 0px 15px;
border-left: 1px solid #d3d3d3a3;
`
const H6 = styled.h6`
font-size:14px;
font-weight:normal;
text-transform:capitalize;
color:${Colors.primaryText};
`
export class index extends Component {
    render() {
        return (<PackageLabelContainer className={this.props.cls}>
            <Icon alt='package-label-icon' src={this.props.img} />
            <PackageDetail>
                <H6>{this.props.heading}</H6>
                {this.props.children}
            </PackageDetail>
        </PackageLabelContainer>)
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(index)