import { CHECK_ITEM,CHECK_ERR, SELECTED,RESETINSURANCE } from './reducer';
// import { RESET } from '../Forms/reducer';

export const selectItem = (data) => ({
  type: CHECK_ITEM,
  payload:data
});

export const showErr = (data) => ({
  type: CHECK_ERR,
  payload:data
});

export const selected = (data) => ({
  type: SELECTED,
  payload:data
});

export const resetInsurance = () => ({
  type: RESETINSURANCE,
});
