import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {NavLink} from 'react-router-dom'
import $ from 'jquery'
import PackageImg from '../../assets/images/package-card.png'
import { Colors } from '../../library/colors'
import {copyObj,apiPost} from '../../library'

const PackageCardWrapper = styled.div`
display:flex;
flex-wrap:wrap;
align-items:center;
margin-left:-15px;
margin-right:-15px;
margin-top:50px;
margin-bottom:50px;
@media (max-width:500px){
    margin-top:30px;
    margin-bottom:30px;
}
`
const PacBox = styled.div`
padding:0px 15px;
width:33.3333%;
margin-bottom:30px;
a{
    display:block;
}
@media (max-width:991px){
    width:50%;
}
@media (max-width:500px){
    width:100%;
}
`
const PackageCard = styled.div`
width:100%;
`
const PackageImage = styled.img`
height:125px;
width:100%;
`
const H3 = styled.h4`
background:${Colors.secondaryText};
color:#ffffff;
font-size:22px;
padding:8px 1rem;
margin-bottom:0;
margin-top:-3px;
`
const PriceTag = styled.h5`
padding:8px 1rem;
margin-bottom:0;
color:#ffffff;
background:${Colors.primaryColor};
`

const cards = [1, 2, 4, 5, 6, 7, 8, 8, 2]
export class index extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            keys: {
                des_name: [],
                order_name: '',
                order_price: '',
                text_language: 'EN',
                package_code: '',
                detail: ''
            }
        }
    }
    
above=()=>{
    $("html, body").animate({ scrollTop: 0 }, 0);
}

    render() {
        console.log('packagecard props',this.props)
        const {data}=this.props
        const{langObj,selectedLang}=this.props.lang
        const language=selectedLang.toLowerCase()
        return (<PackageCardWrapper>
            {data && data.length>0? data.map((x, i) => {
                return <PacBox><NavLink onClick={this.above} to={`/${language}/package-detail/${x.package_code}`}>
                    <PackageCard>
                    <PackageImage src={x.image} alt="package imag" />
            <H3 className='text-capitalize'>{x.name}</H3>
            <PriceTag>{langObj.dashboard_from} <span className='font-sm mx-1'> € </span> {x.from_price}</PriceTag>
                </PackageCard>
                </NavLink></PacBox>
            }):<p>No data available</p>}
        </PackageCardWrapper>)
    }
}

const mapStateToProps = (state) => ({
lang:state.language
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(index)
