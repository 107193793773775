import React, { Component } from 'react'
import styled from 'styled-components'

import Header from '../components/Header/Header'
import Footer from '../components/Footer'

const BodySection=styled.div`
min-height:100vh;
padding:2rem 5rem;
@media only screen and (max-width: 768px) {
padding:2rem 1rem;
}
`
export default class index extends Component {
    render() {
        return (<React.Fragment>
            <Header/>
            <BodySection>
                {this.props.children}
            </BodySection>
            <Footer/>
        </React.Fragment>)
    }
}
