import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import ErrorImg from '../../assets/images/BOX-ERROR.png'
import Status from '../Status'

const H4=styled.h4`
color: #223E92;
font-size: 44px;`

const H6=styled.h6`
font-size:21px;
margin:1rem 0px;
font-weight:normal;
color:#363636;
text-align: center;
`
const PaymentMessage = styled.div`
font-size:16px;
margin:2rem;
`
export class index extends Component {

        render() {
            return (<Status img={ErrorImg} buttonName='Back to connections.be'>
                        <H4>Error 404 </H4>
                        <H6>Ohoh, something went wrong. We can’t seem to find this page. </H6>
                        <PaymentMessage className='margin-y'>Try to click the back button in your browser and try again. If no success, this page is no longer available.</PaymentMessage> 
                    </Status>)
        }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(index)







