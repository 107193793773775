import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import {resetState} from  '../../components/Forms/actions'
import Forms from '../../components/Forms'
import FormPackageCard from '../../components/FormPackageCard'
import Layout from '../../layout'

const FormWrapper=styled.div`
display:flex;
`
const FormSection=styled.div`
width: 63%;
margin-right: 20px;
`
const PackageDetailSection=styled.div`
width: 29.5%;
`
export class index extends Component {

    
    render() {

        const userquery = window.location.search; 
        const urlParam = new URLSearchParams(userquery)
        const step = urlParam.get('step')
        
        if(!step){
            this.props.resetState()
        }

        return (<Layout>
        {/* <FormWrapper>
                <FormSection> */}
                    <Forms/>
                {/* </FormSection>
                <PackageDetailSection>
                    <FormPackageCard/>
                </PackageDetailSection>
        </FormWrapper> */}
        </Layout>)
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps,{resetState})(index)
