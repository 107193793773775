import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import PackageDetail from '../../components/PackageDetail'
import Layout from '../../layout'
import { Colors } from '../../library/colors'


export class index extends Component {

    render() {
        return (<Layout>
                <PackageDetail/>
        </Layout>)
    }
} 

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(index)