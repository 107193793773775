import { ITEM,ERR,RESETPAYMENTMETHODS } from './reducer';
// import { RESET } from '../Forms/reducer';

export const selectType = (data) => ({
  type: ITEM,
  payload:data
});

export const err = (data) => ({
    type: ERR,
    payload:data
  });

  export const resetPaymentMethods = () => ({
    type: RESETPAYMENTMETHODS,
  });