import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import _ from 'lodash'

import Button from '../Button'
import Textarea from '../TextArea'
import { Colors } from '../../library/colors'
import Loader from '../HeaderLoader'
import {closeModal,openModal} from './actions'
import { validEmailRegex, validateInputs, apiPost } from '../../library'
import logo from '../../assets/images/header-logo.png'
import Input from '../Input'
// *** components styles starts *** //
const HeaderWrapper = styled.div`
box-sizing:border-box;
height:72px;
padding:0 5rem;
position:relative;
display:flex;
justify-content:space-between;
border-top:3px solid ${Colors.primaryColor};
@media only screen and (max-width: 768px) {
padding:0 1rem;
}  
`
const LogoContainer = styled.div`
padding:10px 0px;
`
const Logo = styled.img`
width:100%;
`
const HelpLanguageSection = styled.div`
padding-bottom:10px;
display:flex;
justify-content:space-between;
@media only screen and (max-width: 768px) {
   display:none;
}
`
const HelpSection = styled.div`
margin:0px 30px;
align-self: flex-end;
`
const LanguageSection = styled.div`
display:flex;
justify-content:space-between;
`
const LanguageContainer = styled.div`
display:flex;
justify-content:center;
`
const Label = styled.div`
text-decoration:underline;
color:${Colors.primaryText};
font-size:14px;
`
const LanguageButton = styled.div`
width:30px;
height:60px;
background:#dddddd;
display:flex;
align-items:flex-end;
// border: 1px solid #eaeaea;
cursor:pointer;
// transition: all 1s;
&:nth-child(2) {
    margin:0px 8px;
  }
`
const Span = styled.div`
background:${Colors.primaryBg};
text-align:center;
height:25px;
font-size:13px;
width:100%;
padding:3px 0px;
// transition:background 1s;
text-transform:uppercase;
`
const SpanCopy = styled.div`
background:${Colors.primaryBg};
text-align:center;
height:25px;
font-size:13px;
width:30px;
padding:3px 0px;
// transition:background 1s;
text-transform:uppercase;
`
const EnquiryContainer = styled.div`
margin:20px 0px;
background:transparent;
`
const EnquirySuccsess = styled.div`
margin:20px 0px;
background:transparent;
`
const HelpUsFrom = styled.div`

`
const Text = styled.div`
font-size:18px;

`
const FlexWrapper = styled.div`
display:flex;
// align-items:center;
justify-content:space-between;
@media only screen and (max-width: 768px) {
    flex-direction:column;
}
`
const InputFiled = styled.div`
margin:15px 0px;
width:47%;
flex-wrap:wrap;
@media only screen and (max-width: 768px) {
    width:100%;
}
`
const ButtonWrapper = styled.div`
display:flex;
align-items:center;
justify-content:flex-end;
`
const ResponsiveSection = styled.div`
position:absolute;
background:#fff;
min-height:100vh;
z-index:4;
text-align:center;
padding:20px;
display:none;
top: 0;
left: 0;
right: 0;
@media only screen and (max-width: 768px) {
    display:inline-block;
 }
`
const SingleSection = styled.div`
margin:20px 0px;
`
// *** components styles ends *** //

export class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showNavbar: false,
            selectedLang: 'en',
            open: false,
            loading: false,
            show: false,
            keys: {
                first_name: '',
                last_name: '',
                email: '',
                telephone: '',
                remarks: ''
            },
            field_errors: {},
            errors: {}
        }
    }

    // onInputChnage
    onInputChange = (e) => {
        const { name } = e.target
        var value = e.target.value
        let errVal;
        switch (name) {
            case 'email':
                errVal = !validEmailRegex.test(value) ? this.props.lang.langObj.invalid_email : ''
                break;
            case 'telephone':
                // value = value.toString().slice(0, 10);
                // errVal = value.length === 10 ? '' : 'Mobile number is not valid';
                // parseInt(value);
                value = value.replace(/[^+()/0-9]/gi, '');
                value = value.toString().slice(0, 16);
                break;
            default:
        }
        this.setState({
            field_errors: {
                ...this.state.field_errors,
                [name]: errVal !== 'undefined' ? errVal : '',
            },
        });
        this.setState({
            keys: { ...this.state.keys, [name]: value }
        })
    }

    // Change Language //
    chooseLanguage = (lang) => {
        this.setState({ selectedLang: lang })
    }

    onOpenModal = () => {
        const sampleKeys = {
            first_name: '',
            last_name: '',
            email: '',
            telephone: '',
            remarks: ''
        }
        this.setState({ open: true, show: false, keys: sampleKeys });
    };

    onCloseModal = () => {
        this.props.closeModal()
    };

    //Show navbar
    showNavbar = () => {
        this.setState({
            showNavbar: !this.state.showNavbar
        })
    }


    sendRequest = async () => {
        const requiredFields = _.pick(this.state.keys, 'first_name', 'email', 'last_name', 'telephone')
        let errors = validateInputs(requiredFields);
        if (Object.keys(errors).length === 0) {
            this.setState({
                loading: true
            })
            const data = {
                url: 'enquiry/',
                body: this.state.keys
            }
            const newData = await apiPost(data)
            if ('status' in newData && newData.status === 201) {
                this.setState({
                    loading: false,
                    show: true
                })
                this.props.closeModal()
            } else {
                // this.props.closeModal()
                alert('Something went wrong')
            }
            console.log('newData enuiry', newData)
        } else {
            this.setState({
                errors: errors,
                loading: false
            })
        }
    }
    render() {
        const { selectedLang, keys, field_errors, errors, show, loading, showNavbar } = this.state
        const {langObj}=this.props.lang
        var package_cmp = this.props.cmp.selectedPackage.package_cmp
        var packName=''
        if (package_cmp) {
            // var packageCommonDetails = packageCmp.package[0]
            // imageUrl = 'package' in package_cmp ? package_cmp.package.length > 0 ?
            //     package_cmp.package[0].image : PackageImage : PackageImage
            packName = 'package' in package_cmp ? package_cmp.package.length > 0 ?
                package_cmp.package[0].name : '' : ''
            // price = 'package' in package_cmp ? package_cmp.package.length > 0 ?
            //     package_cmp.package[0].from_price : '' : ''
            // if ('package' in packageCmp) {
            //     var packageVars = packageCmp.package[0]

            // }
            // console.log('packageVars', packageVars)
        }
        return (<Modal
            classNames={{
                overlay: 'modal-overlay',
                modal: 'modal-container',
                closeButton: 'modal-close-btn model-close-btn'
            }}
            open={this.props.modalBox.modal}
            onClose={this.onCloseModal}
            center>
            {!show ? <EnquiryContainer>
                <Text className='text-light'>{langObj.enquiry_intro}</Text>
                <h3 className='text-light'>{langObj.enquiry_title}</h3>
                <Text className='text-light my-2'>
                {langObj.enquiry_description.replace("XXX",packName)}
            {/* You have a question about our <b className='text-capitalize'>{packName}</b> package? Since we love to help You
             out on a personal level, fill out the below form and we'll contact you. */}
        </Text>
                <HelpUsFrom className='my-3'>
                    <FlexWrapper>
                        <InputFiled>
                            <h6 className='text-light m-0'>{langObj.enquiry_first_name}</h6>
                            <Input
                                label={langObj.enquiry_first_name}
                                name='first_name'
                                type='text'
                                inputChange={this.onInputChange}
                                val={keys.first_name}
                                classname='w-100' />
                            {errors.first_name && (
                                <span className={`${keys.first_name.length > 0 ? 'd-none' : 'error'}`}>{errors.first_name}</span>
                            )}
                        </InputFiled>
                        <InputFiled>
                            <h6 className='text-light m-0'>{langObj.enquiry_last_name}</h6>
                            <Input
                                name='last_name'
                                inputChange={this.onInputChange}
                                val={keys.last_name}
                                label={langObj.enquiry_last_name}
                                type='text'
                                classname='w-100' />
                            {errors.last_name && (
                                <span className={`${keys.last_name.length > 0 ? 'd-none' : 'error'}`}>{errors.last_name}</span>
                            )}
                        </InputFiled>
                    </FlexWrapper>
                    <FlexWrapper>
                        <InputFiled>
                            <h6 className='text-light m-0'>{langObj.enquiry_email}</h6>
                            <Input
                                inputChange={this.onInputChange}
                                val={keys.email}
                                name='email'
                                type='email'
                                label={langObj.enquiry_email}
                                classname='w-100' />
                            {errors.email && (
                                <span className={`${keys.email.length > 0 ? 'd-none' : 'error'}`}>{errors.email}</span>
                            )}
                            {field_errors.email && (
                                <span className="error mx-2">{field_errors.email}</span>
                            )}
                        </InputFiled>
                        <InputFiled>
                            <h6 className='text-light m-0'>{langObj.enquiry_ph_number}</h6>
                            <Input
                                inputChange={this.onInputChange}
                                val={keys.telephone}
                                name='telephone'
                                type='number'
                                label={langObj.enquiry_ph_number}
                                classname='w-100' />
                            {errors.telephone && (
                                <span className={`${keys.telephone.length > 0 ? 'd-none' : 'error'}`}>{errors.telephone}</span>
                            )}
                            {field_errors.telephone && (
                                <span className="error mx-2">{field_errors.telephone}</span>
                            )}
                        </InputFiled>
                    </FlexWrapper>
                    <FlexWrapper>
                        <InputFiled className='w-100'>
                            <h6 className='text-light m-0 mb-2'>{langObj.enquiry_content} (not mandatory)</h6>
                            <Textarea
                                inputChange={this.onInputChange}
                                val={keys.remarks}
                                name='remarks'
                                label={langObj.more_4_pass_desp}
                                classname='w-100 m-0' />
                        </InputFiled>
                    </FlexWrapper>
                    <ButtonWrapper>
                        {loading ? <Loader /> : <Button
                            submit={this.sendRequest}
                            righticon='fa fa-chevron-right'
                            classname="button-primary"
                            name={langObj.send_this_request} />}
                    </ButtonWrapper>
                </HelpUsFrom>
            </EnquiryContainer> :
                <EnquirySuccsess className='mt-5'>
                    <InputFiled className='w-100 text-light'>
                        <h5 className='w-100 text-light'>{langObj.response_title}</h5>
                        <p className='w-100 text-light'>{langObj.response_desp1}</p>
                    </InputFiled>
                    <InputFiled className='w-100 text-light'>
                        <h5 className='w-100 text-light'>{langObj.response_desp2}</h5>
                        <p className='w-100 text-light'>{langObj.response_desp3}</p>
                    </InputFiled>
                    <InputFiled className='w-100 text-light'>
                        <Button submit={this.onCloseModal}
                            name={langObj.close} classname='button-close my-3' />
                    </InputFiled>
                </EnquirySuccsess>}
        </Modal>)
    }
}

const mapStateToProps = (state) => ({
   modalBox:state.helpSection,
   cmp: state.calc, 
   lang:state.language
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps,{closeModal})(index)
