const initialState = {
    packages: [],
    // selectedLang:'EN',
  };

  export const GET_PACKAGES = 'GET_PACKAGES'
  
  function homeReducer(state = initialState, action) {
    switch (action.type) {
      case GET_PACKAGES:
        return Object.assign({}, state, {
          packages:action.payload,
        })
      default:
        return state;
    }
  };
  
  export default homeReducer;