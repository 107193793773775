import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {NavLink} from 'react-router-dom'

import { Colors } from '../../library/colors'
import IconPen from '../../assets/images/ICON-PEN.png'

const Container = styled.div`
margin:20px 0px;
`
const FloatingHeader = styled.div`
display: flex;
align-items: center;
margin:10px 0px;
justify-content:space-between;

`
const H5 = styled.h6`
color:${Colors.primaryColor};
font-size:18px;
font-weight: 100;
`
const HR = styled.hr`
width: calc(100% - 320px);
border: 1px solid #EBEBEB;
margin:0px 25px;
@media only screen and (max-width: 768px) {
    display:none;
 }
`
const Icon = styled.img`
height:100%;
margin-bottom:6px;
margin:0px 5px 6px 0px;
`
const EditText=styled.h6`
font-size:13px;
text-decoration:underline;
`
const EditSection=styled.div`
display: flex;
align-items: center;
justify-content:space-between;
// width:130px;
@media only screen and (max-width: 768px) {
width:110px;  
 }
`

export class index extends Component {
    render() {
        const {langObj}=this.props.lang
        return (<Container>
            <FloatingHeader>
                <H5>{this.props.heading}</H5>
                <HR/>
                <EditSection  onClick={this.props.edit}>
                    <Icon src={IconPen} alt="edit-details" />
        <EditText>{langObj.step_4_i_edit}</EditText>
                </EditSection>
            </FloatingHeader>
           {this.props.children}
        </Container>)
    }
}

const mapStateToProps = (state) => ({
lang:state.language
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(index)
