// import _ from 'lodash';

const initialState = {
    checkboxes:[],
    errMsg:false,
    selected:{}
  };
  
  export const CHECK_ITEM = 'CHECK_ITEM';
  export const CHECK_ERR = 'CHECK_ERR';
  export const SELECTED = 'SELECTED';
  export const RESETINSURANCE = 'RESETINSURANCE';
  
   const insurance = (state = initialState, action) => {
     console.log('INU reducer',action)
    switch (action.type) {
      case CHECK_ITEM:
        return Object.assign({}, state, {
          checkboxes:action.payload
        })
        case RESETINSURANCE:
        return Object.assign({}, state, {
          checkboxes:[],
          errMsg:false,
          selected:{}
        })
        case CHECK_ERR:
          return Object.assign({}, state, {
            errMsg:action.payload
          })
          case SELECTED:
            return Object.assign({}, state, {
              selected:action.payload
            })
      default:
        return state;
    }
  };
  
  export default insurance;