const initialState = {
    option:'',
    err:false
  };
  
  export const ITEM = 'ITEM';
  export const ERR = 'ERR';
  export const RESETPAYMENTMETHODS = 'RESETPAYMENTMETHODS';
  
   const paymentType = (state = initialState, action) => {
     console.log('INU reducer',action)
    switch (action.type) {
      case ITEM:
        return Object.assign({}, state, {
          option:action.payload
        })
        case RESETPAYMENTMETHODS:
        return Object.assign({}, state, {
          option:'',
          err:false
        })
        case ERR:
            return Object.assign({}, state, {
              err:action.payload
            })
      default:
        return state;
    }
  };
  
  export default paymentType;