import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'


import PackageCard from '../../components/PackageCard'
import Layout from '../../layout'
import { Colors } from '../../library/colors'
import Dropdown from '../../components/Dropdown'
import { apiGet, copyObj,apiPost } from '../../library/'
import {resetState} from  '../../components/Forms/actions'
import {resetInsurance} from  '../../components/Insurance/actions'
import {resetPaymentMethods} from  '../../components/PaymentMethods/actions'
import {resetPaymentOptions} from  '../../components/PaymentOptions/actions'
import {chooseLang} from '../../components/Header/action'
import {getPackages} from './actions'
import { PSPID,Key,Secret } from '../../library/constant'

// Components styles starts //
const HomePageWrapper = styled.div`
`
const HeroText = styled.h1`
color:${Colors.primaryColor};
font-size:26px;
text-transform:uppercase;
`
const P = styled.p`
color:${Colors.primaryText};
font-size:16px;
// text-transform:capitalize;
margin:2rem 0;
`
const FilterSortingSection = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
@media only screen and (max-width: 768px) {
flex-direction:column-reverse;
align-items:baseline;
}
`
const H3 = styled.h3`
color:${Colors.primaryColor};
font-size:20px;
`
const FilterSection = styled.div`
display:flex;
align-items:center;
@media only screen and (max-width: 550px) {
    flex-direction:column;
align-items:baseline;
    }
// flex-wrap:wrap;
`
const SortingSection = styled.div`
display:flex;
align-items:center;
`
const SortButton = styled.label`
color:${Colors.primaryColor};
text-decoration:underline;
font-size:14px;
cursor:pointer;
margin-bottom:0px;
&:nth-child(2) {
margin:0px 25px;
}
`
const FilterContainer = styled.div`
width:255px;
margin:0px 20px;
@media only screen and (max-width: 550px) {
margin:0px 0px;
}
`
// Components styles ends //
const options = [
    { value: 'Chennai', label: 'Chennai' },
    { value: 'Ebba', label: 'Ebba' },
];
export class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showMonth: false,
            loading: false,
            packages:[],
            destinationLists:[],
            keys: {
                des_name: [],
                order_name: '',
                order_price: '',
                text_language: 'NL',
                package_code: '',
                detail: ''
            }
        }
    }
    // pageMounting
    componentDidMount() {
        if (this.props.match.params && this.props.match.params.lang) {
           console.log('this.props home',this.props) 
           this.changeLang(this.props.match.params.lang)
        }else{
            this.changeLang('NL')
        }
        // get a list of packages
        this.getPackages()
        this.getDestinations()
        this.props.resetState()
        this.props.resetInsurance()
        this.props.resetPaymentMethods()
        this.props.resetPaymentOptions()
        
    }

    //changeLang
    changeLang =async (lang) => {
        console.log('language changing.....')
        const obj={
            url:'translation/',
            body:{
                text_language:lang.toUpperCase()
            }
        }
        const newLng=await apiPost(obj)
        const newObj={obj:newLng[0],lang:lang}
        console.log('selectedLang1',lang)
        this.props.chooseLang(newObj)
        // this.props.history.push('/')
        console.log('neeLang Data',newLng)
    } 

    // getDestinations
    getDestinations=async ()=>{
         const data = {
            url: 'destination_pack_view/',
        }
        const newData = await apiGet(data)
        const destOptions=newData.map((x,i)=>{return {...x,'label':x.name,'value':x.name}})
        this.setState({
            destinationLists:destOptions
        })
        console.log('destinations newData', destOptions)
    }

    // getPackages
    getPackages = async () => {
        this.setState({loading:true})
        const newKeys = copyObj(this.state.keys)
        var lang=this.props.match.params.lang?this.props.match.params.lang:'NL'
        console.log('selectedLang',lang)
        newKeys['text_language']=lang.toUpperCase()
        const data = {
            url: 'user_pack_view/',
            body: newKeys
        }
        const newData = await apiPost(data)
        const {dashboard}=newData
        if(dashboard.status===200){
            this.props.getPackages(dashboard.data)
            this.setState({
               packages:dashboard.data,
               loading:false
            })
            
            // const userquery = window.location.search;
            // const urlParam = new URLSearchParams(userquery)
            // const id = urlParam.get('hostedCheckoutId')
            // const data = {
            //     url: "getPaymentStatus/",
            //     body: {
            //         id: id
            //     }
            // }
            // const newData = await apiPost(data)
            // console.log(newData)

        }else{
            this.setState({
                loading:false
            })
        }
    }

    // sortby name
    sortByName=(val)=>{
        this.setState({
            // showMonth: true,
            keys:{...this.state.keys,order_name:val,order_price:''}
        },()=>{
            this.getPackages()
        })
    }

    // sortby Name
    sortByPrice=(val)=>{
        this.setState({
            // showMonth: true,
            keys:{...this.state.keys,order_price:val,order_name:''}
        },()=>{
            this.getPackages()
        })
    }

    // selectDestination
    selectDestination = (val) => {
        console.log('destination changes',val)
        const newList= val?val.map((x,i)=>{return x.name}):[]
        this.setState({
            showMonth: true,
            keys:{...this.state.keys,des_name:newList}
        },()=>{
            this.getPackages()
        })
    }
    render() {
        const { showMonth,destinationLists,loading,keys } = this.state
        console.log('home page props',this.props)
        const {langObj}=this.props.lang
        const{packages}=this.props.home
        return (<Layout>
            <HomePageWrapper>
        <HeroText>{langObj.dashboard_intro}</HeroText>
                <P>{langObj.dashboard_description}</P>
                <FilterSortingSection>
                    <FilterSection>
                <H3>{langObj.dashboard_de_filter}</H3>
                        <FilterContainer>
                            <Dropdown
                                label={langObj.select}
                                options={destinationLists}
                                show={showMonth}
                                changeDropdown={this.selectDestination}
                                classname='dropdown-section'
                                multi={true}
                            />
                        </FilterContainer>
                    </FilterSection>
                    <SortingSection>
                        {keys.order_name===0?
                        <SortButton onClick={()=>this.sortByName(1)}>{langObj.dashboard_sortA_name}</SortButton>:
                        <SortButton onClick={()=>this.sortByName(0)}>{langObj.dashboard_sortZ_name}</SortButton>}
                        {keys.order_price===0?
                        <SortButton onClick={()=>this.sortByPrice(1)}>{langObj.dashboard_sort_price}</SortButton>:
                        <SortButton onClick={()=>this.sortByPrice(0)}>{langObj.dashboard_sort_price}</SortButton>}
                    </SortingSection>
                </FilterSortingSection>
                {loading?<p>Loading</p>:<PackageCard data={packages} />}
            </HomePageWrapper>
        </Layout>)
    }
}

const mapStateToProps = (state) => ({
lang:state.language,
home:state.homeReducer
})

const mapDispatchToProps = {

}


export default connect(mapStateToProps, {resetState,resetPaymentMethods,resetPaymentOptions,getPackages,chooseLang,
    resetInsurance})(index)