import React, { Component } from 'react'
import styled from 'styled-components'
import Checkbox from '../Checkbox'

import AirplaneLogo from '../../assets/images/airplane.png'

const Container = styled.div`
display:flex;
justify-content:space-between;
// align-items:center;
@media only screen and (max-width: 600px) {
flex-direction:column;
}
`
const AirplaneSection = styled.div`
width:47%;
padding:20px;
border-right:1px solid lightgray;
margin:30px 0px;
@media only screen and (max-width: 600px) {
width:100%;
padding:10px;
border-right:0px solid lightgray;
margin:10px 0px;
}
`
const BasicPlans = styled.div`
width:47%;
@media only screen and (max-width: 600px) {
    width:100%;
}
`
const InformationSection = styled.div`
padding:20px;
background:#fff;
margin:15px 0px;
`
const CheckboxWrapper = styled.div`
display:flex;
`
const DetailSection = styled.div`
margin-left:20px;
`
const H3 = styled.h5`
font-size:16px;
`
const Icon = styled.img`
// width:100%;
`
const P = styled.p`
margin:30px 0px!important;
`
export default class index extends Component {
    render() {
        const{data}=this.props
        return (<Container>
            <AirplaneSection>
                <Icon src={data.image} width='240px' height="50px" alt="airport logo" />
                <P className='upgrade-card-text'>
           {data.description}         
            </P>
            </AirplaneSection>
            <BasicPlans>
                {this.props.children}
                {/* <InformationSection>
                    <CheckboxWrapper>
                        <Checkbox val='stndard' />
                        <DetailSection>
                            <H3>Economy - Return</H3>
                            <p className='upgrade-card-text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Max two small lines!</p>
                        </DetailSection>
                    </CheckboxWrapper>
                    <H3 className='primary-text'>Included in package</H3>
                </InformationSection>
                <InformationSection>
                    <CheckboxWrapper>
                        <Checkbox val='stndasrd' />
                        <DetailSection>
                            <H3>Economy plus - Return</H3>
                            <p className='upgrade-card-text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Max two small lines!</p>
                        </DetailSection>
                    </CheckboxWrapper>
                    <H3 className='primary-text'>Included in package</H3>
                </InformationSection>
                <InformationSection>
                    <CheckboxWrapper>
                        <Checkbox val='stndsard' />
                        <DetailSection>
                            <H3>Business Class upgrade - Return</H3>
                            <p className='upgrade-card-text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Max two small lines!</p>
                        </DetailSection>
                    </CheckboxWrapper>
                    <H3 className='primary-text'>Included in package</H3>
                </InformationSection> */}
            </BasicPlans>
        </Container>)
    }
}
