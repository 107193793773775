import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'

import styled from 'styled-components'
import Layout from '../../layout'
import Button from '../Button'

const ButtonGroups=styled.div`
display:flex;
align-items:center;
justify-content: center;
`
const ImageTag =styled.img`
height: 50%;
margin-top: 55px;
` 
const ImageContainer = styled.div`
border-radius: 50%;
background: white;
width: 200px;
height: 200px;
margin-bottom: 30px;
display: inline-block;
margin-bottom: 30px;
`
const ParentDisplay = styled.div`
display:flex;
align-items:center;
justify-content:center;
`
const ChildDisplay = styled.div`
width:45%;
text-align:center;
`
export class index extends Component {

        render() {
            const {selectedLang}=this.props.lang
            const language=selectedLang.toLowerCase()
            return (<Layout>
            <ParentDisplay>
                <ChildDisplay className="status-child-div">
                  <ImageContainer>
                  <ImageTag src={this.props.img} alt="Avatar"  ></ImageTag>
                  </ImageContainer>
                    {this.props.children}
                    <ButtonGroups>
                        {this.props.result==="SUCCESSFUL" ? <Link to={`/${language}`}>    
                            <Button 
                                righticon='fa fa-chevron-right'
                                classname="button-primary"
                                name={this.props.buttonName}/>
                        </Link> : 
                            // <Link to={this.props.destination_url}> 
                            <Link to={`/${language}`}>    
                            <Button 
                                righticon='fa fa-chevron-right'
                                classname="button-primary"
                                name={this.props.buttonName}/>
                        </Link> }
                        {/* <Link to={`/${language}`}>    
                            <Button 
                                righticon='fa fa-chevron-right'
                                classname="button-primary"
                                name={this.props.buttonName}/>
                        </Link> */}
                    </ButtonGroups> 
                </ChildDisplay>
            </ParentDisplay>
            </Layout>)
        }
}

const mapStateToProps = (state) => ({
lang:state.language
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(index)

 





