import { combineReducers } from 'redux'
import articles from './components/Header/reducer'
import packageItem from './components/Forms/reducer'
import insurance from './components/Insurance/reducer'
import paymentOptions from './components/PaymentOptions/reducer'
import paymentType from './components/PaymentMethods/reducer'
import helpSection from './components/HelpUsSection/reducer'
import homeReducer from './pages/Home/reducer'

export default combineReducers({
  homeReducer:homeReducer,
  language:articles,
  calc:packageItem,
  insurance:insurance,
  paymentOptions:paymentOptions,
  paymentType:paymentType,
  helpSection:helpSection
})