// import _ from 'lodash';

const initialState = {
    options:[],
    errVal:false
  };
  
  export const ITEMS = 'ITEMS';
  export const ERRVAL = 'ERRVAL';
  export const RESETPAYMENTOPTIONS = 'RESETPAYMENTOPTIONS';
  
  
   const insurance = (state = initialState, action) => {
     console.log('INU reducer',action)
    switch (action.type) {
      case ITEMS:
        return Object.assign({}, state, {
          options:action.payload
        })
        // break;
        case ERRVAL:
            return Object.assign({}, state, {
              errVal:action.payload
            })
        // break;
        case RESETPAYMENTOPTIONS:
            return Object.assign({}, state, {
              errVal:false,
              options:[]
            })    
      default:
        return state;
    }
  };
  
  export default insurance;