// import _ from 'lodash';

const initialState = {
    modal:false
  };
  
  export const OPEN_MODAL = 'OPEN_MODAL'
  export const CLOSE_MODAL = 'CLOSE_MODAL';
  ;
//   export const CHECK_ERR = 'CHECK_ERR';
//   export const SELECTED = 'SELECTED';
  
   const helpSection = (state = initialState, action) => {
     console.log('INU reducer',action)
    switch (action.type) {
      case OPEN_MODAL:
        return Object.assign({}, state, {
          modal:true
        })
        case CLOSE_MODAL:
          return Object.assign({}, state, {
            modal:false
          })
      default:
        return state;
    }
  };
  
  export default helpSection;