import { ITEMS,ERRVAL,RESETPAYMENTOPTIONS } from './reducer';
// import { RESET } from '../Forms/reducer';

export const selectItem = (data) => ({
  type: ITEMS,
  payload:data
});

export const err_Val = (data) => ({
    type: ERRVAL,
    payload:data
  });


  export const resetPaymentOptions = () => ({
    type: RESETPAYMENTOPTIONS,
  });  
