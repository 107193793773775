import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react'


import reducers from './reducer';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import storage from 'redux-persist/lib/storage';
import TagManager from 'react-gtm-module'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistConfig = {
    key: 'form',
    storage: storage,
    whitelist: ['calc']
}

const pReducer = persistReducer(persistConfig,reducers)

const store = createStore(pReducer, composeEnhancers(applyMiddleware()));
const persistor = persistStore(store)
 
const tagManagerArgs = {
    gtmId: 'GTM-8BKB'
}
 
TagManager.initialize(tagManagerArgs)

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={ persistor }>
            <App />
        </PersistGate>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
