import React from "react";
import jQuery from "jquery";
import { localUrl } from "./constant";
import { Route, Redirect } from "react-router-dom";

// required fields
export const validateInputs = (obj) => {
  const items = Object.keys(obj);
  const errors = {};
  items.map((key) => {
    const err = localStorage.getItem("required")
      ? localStorage.getItem("required")
      : "Required";
    const value = obj[key];
    if (!value) {
      errors[key] = err;
    }
  });
  return errors;
};

// obj copy functions
export const copyObj = (obj) =>
  obj
    ? Object.prototype.toString.call(obj) === "[object Object]"
      ? jQuery.extend(true, {}, obj)
      : JSON.parse(JSON.stringify(obj))
    : obj;

//
export const apiPost = async (data) => {
  console.log("ApiPost fetch before", data);
  try {
    const response = await fetch(localUrl + data.url, {
      method: "Post",
      headers: {
        Authorization: localStorage.getItem("token"),
        "Content-type": "application/json",
      },
      body: JSON.stringify(data.body),
    });
    return response.json();
  } catch (err) {
    alert(err);
    return err;
  }
};

//Api get method
export const apiGet = async (data) => {
  console.log("api get fetch before", data);
  try {
    const response = await fetch(localUrl + data.url, {
      method: "Get",
      headers: {
        Authorization: localStorage.getItem("token"),
        "Content-type": "application/json",
      },
    });
    console.log("get response", response);
    return response.json();
  } catch (err) {
    alert("err");
    console.log("get json error", err);
    // return err;
  }
};

// email format
export const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

// date format
export const dateFormat = (date) => {
  console.log("date", date);
  let newDate = new Date(date);
  let day = newDate.getDate();
  day = day.toString().length === 1 ? `0${day.toString()}` : day;
  let month = newDate.getMonth() + 1;
  month = month.toString().length === 1 ? `0${month.toString()}` : month;
  let year = newDate.getFullYear().toString();
  let finalDate = `${year}-${month}-${day}`;
  return finalDate;
};

// time format
export const timeFormat = (date) => {
  // console.log('totalstate',configureStore.getState())
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  let strTime = `${hours}:${minutes} ${ampm}`;
  return strTime;
};

export const getMonthName = (month) => {
  console.log("month value");
  var name;
  switch (month) {
    case 0:
      name = "January";
      break;
    case 1:
      name = "Febrauary";
      break;
    case 2:
      name = "March";
      break;
    case 3:
      name = "April";
      break;
    case 4:
      name = "May";
      break;
    case 5:
      name = "June";
      break;
    case 6:
      name = "July";
      break;
    case 7:
      name = "August";
      break;
    case 8:
      name = "September";
      break;
    case 9:
      name = "October";
      break;
    case 10:
      name = "November";
      break;
    case 11:
      name = "December";
      break;
  }
  return name;
};

export const monthObj = {
  jan: "1",
  feb: "2",
  mar: "3",
  apr: "4",
  may: "5",
  jun: "6",
  jul: "7",
  aug: "8",
  sep: "9",
  oct: "10",
  nov: "11",
  dec: "12",
};

export const days = [
  { value: "01", label: "01" },
  { value: "02", label: "02" },
  { value: "03", label: "03" },
  { value: "04", label: "04" },
  { value: "05", label: "05" },
  { value: "06", label: "06" },
  { value: "07", label: "07" },
  { value: "08", label: "08" },
  { value: "09", label: "09" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
  { value: "25", label: "25" },
  { value: "26", label: "26" },
  { value: "27", label: "27" },
  { value: "28", label: "28" },
  { value: "29", label: "29" },
  { value: "30", label: "30" },
  { value: "31", label: "31" },
];

export const months = [
  { value: "january", label: "Jan" },
  { value: "febrauary", label: "Feb" },
  { value: "march", label: "Mar" },
  { value: "april", label: "Apr" },
  { value: "may", label: "May" },
  { value: "june", label: "Jun" },
  { value: "july", label: "Jul" },
  { value: "august", label: "Aug" },
  { value: "september", label: "Sep" },
  { value: "october", label: "Oct" },
  { value: "november", label: "Nov" },
  { value: "december", label: "Dec" },
];

export const years = [
  { value: "2019", label: "2019" },
  { value: "2012", label: "2012" },
  { value: "2013", label: "2013" },
  { value: "2014", label: "2014" },
];
