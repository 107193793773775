import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import PackageImg from '../../assets/images/package-card.png'
import { Colors } from '../../library/colors'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PackageLabel from '../PackageLabel'
import PlaneIcon from '../../assets/images/PLANE.png'
import HotelIcon from '../../assets/images/HOTEL-ICON.png'
import TourIcon from '../../assets/images/TOUR-ICON.png'
import ExtraIcon from '../../assets/images/EXTRA-ICON.png'
import ExcursionIcon from '../../assets/images/EXCURSION-ICON.png'
import Button from '../Button'
import { copyObj, apiPost } from '../../library'
import PackageImage from '../../assets/images/package-detailimg.png'

import {
  selectPackage, packageCmp, priceCal, selectDays, choosenPackage, selectTravelers, showTableData, selectMonth, selectPassengers, getExtraComps, changePageNumber,
  choosenPrice, changeInvoice, changeStepOne, changeStepTwoAdult, changeStepTwoChild, resetInputs,
} from '../Forms/actions'
import Loader from '../Loader'
import Layout from '../../layout'

function createData(days, d1, d2, d3, d4, d5, d6, d7) {
  return { days, d1, d2, d3, d4, d5, d6, d7 };
}

const rows = [
  createData('5 days - 4 nights', '$160', '$159', '$67', '$424', '$156'),
  createData('6 days - 5 nights', '$110', '$109', '$60', '$124', '$536'),
  createData('9 days - 8 nights', '$120', '$179', '$623', '$624', '$565'),
];

const H4 = styled.h4`
font-weight:100;
`
const H3 = styled.h3`
color:${Colors.primaryColor};
font-size:26px;
`
const InformationSection = styled.div`
padding:20px;
background:#fff;
margin:15px 0px;
`
const CheckboxWrapper = styled.div`
display:flex;
`
const DetailSection = styled.div`
margin-left:20px;
`
const P = styled.p`
font-size:16px;
margin: 8px 0px;
text-transform:capitalize;
`
const FormWrapper = styled.div`
width:85%;
`
const Label = styled.label`
font-size:14px;
`
const InputFieldsSection = styled.div`
margin:30px 0px;
`
const FlexWrapper = styled.div`
display:flex;
// align-items:ce;
margin:30px 0px;
justify-content:space-between;
// flex-wrap:wrap;

`
const InputContainer = styled.div`
display: flex;
flex-direction: column;
// margin-right: 25px;
width: 47.5%;
`
const ButtonGroups = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
margin: 3rem 0px;
`
const FloatingHeader = styled.div`
display: flex;
align-items: center;
justify-content:space-between;

`
const H5 = styled.h6`
color:${Colors.primaryColor};
font-size:20px;
`
const HR = styled.hr`
width: calc(100% - 90px);
    border: 1px solid #EBEBEB;
`
const JustifyFlex = styled.div`
display:flex;
align-items:center;
margin:30px 0px;
`
const H3Text = styled.h5`
font-size:16px;
`
// step 4 styles starts
const FourthStepSection = styled.div`
margin:1.7rem 0px;
`
const PackageDetails = styled.div`

`
const FourthStepHeader = styled.h3`
font-size:20px;
color:#fff;
background:${Colors.primaryColor};
margin: 15px 0px;
padding: 8px 15px;
font-weight: 200;
`
const PricingContainer = styled.div`
margin:10px 20px;
`
const FourthStepSubHeader = styled.h4`
font-size:24px;
color:${Colors.primaryColor};
font-weight:normal;
`
const SingleSection = styled.div`
margin:5px 0px;
`
const NormalText = styled.h5`
font-size:14px;
color:#383838;
font-weight:100;
margin:0.4rem 0;
`
const HighlightText = styled.h5`
font-size:16px;
margin:0.4rem 0;
`
const FloatingContainer = styled.div`
display: flex;
align-items: center;
justify-content:space-between;
flex-wrap:wrap;
`
const StepsContainer = styled.div`
display: flex;
align-items: center;
`
const Bar = styled.hr`
width:25%;
margin:15px 0px;
border:1.5px solid lightgray; 
`
const Separator = styled.hr`
width:100%;
border:1px solid #d3d3d352;
`
const TotalPriceContainer = styled.div`
text-align: right;
    background: #d3d3d363;
    border-top: 1.5px solid lightgray;
    padding: 10px;
`
const TermsConditions = styled.div`
display:flex;
align-items:center;
justify-content: flex-end;
`
// step 4 style ends

// step 0 starts
const PageWrapper = styled.div`
display:flex;
justify-content:space-between;
`
const ImageContainer = styled.div`
width:32%;
height:602px;
background-image:url(${props => props.img ? props.img : PackageImage});
background-position: center; 
background-repeat: no-repeat; 
background-size: cover; 
position:relative;
`
const StepZeroContainer = styled.div`
width:63%;
`
const PackageName = styled.div`

`
const PackageIcon = styled.img`
margin-right: 0.2em;
`
const FlexPackageIcon = styled.h3`
display: -webkit-box;
`

const StepZeroH3 = styled.h3`
font-size:20px;
margin:8px 0px;
color:${Colors.primaryColor};
`
const StepZeroH4 = styled.h4`
font-size:20px;
color:${Colors.primaryColor};
`
const StepZeroH6 = styled.h6`
font-size:20px;
font-weight:normal;
color:${Colors.primaryText};
`
const StepZeroHighlightText = styled.p`
font-size:16px;
font-weight:600;
color:${Colors.primaryText};
margin: 0.1rem 0px;
`
const StepZeroStepZeroHighlightText = styled.span`
font-size:16px;
font-weight:100;
color:${Colors.primaryText};
`
const IncludedPackage = styled.div`
margin:10px 0px;
`
// const FlexWrapper = styled.div`
// display:flex;
// justify-content:space-between;
// `
const PackageSections = styled.div`
width:40%;
`
const LinkText = styled.p`
font-size:12px;
font-weight:100;
color:${Colors.primaryText};
`
const StepZeroStepZeroSeparator = styled.br`
`
const SummaryContainer = styled.div`
margin:1.5rem 0px;

`
const StepZeroStepZeroHR = styled.hr`
width: 100%;
margin: 30px 0px;
border: 1px solid #EBEBEB;
`
const SummaryPackageDetail = styled.div`
display:flex;
align-items:center;
margin: 10px;
justify-content:space-between;
`
const TableWrapper = styled.div`
margin:20px 0px;
`
const DateSection = styled.div`
display:flex;
align-items:center;
width:40%;
justify-content:space-between;
`
const Icon = styled.img`
height:100%;
`
const StepZeroStepZeroFloatingContainer = styled.div`
display:flex;
align-items:center;
`
const EnquiryContainer = styled.div`
margin:20px 0px;
background:transparent;
`
const EnquirySuccsess = styled.div`
margin:20px 0px;
background:transparent;
`
const HelpUsFrom = styled.div`

`
const Text = styled.div`
font-size:18px;
color:grey;
`
// const FlexWrapper = styled.div`
// display:flex;
// // align-items:center;
// justify-content:space-between;
// `
const InputFiled = styled.div`
margin:15px 0px;
width:47%;
flex-wrap:wrap;
`
const ButtonWrapper = styled.div`
display:flex;
align-items:center;
justify-content:flex-end;
`
const DropdownGroup = styled.div`
display:flex;
align-items:center;
`
const Note = styled.p`
font-size:12px;
color:gray;
text-align:right;
`
const Name = styled.h4`
background:${Colors.secondaryText};
color:#ffffff;
font-size:22px;
padding:8px 1rem;
position:absolute;
bottom:140px;
left:0;
// margin-top:-3px;
`
const PriceTag = styled.h5`
padding:8px 1rem;
color:#ffffff;
position:absolute;
bottom:100px;
left:0;
background:${Colors.primaryColor};
`
// step 4 ends

const FormSectionWrapper = styled.div`
display:flex;
`
const FormSection = styled.div`
width: 63%;
margin-right: 20px;
`
const PackageDetailSection = styled.div`
width: 29.5%;
`
const LoadingWrapper = styled.div`
display:flex;
align-items:center;
justify-content:center;
height:80vh;
`
export class index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      keys: {
        des_name: [],
        order_name: '',
        order_price: '',
        text_language: 'EN',
        package_code: '',
        detail: ''
      },
      loading:false
    }
  }
  componentDidMount() {
    console.log('packegedetails', this.props)    
  }
  
  componentDidMount = async () => {
    if (this.props.match.params && this.props.match.params.id) {
      this.setState({
          // loading: true
      })
      const newKeys = copyObj(this.state.keys)
      newKeys['package_code'] = this.props.match.params.id
      const data = {
          url: 'user_pack_view/',
          body: newKeys
      }
      const newData = await apiPost(data)
      this.props.packageCmp(newData)
      const newTravel = newData.qty.map((x, i) => { return { label: x, value: x } })
      const newMonths = newData.period.map((x, i) => { return { label: x, value: x } })
      const newObj = {
          package_cmp: newData,
          travelOptions: newTravel,
          monOptions: newMonths,
          csv_file: newData.package[0].csv_file,
      }
      this.props.choosenPackage(newObj)
      console.log('selected package newData', newData)
      // this.setState({
      //     // package_cmp: newData,
      //     travelOptions: newTravel,
      //     monOptions: newMonths,
      //     csv_file: newData.package[0].csv_file,
      //     morePassengers: { ...this.state.morePassengers, package_code: newData.package[0].package_code }
      //     // package_detail: newData.package
      // })
      var packageVars = newData.package[0]
      // console.log('validate fpit cdm', packageVars)
      // if (packageVars.partial_pay === false) {
      //     this.props.selectType('full payment')
      // }
      // console.log('selected packegedetails', newData)
  }
  }

  render() {
    const { packageObj, priceCalcObj, daysMonthsYears, travelers, showTable, rows, col, rowDatas, selectedMonth, index, data, showSummary,
      child, adult, pageNum, invoice, stepOneInputs, extrasPackage } = this.props.cmp


  const { package_cmp, travelOptions, monOptions } = this.props.cmp.selectedPackage
  var packageCmp = this.props.cmp.selectedPackage.package_cmp
  var adults = 0
  var children = 0
  var totalTravelers = []
  var noOfAdults = []
  var noOfChild = []
  var imageUrl = ''
  var packName = ''
  var price = ''
  if (Object.keys(packageObj).length > 0) {
      totalTravelers = packageObj.qty.split(" ").filter((x, i) => !isNaN(x))
      adults = totalTravelers.length > 0 ? totalTravelers[0] : 0
      children = totalTravelers.length > 1 ? totalTravelers[1] : 0
      noOfAdults = Array.from(Array(parseInt(adults)).keys())
      noOfChild = Array.from(Array(parseInt(children)).keys())
  }
  console.log('packagecmp', this.props)
  const { showCountry, step2Arr, fieldErrOne, country, errTwo,
      showDays, showGender, showYear, errOne, paymentLoading, loading } = this.state
  // step zero render
  const { morePassengers, keys, packageSummary,
      errors, field_errors, show, } = this.state
  if (packageCmp) {
 var packageCommonDetails=packageCmp.package[0]
      imageUrl = 'package' in package_cmp ? package_cmp.package.length > 0 ?
          package_cmp.package[0].image : PackageImage : PackageImage
      packName = 'package' in package_cmp ? package_cmp.package.length > 0 ?
          package_cmp.package[0].name : '' : ''
      price = 'package' in package_cmp ? package_cmp.package.length > 0 ?
          package_cmp.package[0].from_price : '' : ''
      if ('package' in packageCmp) {
          var packageVars = packageCmp.package[0]

      }
      console.log('packageVars', packageVars)
  }
    return (<Layout>
    <PageWrapper>
      <ImageContainer img={imageUrl}>
          <Name className='text-capitalize'>{packName}</Name>
          <PriceTag>from <span className='font-sm mx-1'> <span className='font-sm mx-1'>€</span> </span> {price}</PriceTag>
      </ImageContainer>
      <StepZeroContainer>
          <StepZeroH6>Great, you have selected a package deal!</StepZeroH6>
          <StepZeroH3>What’s included in this package?</StepZeroH3>
          {/* package detail starts */}
          {packageCmp ? <IncludedPackage>
              {'airline' in package_cmp ? package_cmp.airline.length > 0 ? package_cmp.airline.map((x, i) => {
                  return <PackageLabel img={PlaneIcon} heading={`${packageCommonDetails.flight_status} - ${packageCommonDetails.flight_name}`}>
                      <StepZeroHighlightText>{x.name}</StepZeroHighlightText>
                      <StepZeroStepZeroHighlightText>
                          {`${package_cmp.package[0].luggage_included ? package_cmp.package[0].luggage_qty + ' kg of luggage included' : ''}`}</StepZeroStepZeroHighlightText>
                  </PackageLabel>
              }) : '' : ''}
              {'hotel' in package_cmp ? package_cmp.hotel.length > 0 ? package_cmp.hotel.map((x, i) => {
                  return <PackageLabel cls='m-0' img={HotelIcon} heading="Hotel">
                      <StepZeroHighlightText>{x.name}</StepZeroHighlightText>
                      <StepZeroStepZeroHighlightText>{x.room_name}</StepZeroStepZeroHighlightText>
                      {x.breakfast ? <StepZeroStepZeroHighlightText>Breakfast included</StepZeroStepZeroHighlightText> : ''}
                  </PackageLabel>
              }) : '' : ''}
              <FlexWrapper className='m-0'>
                  <PackageSections>
                      {'tour' in package_cmp ? package_cmp.tour.length > 0 ? package_cmp.tour.map((x, i) => {
                          return <PackageLabel img={TourIcon} heading="Tours">
                              <StepZeroHighlightText>{x.name}</StepZeroHighlightText>
                          </PackageLabel>
                      }) : '' : ''}
                      {'excursion' in package_cmp ? package_cmp.excursion.length > 0 ? package_cmp.excursion.map((x, i) => {
                          return <PackageLabel img={ExcursionIcon} heading="Excursions">
                              <StepZeroHighlightText>{x.name}</StepZeroHighlightText>
                          </PackageLabel>
                      }) : "" : ''}
                  </PackageSections>
                  <PackageSections>
                      {'car_rental' in package_cmp ? package_cmp.car_rental.length > 0 ? package_cmp.car_rental.map((x, i) => {
                          return <PackageLabel img={ExtraIcon} heading="Car rental">
                              <StepZeroHighlightText>{x.name}</StepZeroHighlightText>
                          </PackageLabel>
                      }) : "" : ''}
                      <StepZeroStepZeroSeparator />
                      {'transfer' in package_cmp ? package_cmp.transfer.length > 0 ? package_cmp.transfer.map((x, i) => {
                          return <PackageLabel img={ExtraIcon} heading="Transfer">
                              <StepZeroHighlightText>{x.name}</StepZeroHighlightText>
                          </PackageLabel>
                      }) : "" : ''}
                      <StepZeroStepZeroSeparator />
                      {'added_value' in package_cmp ? package_cmp.added_value.length > 0 ? package_cmp.added_value.map((x, i) => {
                          return <PackageLabel img={ExtraIcon} heading="Added Value">
                              <StepZeroHighlightText>{x.name}</StepZeroHighlightText>
                          </PackageLabel>
                      }) : "" : ''}
                  </PackageSections>
              </FlexWrapper>
          </IncludedPackage> : ""}
          {/* package detail ends */}
          {/* selecting no of travelers */}
          {/* selecting no of travelers */}
          {/* <StepZeroStepZeroHR /> */}
          {/* Package Table starts */}
          
          {/* Package Table ends */}
          {/* summary section starts*/}
          
          {/* summary section ends*/}
      </StepZeroContainer>
      {/* <HelpSection /> */}
  </PageWrapper>
  </Layout>
    )
  }
}

const mapStateToProps = (state) => ({
  cmp: state.calc,
  type: state.paymentType,
  opt: state.paymentOptions,
  checks: state.insurance
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, {packageCmp,choosenPackage})(index)