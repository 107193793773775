import React, { Component } from 'react'
import { connect } from 'react-redux'

import SelectedPackage from '../../components/SelectedPackage'
import Layout from '../../layout/'

export class index extends Component {
    render() {
        return (<Layout>
                <SelectedPackage/>
        </Layout>)
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(index)
