import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import {Colors} from '../../library/colors'
import {copyObj} from '../../library/'
import Checkbox from '../Checkbox'
import {selectType} from './actions'
const Container=styled.div`
display:flex;
flex-wrap:wrap;
background:#fff;
padding:15px;
width:43%;
@media only screen and (max-width: 600px) {
    width:100%;
   }
`
const InformationSection=styled.div`
margin:0px 15px;
`
const NormalText=styled.h6`
font-size:14px;
// font-weight:100;
// margin:0.4rem 0px;

`
const PriceTag=styled.h5`
color:${Colors.primaryColor};
font-size:18px;
margin:0.4rem 0px;

`
const CheckboxWrapper=styled.div`

`
export  class index extends Component {
    state={
        checkboxes:[]
    }
    selectInsurance=(e,price)=>{
        const {name,checked}=e.target
           this.props.selectType(price)
}
    render() {
        const{option}=this.props.type
        return (<Container>
                <CheckboxWrapper>
                    <Checkbox 
                    disable={option===this.props.val?true:false}
                    id={this.props.val}
                    name={this.props.val}
                    check={option===this.props.val?true:false} 
                    change={(e)=>this.selectInsurance(e,this.props.val)}
                    val={this.props.val}/>
                </CheckboxWrapper>
                <InformationSection>
                    <NormalText>
                        {this.props.name}
                    </NormalText>
                    <PriceTag>
                        {this.props.price}
                    </PriceTag>
                </InformationSection>
        </Container>)
    }
}

const mapStateToProps = (state) => ({
    cmp:state.calc,
    type:state.paymentType   
   })
   
   const mapDispatchToProps = {
       
   }
   
   export default connect(mapStateToProps, {selectType})(index)
   