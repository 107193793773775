import React from 'react';
import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom';
import Alert from 'react-s-alert';
// import { connect } from 'react-redux';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css';
import 'react-s-alert/dist/s-alert-css-effects/jelly.css';

import PackageDetail from './pages/PackageDetail'
import Home from './pages/Home'
import FormsPage from './pages/FourStepForm'
import Details from './pages/Details'
import ConfirmStatus from './components/ConfirmStatus'
import PendingStatus from './components/PendingStatus'
import FailedStatus from './components/FailedStatus'
import ErrorStatus from './components/Error'
import PackageView from './pages/MnagaeView'
import './App.css';
import langComponent from './components/LangComponent'

function App() {
  localStorage.removeItem('required')
  return (<Router >
    <div className="App">
      <Switch>
       {/* <Route path="/"
       to={'/en'}
       /> */}
       {/* <Route component={langComponent}> */}
        <Route
          exact
          path="/:lang"
          component={Home}
        />
        <Route
          exact
          path="/:lang/package-detail/:id"
          component={FormsPage}
        />
        <Route
          exact
          path="/test"
          component={Details}
        />
        <Route
          exact
          path="/view-package/:id"
          component={PackageView}
        />
        <Route
          exact
          path="/package/:id"
          component={PackageDetail}
        />
        <Route
          exact
          path="/:lang/pending-state/:id"
          component={PendingStatus}
        />
        <Route
          exact
          path="/failed"
          component={FailedStatus}
        />
        <Route
          exact
          path="/:lang/success"
          component={ConfirmStatus}
        />
         <Route
        exact
        path='/'
        render={()=>(<Redirect to="/nl" />)}
        />
        <Route
          exact
          path="*"
          component={ErrorStatus}
        />
        {/* </Route> */}
      </Switch>
      {/* </Route> */}
      <Alert stack={{ limit: 3 }} />
    </div>
  </Router>);
}

export default App;
