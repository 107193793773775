import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import {Colors} from '../../library/colors'

const Container = styled.div`
margin:20px 0px;
`
const FloatingHeader = styled.div`
display: flex;
align-items: center; 
justify-content:space-between;

`
const H5 = styled.h6`
color:${Colors.primaryColor};
font-size:18px;
font-weight: 100;
`
const HR = styled.hr`
width: calc(100% - ${props=>props.nl?'300px':'235px'});
border: 1px solid #EBEBEB;
margin-left: 25px;
@media only screen and (max-width: 600px) {
    width:0px;
 }
`

export class index extends Component {
    render() {
        const{selectedLang}=this.props.lang
        return (<Container>
            <FloatingHeader>
                <H5 className='first-letter-cap'>{this.props.heading}</H5>
                <HR 
                nl={selectedLang.toUpperCase()==='NL'?true:false}/>
            </FloatingHeader>
            {this.props.children}
        </Container>)
    }
}

const mapStateToProps = (state) => ({
lang:state.language 
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(index)
