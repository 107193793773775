// import _ from 'lodash';
const year = (new Date()).getFullYear();
const years = Array.from(new Array(20), (val, index) => { return { 'label': index + year, value: index + year } });
const months = Array.from(new Array(12), (val, index) => { return { 'label': index + 1, value: index + 1 } });
const dates = Array.from(new Array(31), (val, index) => { return { 'label': index + 1, value: index + 1 } });

const setInitialState = () => {
  return {
    selectedPackage: {
      package_cmp: '',
      travelOptions: '',
      monOptions: '',
      csv_file: ''
    },
    packageObj: {},
    package_cmp: {},
    priceCalcObj: {},
    daysMonthsYears: {
      years: years,
      months: months,
      dates: dates
    },
    travelers: '',
    rows: [],
    col: [],
    rowDatas: {},
    showTable: false,
    selectedMonth: '',
    showSummary: false,
    selectedPrice: '',
    index: '',
    data: '',
    child: [],
    adult: [],
    extrasPackage: {},
    pageNum: 0,
    stepOneInputs: {
      first_name: '',
      last_name: '',
      email: '',
      confirm_email: '',
      telephone_number: '',
      contact_status: false,
      street: '',
      house_number: '',
      postal_code: '',
      city: '',
      country: '',
      company_name: '',
      vat_number: '',
      contact_number:'',
      remarks:''
    },
    invoice: ''
  }
}

const initialState = setInitialState();

export const PACKAGE_ITEM = 'PACKAGE_ITEM';
export const SELECTED_PACKAGE = 'SELECTED_PACKAGE';
export const PACKAGE_CMP = 'PACKAGE_CMP';
export const PACKAGE_CAL = 'PACKAGE_CAL';
export const DAYS_MONTHS_YEARS = 'DAYS_MONTHS_YEARS'
export const TRAVELERS_VAL = 'TRAVELERS_VAL';
export const TABLE_DATA = 'TABLE_DATA'
export const SELECT_MONTH = 'SELECT_MONTH'
export const SELECT_PRICE = 'SELECT_PRICE'
export const GET_EXTRAS = 'GET_EXTRAS'
export const SELECT_PAASENGERS = 'SELECT_PAASENGERS'
export const PAGE_NUMBER = 'PAGE_NUMBER'
export const INVOICE = 'INVOICE'
export const STEP_ONE = 'STEP_ONE'
export const RESET = 'RESET'
export const MON_OPTIONS = 'MON_OPTIONS'
export const RESET_INPUTS = 'RESET_INPUTS'
export const STEP_TWO_ADULT = 'STEP_TWO_ADULT'
export const STEP_TWO_CHILD = 'STEP_TWO_CHILD'



const packageItem = (state = initialState, action) => {
  console.log('login reducer', action)
  switch (action.type) {
    case PACKAGE_ITEM:
      return Object.assign({}, state, {
        packageObj: action.payload, showSummary: true
      })
    case STEP_TWO_ADULT:
      return Object.assign({}, state, {
        adult: action.payload,
      })
    case STEP_TWO_CHILD:
      return Object.assign({}, state, {
        child: action.payload
      })
    case PACKAGE_CMP:
      console.log('PACKAGE_CMP', action)
      return Object.assign({}, state, {
        package_cmp: action.payload
      })
    case RESET:
      console.log('PACKAGE_CMP', action)
      return setInitialState()

      case RESET_INPUTS:
        console.log('resetting ips', action)
        return Object.assign({}, state, {
          child:[],adult:[],extrasPackage:{},packageObj:{},priceCalcObj:{},package_cmp:{},selectedPackage:{package_cmp: '',
          travelOptions: '',
          monOptions: '',
          csv_file: ''},
          stepOneInputs:{
            first_name: '',
            last_name: '',
            email: '',
            confirm_email: '',
            telephone_number: '',
            contact_status: false,
            street: '',
            house_number: '',
            postal_code: '',
            city: '',
            country: '',
            company_name: '',
            vat_number: '',
            remarks:''
          },invoice:''
        })
    case DAYS_MONTHS_YEARS:
      console.log('Days months reducer', action)
      return Object.assign({}, state, {
        daysMonthsYears: action.payload
      })
    case INVOICE:
      console.log('INVOIC', action)
      return Object.assign({}, state, {
        invoice: action.payload
      })
    case STEP_ONE:
      console.log('INVOIC', action)
      return Object.assign({}, state, {
        stepOneInputs: action.payload
      })
    case PACKAGE_CAL:
      console.log('PACKAGE_CAL', action)
      return Object.assign({}, state, {
        priceCalcObj: action.payload
      })
    case SELECTED_PACKAGE:
      console.log('PACKAGE_CAL', action)
      return Object.assign({}, state, {
        selectedPackage: action.payload
      })
    case TRAVELERS_VAL:
      console.log('NO OF TRAVELERS REDUCERS', action)
      return Object.assign({}, state, {
        travelers: action.payload, showSummary: false, data: '', index: '', price: ''
      })
    case TABLE_DATA:
      console.log('table date REDUCERS', action)
      return Object.assign({}, state, {
        showTable: true, rows: action.payload.rows,
        col: action.payload.col, rowDatas: action.payload.rowDatas
      })
    case SELECT_MONTH:
      console.log('SELCT MONTH REDUCERS', action)
      return Object.assign({}, state, {
        selectedMonth: action.payload, showSummary: false, data: '', index: '', price: ''
      })
    case SELECT_PRICE:
      console.log('SELCT price REDUCERS', action)
      return Object.assign({}, state, {
        selectedPrice: action.payload.price, data: action.payload.data,
        index: action.payload.index
      })
    case SELECT_PAASENGERS:
      console.log('SELCT passenges REDUCERS', action)
      return Object.assign({}, state, {
        child: action.payload.child, adult: action.payload.adult,
      })
    case GET_EXTRAS:
      console.log('SELCT extras REDUCERS', action)
      return Object.assign({}, state, {
        extrasPackage: action.payload
      })
    case PAGE_NUMBER:
      console.log('page number REDUCERS', action)
      return Object.assign({}, state, {
        pageNum: action.payload
      })
      case MON_OPTIONS:
        console.log('months REDUCERS', action)
        return  { ...state, selectedPackage:{...state.selectedPackage,monOptions:action.payload} }
    default:
      return state;
  }
};

export default packageItem;