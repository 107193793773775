import { SELECT_LANG,SELECT_EMAILSUB } from './reducer';

export const chooseLang = (data) => ({
  type: SELECT_LANG,
  payload:data
});

export const chooseEmailSub = (data) => ({
  type: SELECT_EMAILSUB,
  payload: data
});
