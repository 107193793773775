import React, { Component } from 'react'

export default class index extends Component {
    render() {
        return (
            <div className="form-group">
            <input type="radio" 
            onChange={this.props.change}
            value={this.props.value}
            name={this.props.name}
            disabled={this.props.disable}
            checked={this.props.check}
            id={this.props.val}/>
            <label htmlFor={this.props.val}></label>
          </div>
        )
    }
}
