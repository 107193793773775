import React, { Component } from 'react'
import styled from 'styled-components'

import {Colors} from '../../library/colors'

const Container = styled.div`
margin:20px 0px;
`
const FloatingHeader = styled.div`
display: flex;
align-items: center;
justify-content:space-between;

`
const H5 = styled.h6`
color:${Colors.primaryColor};
font-size:18px;
font-weight: 100;
text-transform:capitalize;
`
const HR = styled.hr`
width: calc(100% - 150px);
border: 1px solid #EBEBEB;
margin-left: 25px;
@media only screen and (max-width: 600px) {
   width:0px;
}
`
export default class index extends Component {
    render() {
        return (<Container>
            <FloatingHeader>
                <H5>{this.props.heading}</H5>
                <HR />
            </FloatingHeader>
            {this.props.children}
        </Container>)
    }
}
