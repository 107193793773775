import {SELECTED_PACKAGE, PACKAGE_ITEM,PACKAGE_CMP,PACKAGE_CAL,DAYS_MONTHS_YEARS,
TRAVELERS_VAL,SELECT_MONTH, 
TABLE_DATA,
SELECT_PRICE,
SELECT_PAASENGERS,
GET_EXTRAS,
PAGE_NUMBER,
INVOICE,
STEP_ONE,
RESET,STEP_TWO_ADULT,
STEP_TWO_CHILD,
RESET_INPUTS,
MON_OPTIONS} from './reducer';

export const selectDays = (data) => ({
  type: DAYS_MONTHS_YEARS,
  payload:data
});

export const choosenPrice = (data) => ({
  type: SELECT_PRICE,
  payload:data
});

//
export const selectMonth = (data) => ({
  type: SELECT_MONTH,
  payload:data
});


export const choosenPackage = (data) => ({
  type: SELECTED_PACKAGE,
  payload:data
});
 
export const showTableData = (data) => ({
  type: TABLE_DATA,
  payload:data
});

export const selectPackage = (data) => ({
  type: PACKAGE_ITEM,
  payload:data
});

export const packageCmp = (data) => ({
    type: PACKAGE_CMP,
    payload:data
  });

  export const priceCal= (data) => ({
    type: PACKAGE_CAL,
    payload:data
  });

  export const selectTravelers= (data) => ({
    type: TRAVELERS_VAL,
    payload:data
  });

  export const selectPassengers= (data) => ({
    type: SELECT_PAASENGERS,
    payload:data
  });

  export const getExtraComps= (data) => ({
    type: GET_EXTRAS,
    payload:data
  });

  export const changePageNumber= (data) => ({
    type: PAGE_NUMBER,
    payload:data
  });

  export const changeInvoice= (data) => ({
    type: INVOICE,
    payload:data
  });

  export const changeStepOne= (data) => ({
    type: STEP_ONE,
    payload:data
  });

  export const changeStepTwoAdult= (data) => ({
    type: STEP_TWO_ADULT,
    payload:data
  });

  export const changeStepTwoChild= (data) => ({
    type: STEP_TWO_CHILD,
    payload:data
  });

  export const resetState= () => ({
    type: RESET,
  });

  export const resetInputs= () => ({
    type: RESET_INPUTS,
  });

  export const mon_Options= (data) => ({
    type:MON_OPTIONS,
    payload:data
  });


