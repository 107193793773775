import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import ShowMoreText from 'react-show-more-text';

import { Colors } from '../../library/colors'
import Checkbox from '../Checkbox'
import ReadMoreAndLess from 'react-read-more-less';

const Container = styled.div`
display:flex;
margin:20px 0px;
justify-content:space-between;
box-shadow:${props => props.bg ? "2px 2px 11px 0px #d3d3d3ab" : "0px 0px 0px 0px transparent"};
background:#fff;
@media only screen and (max-width: 600px) {
    flex-direction:column;    
    }
`
const InformationSection = styled.div`
padding:20px;
`
const CheckboxWrapper = styled.div`
display:flex;
`
const DetailSection = styled.div`
margin-left:20px;
`
const H3Text = styled.h5`
font-size:16px;
`
const H3 = styled.h5`
font-size: 18px;
font-weight: bold;
`
const ImageSection = styled.div`
// width:200px;
// height:150px;
`
const TourSection=styled.div`
display:flex;
// align-items:center;
// background:${Colors.primaryColor};
// padding: 5px 10px 1px 10px;
`
const IconSection=styled.div`
background: #DBDDDD;
    text-align: center;
    padding: 3px 5px;
    @media only screen and (max-width: 600px) {
        width:12.5%;
        }
`
const Image = styled.img`
@media only screen and (max-width: 600px) {
width:100%;
height:auto;
}
`
const DaysSection=styled.div`
display:flex;
align-items:center;
@media only screen and (max-width: 600px) {
    width:87.5%;
    }
`
const Days=styled.label`
padding: 3px 6px;
    background:#878787;
    color: #fff;
    text-transform: capitalize;
    margin: 0;
    @media only screen and (max-width: 600px) {
        width:14.3%;
        } 
`
const Icon=styled.i`
color:#2C3D7B;
`

export class index extends Component {
    executeOnClick(isExpanded) {
        console.log(isExpanded);
    }
    state = {
        charLimit: 150
    }

    readMore = () => {
        this.setState({
            charLimit: -1
        })
    }

    hideText = () => {
        this.setState({
            charLimit: 150
        })

    }
    render() {
        const {langObj}=this.props.lang
        const days=[langObj.Mo,langObj.Tu,langObj.We,langObj.TH,langObj.Fr,langObj.Sa,langObj.Su]
        const {charLimit}=this.state
        return (<Container bg={this.props.check}>
            <InformationSection>
                <CheckboxWrapper>
                <div className="checkbox-group">
            <input type="checkbox" 
            onChange={this.props.change}
            // value={this.props.val}
            disabled={this.props.disable}
            name={this.props.name}
            checked={this.props.check}
            id={this.props.name}/>
            <label id="check-box-id" htmlFor={this.props.name}></label>
          </div>
                    <DetailSection>
                        <H3>{this.props.heading}</H3>
                        {this.props.content.length > 150 ? <p className='word-break'>
                                    {this.props.content.slice(0, charLimit)}{charLimit === -1 ? (null) : (<span>...</span>) }{charLimit !== 150 ? <span
                                    className='c-pointer readMoreText' 
                                    onClick={this.hideText}
                                    > - <span className='text-underline'>{langObj.step_4_readless}</span></span> : <span 
                                    className='c-pointer readMoreText' 
                                    onClick={this.readMore}>+ <span className='text-underline'>{langObj.step_4_readmore}</span></span>}
                            </p> :
                                   <p className='word-break'>{this.props.content}</p> }
                    </DetailSection>
                </CheckboxWrapper>
                {this.props.children}
            </InformationSection>
            {this.props.img ? <ImageSection>
                <Image src={this.props.img} width='100%' height='150px'
                    alt="image alt foto" />
                <TourSection>
                    <IconSection>
                    <i class="fa fa-calendar text-icon "></i>
                    </IconSection>
                    <DaysSection>
                        {this.props.days.length>0?days.map((x,i)=>{
                            return <Days className={`${this.props.days[i].val===1?'bg-success':''}`}>{x}</Days>
                        }):''}
                    </DaysSection>
                </TourSection>    
            </ImageSection> : ''}
        </Container>)
    }
}

const mapStateToProps = (state) => ({
    lang:state.language
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(index)
