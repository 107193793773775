import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { copyObj } from '../../library/'
import { selectItem } from './actions'
import CheckBox from '../Checkbox'
import Logo1 from '../../assets/images/cnx-pay-Amex.svg'
import Logo2 from '../../assets/images/cnx-pay-Bancontact.svg'
import Logo3 from '../../assets/images/pay3.png'
import Logo4 from '../../assets/images/pay4.png'
import Logo5 from '../../assets/images/pay5.png'
import Logo6 from '../../assets/images/cnx-pay-Mastercard.svg'
import Logo7 from '../../assets/images/cnx-pay-Visa.svg'
import Logo8 from '../../assets/images/cnx-pay-Voucher.svg'

import { Colors } from '../../library/colors'

const PaymentOptions = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
margin:10px 0px;
flex-wrap:wrap;
@media only screen and (max-width: 600px) {
 flex-direction:column;
}
`
const SingleOption = styled.div`
background:#fff;
padding:15px;
width:43%;
margin:10px 0px;
@media only screen and (max-width: 600px) {
    width:100%;
}
`
const Container = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
`

const InformationSection = styled.div`
margin:15px 0px 0px 0px;
`
const NormalText = styled.h6`
font-size:16px;
margin:0.4rem 0px;

`
const HighlightText = styled.h5`
color:${Colors.primaryColor};
font-size:14px;
margin:0.4rem 0px;

`
const CheckboxWrapper = styled.div`

`
const Image = styled.img`
height:100%;
width: 78px;
`
export class index extends Component {
    state = {
        checkboxes: []
    }
    selectInsurance = (e, price) => {
        var checkState = copyObj(this.state.checkboxes)
        const { name, checked } = e.target
        console.log('name,checkjed', name, checked, checkState)
        if (checked) {
            if (name === 'no_assistance') {
                checkState = ['no_assistance']
            } else {
                checkState.push(name)
            }
            var newState = checkState.filter(x => x === name)
            console.log('chcked adte', checkState)
            this.props.selectItem(newState)
            this.setState({
                checkboxes: newState
            }, () => {
                console.log('checksrate', this.state, name, checked)
            })
        }
    }
    render() {
        const { langObj } = this.props.lang
        var logos = [{ img: Logo2, name: langObj.Bancontact, id: 'bancontact', uniqId: 'bancontact' },
        // { img: Logo5, name: langObj.kbc_bank, id: 'kbc', uniqId: 'kbc' },
        // { img: Logo3, name: langObj.belfius_bank, id: 'belfius', uniqId: 'belfius' },
        // { img: Logo4, name: langObj.ing_bank, id: 'inghomepay', uniqId: 'inghomepay' },
        { img: Logo6, name: langObj.master_card, id: 'creditcard', uniqId: 'creditcard' },
        { img: Logo7, name: langObj.visa, id: 'creditcard', uniqId: 'creditcard2' },
        { img: Logo1, name: langObj.american_express, id: 'creditcard', uniqId: 'creditcard3' },
        { img: Logo8, name: langObj.cant_pay_now, id: 'nill', uniqId: 'nill' }]
        const { package_cmp } = this.props.cmp
        console.log('logos before', logos)
        if ('package' in package_cmp) {
            var packageVars = package_cmp.package[0]
            if (packageVars.cant_pay_now === false) {
                console.log('logos after 0', logos)
                logos = logos.filter((x, i) => x.id !== "nill")
                console.log('logos after', logos)
            }
        }
        console.log('payment options', packageVars, logos)
        const { options } = this.props.opt

        // Logo1,Logo2,Logo3,Logo4,Logo5,Logo6,Logo7]
        return (<PaymentOptions>
            {logos.map((x, i) => {
                return <SingleOption>
                    <Container>
                        <Image src={x.img} alt="payment-logo" />
                        <CheckboxWrapper>
                            <CheckBox
                                cls='checkbox-group'
                                disable={options.indexOf(x.uniqId) === -1 ? false : true}
                                id={x.uniqId}
                                name={x.uniqId}
                                check={options.indexOf(x.uniqId) === -1 ? false : true}
                                change={(e) => this.selectInsurance(e, x.id)}
                                val={x.name}
                            />
                        </CheckboxWrapper>
                    </Container>
                    <InformationSection>
                        <NormalText>{x.name}</NormalText>
                        <HighlightText>
                            {langObj.payment_charge}
                        </HighlightText>
                    </InformationSection>
                </SingleOption>
            })}
        </PaymentOptions>)
    }
}

const mapStateToProps = (state) => ({
    cmp: state.calc,
    opt: state.paymentOptions,
    lang: state.language
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, { selectItem })(index)
