import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import ErrorImg from '../../assets/images/ERROR.png'
import Status from '../Status'

const H4=styled.h4`
color: #223E92;
font-size: 26px;`

const H6=styled.h6`
font-size:21px;
margin:1rem 0px;
font-weight:normal;
color:#363636;
text-align: center;
`
const PaymentMessage = styled.div`
font-size:16px;
margin:2rem;
`
export class index extends Component {

        render() {
            return (<Status img={ErrorImg} buttonName='Back to step 4'>
                        <H4>Your payment was not succesful ...</H4>
                        <H6>Please go back and select a other payment method. </H6>
                        <PaymentMessage className='margin-y'>If no succes, please contact our contact center by clicking “Need help?” on top of this page.</PaymentMessage> 
                    </Status>)
        }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(index)







