import React, { Component } from 'react'
import styled from 'styled-components'

const Input=styled.input`
padding: 10px 15px;
border: 1px solid #F1F1F1;
outline: none;
color:#333333;
/*color: #a2a2a2;*/
box-shadow: 1px 1px 4px 0px #d3d3d373;
border-bottom:${props=>props.btm};
font-size: 14px;
margin:8px 0px;
background:#fff;
`
export default class index extends Component {
    render() {
        return (
        <Input btm={this.props.val&& this.props.val.length>0?'2.5px solid green':"2.5px solid #d3d3d3b3"}
        onChange={this.props.inputChange}
        placeholder={this.props.label}
        value={this.props.val}
        name={this.props.name}
        type={this.props.type}
        disable={this.props.disable}
        className={this.props.classname}
        />
        )
    }
}
