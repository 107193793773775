import React, { Component } from 'react'
import styled from 'styled-components'
import Select from 'react-select';

import {Colors} from '../../library/colors'

export default class index extends Component {
    customStyles = {
        dropdownIndicator: this.dropdownIndicatorStyles,
        control: (base, state) => ({
            ...base,
            // padding: '10px 15px',
            border: '1px solid #F1F1F1',
            outline: 'none',
            color: '#a2a2a2',
            boxShadow: ' 1px 1px 4px 0px #d3d3d373',
            borderBottom: this.props.val?'2.5px solid green':'2.5px solid #d3d3d3b3',
            fontSize: '14px',
            margin: '8px 0px 0px 0px',
            background: '#fff',
            transition: 'none',
            outline: 'none',
            // textTransform: 'capitalize',
            '&:hover': {
                // border: `1px solid ${this.props.hoverColor}`,
                // boxShadow:`1px 1px 2px 1px rgb(191,191,191)`,
            },
        }),
        menu: base => ({
            ...base,
            marginTop: '0px',
            outline: 'none',
            // textTransform: 'capitalize',
            border: '0px solid white',
            transition: 'none',
        }),
        menuList: base => ({
            ...base,
            // textTransform: 'capitalize',
            //   borderRadius:"3.5px",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? Colors.primaryColor : `transparent`,
            color: state.isSelected ? `white` : `black`,
            '&:hover': {
                backgroundColor: `#223e92`,
                color: `#ffffff`,
            },
        }),
        input: (base, state) => ({
            ...base,
            '[type="text"]': {
                color: `black !important`,
            },
        }),
    };

    render() {
        return (
            <Select
                styles={this.customStyles}
                value={this.props.val}
                className={this.props.classname}
                onChange={this.props.changeDropdown}
                options={this.props.options}
                placeholder={this.props.label}
                name={this.props.name}
                isMulti={this.props.multi}
            />
        )
    }
}
